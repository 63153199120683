import { DateTime } from 'luxon'

export default class CommonHelper {
  static convertUTCDateToLocalDate(date: any): Date {
    let newDate: Date = new Date(date);
    let localOffset: number = newDate.getTimezoneOffset() * 60000;
    let localTime: number = newDate.getTime();

    return new Date(localTime - localOffset);
  }

  static convertUTCDateToLocalDateString(
    date: any,
    dateFormat: string = "MMDDYYYY",
    dateSeparator: string = "/"
  ): string {
    const newDateValue = this.convertUTCDateToLocalDate(date);

    return this.dateFormat(newDateValue, dateFormat, dateSeparator);
  }

  static combineDateWithTimeAndTimezone = (d: Date, t: Date, timezone?: string) => {
    const utcDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth()+1, day: d.getDate(), hour: t.getHours(), minute: t.getMinutes(), second: t.getSeconds(), millisecond: t.getMilliseconds() }, { zone: timezone ? timezone : DateTime.local().zoneName }).toUTC();

    const combinedDateTime = new Date(utcDateTime.toISODate() + "T" + utcDateTime.toISOTime());
    return combinedDateTime;
  }

  static convertUTCDateTimeToGivenTimezone = (d: Date, timezone?: string) => {

    const utcDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth(), day: d.getDate(), hour: d.getHours(), minute: d.getMinutes(), second: d.getSeconds(), millisecond: d.getMilliseconds() }, { zone: 'utc' });

    const destinationZone = utcDateTime.setZone(timezone ? timezone : DateTime.local().zoneName);// This line should ideally convert the time to the destination timezone, but it doesn't work as expected
    //So, we are doing it manually by taking the offset of the destination timezone and adding it to the UTC time

    const destinationOffsetMins = destinationZone.offset;
    const convertedDateTime = utcDateTime.plus({ minutes: destinationOffsetMins });
    return new Date(convertedDateTime.year, convertedDateTime.month, convertedDateTime.day, convertedDateTime.hour, convertedDateTime.minute, convertedDateTime.second, convertedDateTime.millisecond);
  }

  static toTitleCase(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  static toCamelCase(value: string): string {
    return value.charAt(0).toLowerCase() + value.slice(1);
  }

  static isNullOrEmpty(value?: string): boolean {
    return !(typeof value === "string" && value.length > 0);
  }
  static isNullOrWhiteSpace(str: string) {
    return !str || str.length === 0 || /^\s*$/.test(str);
  }

  static syncMatchingProps<T>(values: Partial<T>, newVals: T): T {
    return Object.keys(newVals)
      .filter((key) => key in newVals)
      .reduce((accumulatedObject, key) => {
        (accumulatedObject as any)[key] = (values as any)[key];
        return accumulatedObject;
      }, newVals) as T;
  }

  static convertPropsToType<T>(values: Partial<T>, classType: new () => T): T {
    const instance = new classType();
    return CommonHelper.syncMatchingProps(values, instance);
  }

  static dedupeObjects<T>(newObjects: T[], existingObjects: T[], keyPropName: string): T[] { // This function will return distinct array of objects by removing duplicate objects based on unique property name
    const combined = (existingObjects || []).concat(newObjects || []);
    return combined.filter((currentObject, idx, self) => self.findIndex((t: any) => t[keyPropName] === (currentObject as any)[keyPropName]) === idx);
  };

  static getCurrentDate(dateFormat: string, dateSeparator: string): string {
    return this.dateFormat(new Date(), dateFormat, dateSeparator);
  }

  static dateFormat(dateValue: Date, dateFormat: string, dateSeparator: string): string {
    let day: number = dateValue.getDate();
    let month: number = dateValue.getMonth() + 1;
    let year: number = dateValue.getFullYear();
    let formattedDate: string = "";

    switch (dateFormat) {
      case "MMDDYYYY":
        formattedDate = `${this.leftPad(month.toString(), "0", 2)}${dateSeparator}${this.leftPad(
          day.toString(),
          "0",
          2
        )}${dateSeparator}${year}`;
        break;
      case "DDMMYYYY":
        formattedDate = `${this.leftPad(day.toString(), "0", 2)}${dateSeparator}${this.leftPad(
          month.toString(),
          "0",
          2
        )}${dateSeparator}${year}`;
        break;
      case "YYYYMMDD":
        formattedDate = `${year}${dateSeparator}${this.leftPad(
          month.toString(),
          "0",
          2
        )}${dateSeparator}${this.leftPad(day.toString(), "0", 2)}`;
        break;
      default:
        formattedDate = `${this.leftPad(month.toString(), "0", 2)}${dateSeparator}${this.leftPad(
          day.toString(),
          "0",
          2
        )}${dateSeparator}${year}`;
    }

    return formattedDate;
  }

  static leftPad(value: string, padString: string, length: number): string {
    while (value.length < length) {
      value = padString + value;
    }

    return value;
  }

  static cloneObject<T>(obj: T): T {
    return { ...obj };
  }

  static validateIncidentNumberFormat(value: string) {
    if (value) {
      const regExp = new RegExp(/(^\INC\d{7}?$)/); // This function validates service now incident number format. For example : INC + 1234567
      return regExp.test(value.toUpperCase());
    }

    return false;
  }

  static validateEmailsByGivenDelimeter(emailAddresses: string, delimeter: string) {
    if (emailAddresses) {
      let emailsList = emailAddresses.split(delimeter);
      for (let i = 0; i < emailsList.length; i++) {
        const emailListRegEx = new RegExp(/\S+@\S+\.\S+/); // This function is to validate list of email addresses with given delimeter
        if (!emailListRegEx.test(emailsList[i])) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  static formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static getUserTimeZoneID() {
    var timezone = String(new Date());
    return timezone.substring(timezone.lastIndexOf('(') + 1).replace(')', '').trim();
  }

  static sortArrayOfNumbers = (numbers: number[]) => {
    return numbers.sort((n1: number, n2: number) => n1 - n2);
  }

  //--- sorting object element should be string
  static sortArrayObjectByString<T>(arrayObj: T[], sortField: string) {
    return [...arrayObj].sort((a: any, b: any) =>
      (a[sortField] && a[sortField].toLowerCase()) > (b[sortField] && b[sortField].toLowerCase()) ? 1 : -1,
    );
  }

  static base64ToArrayBuffer = (base64: any) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    return bytes;
  }

  static convertByteArrayToFile = (contentType: string, fileName: string, byte: Uint8Array) => {
    const blob: Blob = new Blob([byte], { type: contentType });
    const acchorElement = document.createElement('a');

    acchorElement.href = window.URL.createObjectURL(blob);
    acchorElement.target = "_blank";
    acchorElement.download = fileName;
    acchorElement.click();
  };

  static getEmailDomain = (email: string) => {
    return email && email.substring(email.indexOf('@') + 1, email.length);
  }

  static validateEmailFormat = (email: string) => {
    if (!email) {
      return false;
    }
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    //const emailRegex = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$");
    return emailRegex.test(email);
  }

  static getNewLineForMarkDown = (text: string) => {
    let description = text ? text.replace(/\n\n/g, 'DOUBLE_NEWLINE') : "";
    description = description.replace(/\n/g, '  \n');
    description = description.replace(/DOUBLE_NEWLINE/g, '\n\n');
    return description;
  }

  static convertStringArrayToTitleIdArray = (stringArray: string[]) => {
    return stringArray.map((item, index) => {
      return { title: item, id: index };
    });
  }
}
