import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import { Routes } from "../../../models/Routing";
import RequestGrid, { RequestType } from "../common/RequestGrid";
import * as SessionStore from "../../../store/Session";
import { requestSupportSteps, createSupportRequest, wizardSummary, wizardConfirmSubmitMessage, warningMessage, jobMessagingConfig, TicketTypes } from "../../../models/ServicenowSupportRequestSettings";
import { IFieldDataDropdown, IWizardDropDownListItem, IWizardSection } from "../../../models/Wizard";
import { useEffect, useState } from "react";
import SupportService from "../../../services/SupportService";
import { JobTypeEnum } from "../../../models/Enums";

interface IProps {
  match?: any;
  history: any;
}

type Props = IProps & SessionStore.ISessionState;

const ServiceNowRequests = (props: Props) => {
  const [workRequestSteps, setWorkRequestSteps] = useState<Array<IWizardSection>>(requestSupportSteps);
  const defaultTicketTypesOrder = [TicketTypes.AccountAdministration, TicketTypes.GoAnywhereSupport, TicketTypes.ManageRelativityUsers, TicketTypes.ManageEpiqAccessUsers, TicketTypes.TechnicalSupport, TicketTypes.DRSITServices];

  const getSubHeader = (code: string) => {
    switch (code) {
      case TicketTypes.AccountAdministration:
        return "RELATIVITY HELP";
      case TicketTypes.GoAnywhereSupport:
        return "GOANYWHERE HELP";
      default:
        return "";
    }
  }

  const fetchRequestTypes = async () => {
    const requestTypeDropDown = [] as Array<IWizardDropDownListItem>;

    const result = await SupportService.getTicketTypes();
    if (result.ok) {
      result.data.forEach((r: any) => {
        requestTypeDropDown.push({
          id: r.code,
          title: r.ticketName,
          lookupValues: { permission: r.permission ? r.permission.permissionName : null, databaseHeader: r.ticketName, code: r.code, subHeader: getSubHeader(r.code)  }
        });
      });
    }

    const sortedTicketTypes = requestTypeDropDown.sort((a, b) => {
      return (
        defaultTicketTypesOrder.indexOf(a.id as TicketTypes) - defaultTicketTypesOrder.indexOf(b.id as TicketTypes)
      );
    });

    if (props.sessionData.hideGeneralTechSupport) {
      const hasTechSupportReq = sortedTicketTypes.findIndex(x => x.id === TicketTypes.TechnicalSupport);
      if (hasTechSupportReq > -1) {
        sortedTicketTypes.splice(hasTechSupportReq, 1);
      }
    }

    const updatedSteps = workRequestSteps.map(x => {
      x.fields.map((y: any) => {
        if (y.fieldId === "1.1") {
          y.DropDownOptions = sortedTicketTypes;
          if (requestTypeDropDown.length == 1) {
            y.value = requestTypeDropDown[0].id;
          }
        }
        return y;
      });
      return x;
    });

    setWorkRequestSteps(updatedSteps);

  }

  const updateUserFields = async () => {
    const firstName = props.sessionData.firstName;
    const lastName = props.sessionData.lastName;
    const email = props.sessionData.username;
    const employerId = props.sessionData.employerId;
    const updatedSteps = workRequestSteps.map(x => {
      x.fields.map((y: any) => {
        if (y.fieldId === "1.0.0") {
          y.value = firstName + " " + lastName;
        }
        if (y.fieldId === "3.1") {
          y.value = firstName;
        }
        if (y.fieldId === "3.2") {
          y.value = lastName;
        }
        if (y.fieldId === "3.3" || y.fieldId === "1.0.3") {
          y.value = email;
        }
        if (y.fieldId === "1.0.4") {
          y.value = employerId;
        }
        return y;
      });
      return x;
    });
    setWorkRequestSteps(updatedSteps);
  }

  useEffect(() => {
    (async () => {
      fetchRequestTypes();
      updateUserFields();
    })();
  }, []);


  return (
    <RequestGrid
      {...props}
      requestType={RequestType.ServiceNow}
      showCreateRequestButton={true}
      showRequestList={true}
      createWorkRequest={createSupportRequest}
      workRequestSteps={workRequestSteps}
      wizardConfirmSubmitMessage={wizardConfirmSubmitMessage}
      wizardWarningMessage={warningMessage}
      wizardSummary={wizardSummary}
      disableSubmitButton={props.sessionData.preImpersonatedUser != null}
      jobMessagingConfig={jobMessagingConfig}
      jobTypeNumber={JobTypeEnum.CreateTicket}
    />
  );
};

export default connect((state: IApplicationState) => ({ ...state.sessionState }), null)(ServiceNowRequests);
