import * as React from "react";
import { connect } from "react-redux";
import { TextInput } from "../../../common/TextInput";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import ImageUploadPreview, { IImageChangeEvent } from "../../../common/ImageUploadPreview";
import * as SessionStore from "../../../../store/Session";
import "../SiteBranding.scss";
import {
  SiteBrandingModel
} from "../../../../models/SiteBrandingModel";
import { ITheme, IUpsertTheme, themeReference } from "../../../../models/Theme";
import CommonHelper from "../../../common/utilities/CommonHelper";
import CollapsibleSection from "../../common/CollapsibleSection";
import { IApplicationState } from "../../../../store";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { Grid } from "@material-ui/core";
import ThemeService from "../../../../services/ThemeService";
import { IGridParams } from "../../../common/grid/AdvancedGrid";
import SingleSelectDropDown from "../../../common/SingleSelectDropDown";
import BrandedEmails from "./BrandedEmails";
import { JobTypeEnum } from "../../../../models/Enums";

const validationMessages = {
  requiredField: "This field is required.",
  isAppNameExists: "Client name already exists.",
};

interface IElementToValidate {
  name: string;
  isValid: boolean;
}
interface IProps {
  siteDetails?: SiteBrandingModel;
  createSite: boolean;
  isSaving: boolean;
  isClientNameExists: boolean;
  updateEditedSiteDetails?: (
    editedSiteDetails: SiteBrandingModel,
    isFormReadyForSave: boolean,
    resetClientNameExistMessage: boolean,
  ) => void;
}

type Props = IProps & SessionStore.ISessionState;

type State = {
  hasValue: boolean;
  editedSiteDetails: SiteBrandingModel;
  elementsToValidate: Array<IElementToValidate>;
  themes: Array<ITheme>;
  selectedTheme: ITheme | null;
};

const lightTheme = {
  background: "linear-gradient(180deg, #EEF1F3 53.98%, rgba(198, 198, 198, 0.00) 100%, rgba(183, 187, 191, 0.00) 100%)",
  loginButtonBackground: "linear-gradient(138deg, #9EA5A8 0%, #858C8F 54.17%, #777B7D 100%)",
  poweredByLogoUrl: "WhiteLabelPoweredByLightTheme.png"
};
const darkTheme = {
  background: "linear-gradient(180deg, #171A1C 0%, #2D3234 70.15%, rgba(40, 44, 47, 0.82) 100%)",
  loginButtonBackground: "linear-gradient(315deg, #171A1C 0%, #1E2123 69.87%, rgba(26, 29, 31, 0.90) 100%)",
  poweredByLogoUrl: "WhiteLabelPoweredByDarkTheme.png"
};

class EditSite extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasValue: true,
      editedSiteDetails: CommonHelper.cloneObject(props.siteDetails),
      elementsToValidate: new Array<IElementToValidate>(
        { name: "name", isValid: !props.createSite },
        { name: "hostName", isValid: !props.createSite },
        { name: "backgroundColor", isValid: !props.createSite },
        { name: "logoUrl", isValid: !props.createSite }
      ),
      themes: [],
      selectedTheme: null
    };
  }

  onFormInputChangeEvent = (value: string, name: string, isValid: boolean) => {
    const editedSiteDetails: any = this.state.editedSiteDetails;

    editedSiteDetails[name] = value;

    this.setState({ editedSiteDetails: editedSiteDetails });

    const resetClientNameExistMessage: boolean =
      this.props.isClientNameExists && name === "name" ? false : this.props.isClientNameExists;

    this.setElementValidationState({ name, isValid }, () => {
      this.setState({ editedSiteDetails: editedSiteDetails });

      if (this.props.updateEditedSiteDetails) {
        this.props.updateEditedSiteDetails(
          editedSiteDetails,
          !this.state.elementsToValidate.find(element => !element.isValid),
          resetClientNameExistMessage,
        );
      }
    });
  };

  private setElementValidationState(newValidationState: IElementToValidate, callBack: () => void) {
    const indexToUpdate = this.state.elementsToValidate.findIndex(element => element.name === newValidationState.name);
    const elementsToValidate = [...this.state.elementsToValidate];

    if (indexToUpdate === -1) {
      callBack();
    } else {
      elementsToValidate.splice(indexToUpdate, 1);
      elementsToValidate.splice(indexToUpdate, 0, newValidationState);

      this.setState({ ...this.state, elementsToValidate }, callBack);
    }
  }

  onImageUploadFinished = (propName: string, uploadFinishedEvent: IImageChangeEvent) => {
    if (uploadFinishedEvent.imgUrl) {
      this.onFormInputChangeEvent(uploadFinishedEvent.imgUrl, propName, true)
    }
  }

  handleRadioChange = (e: any) => {
    if (e.value) {
      //because state hasn't been updated the dark theme is being selected if current state equals light theme
      const isDarkTheme = this.state.editedSiteDetails.backgroundColor === lightTheme.background;

      this.onFormInputChangeEvent(e.value, "backgroundColor", true)
      this.onFormInputChangeEvent(isDarkTheme ? darkTheme.poweredByLogoUrl : lightTheme.poweredByLogoUrl, "poweredByLogoUrl", true);
      this.onFormInputChangeEvent(isDarkTheme ? darkTheme.loginButtonBackground : lightTheme.loginButtonBackground, "loginButtonBackgroundColor", true);
    }
  }

  handleCheckboxChange = (e: any) => {
    const name = e.target.element.current.name;
    const value = e.value
    this.onFormInputChangeEvent(value, name, true);
  }

  render() {
    const { editedSiteDetails } = this.state;
    const { isSaving, isClientNameExists } = this.props;
    const themeDropDownProps =
    {
      getItems: (params: IGridParams) => {
        return ThemeService.getThemes(params)
      },
      textToRequestConverter: (input: string) => {
        return {
          sort: [{ field: "name", dir: "asc" }],
          filter: { logic: "and", filters: [{ field: "name", operator: "contains", value: input }] },
        }
      },
      preselectedItemCall: this.state.editedSiteDetails.themeId ? async () => {
        return await ThemeService.getThemeDetails(this.state.editedSiteDetails.themeId);
      } : null,
      placeholder: "(none)",
      onChange: (selected: any) => {
        if (!selected) {
          this.setState({ selectedTheme: null }, () => this.onFormInputChangeEvent(null, "themeId", true));
          return;
        }

        this.setState({ selectedTheme: selected }, () => this.onFormInputChangeEvent(selected.id, "themeId", true));
      },
      typeToListConverter: (themeItem: (any)[]) => themeItem.map(theme => {
        return {
          id: theme.id,
          text: theme.name,
          data: theme.data
        }
      })
    };

    return (
      <div className="Site-info-wrapper">
        <CollapsibleSection title="Login Page Details">
          <>
            <div className="field-hint">Required Fields</div>
            <Grid container>
              <Grid item sm={4} xs={12}>
                <ImageUploadPreview allowMultiple={false} value={editedSiteDetails.logoUrl} name="logoUrl" label="Upload Login Page Logo" labelSubText="Reccomended dimensions are 250 X 50" onChange={this.onImageUploadFinished.bind(this, "logoUrl")} jobType={JobTypeEnum.CdnWhiteLabelUpload} />
              </Grid>
              <Grid item sm={6} xs={12} className="input-wrapper">
                <TextInput
                  type="text"
                  name="name"
                  label="Client Name"
                  defaultValue={editedSiteDetails.name}
                  validations={[
                    { name: "required", errorMessage: "This field is required." },
                    {
                      name: "CustomValidation",
                      errorMessage: validationMessages.isAppNameExists,
                      predicate: isClientNameExists.toString()
                    }
                  ]}
                  displayCustomValidationMessage={isClientNameExists}
                  onChange={this.onFormInputChangeEvent}
                  maxLength={100}
                  disabled={isSaving}
                />
                <TextInput
                  type="text"
                  defaultValue={editedSiteDetails.hostName}
                  name="hostName"
                  label="Login Page Custom URL"
                  maxLength={100}
                  disabled={isSaving}
                  validations={[
                    { name: "required", errorMessage: "This field is required." },
                    { name: "Url", errorMessage: "Please enter a valid URL." }
                  ]}
                  onChange={this.onFormInputChangeEvent}
                />

                <div className="site-background-theme">
                  <span>Select Login Page Background</span>
                  <div className="container">
                    <div className="radio-site">
                      <RadioButton name="backgroundtheme" id="rb1" value={lightTheme.background} checked={editedSiteDetails.backgroundColor === lightTheme.background} onChange={this.handleRadioChange.bind(this)}>
                        <label htmlFor="rb1" className="lbl-theme"><div>Light theme</div> <div className='box light'></div>Use with colorful logos.</label>
                      </RadioButton>
                    </div>
                    <div className="radio-site">
                      <RadioButton name="backgroundtheme" id="rb2" value={darkTheme.background} checked={editedSiteDetails.backgroundColor === darkTheme.background} onChange={this.handleRadioChange.bind(this)}>
                        <label htmlFor="rb2" className="lbl-theme"><div>Dark theme</div> <div className='box dark'></div>Use with white or light logos.</label>
                      </RadioButton>
                    </div>
                  </div>
                </div>
                <div className="title-faqLinks">FAQ Links</div>
                <Checkbox className="chk-faqlink" name="hideEaFaqLink" value={editedSiteDetails.hideEaFaqLink} label={"Hide the Epiq Access FAQ Link"} onChange={this.handleCheckboxChange} />
                <Checkbox className="chk-faqlink" name="hideNeedHelpLink" value={editedSiteDetails.hideNeedHelpLink} label={'Hide the Login "Need Help?" Link'} onChange={this.handleCheckboxChange} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={4} xs={12}></Grid>
              
            </Grid>
          </>
        </CollapsibleSection>
        <CollapsibleSection title="Home Page">
          <Grid container>
            <Grid item sm={4} xs={12}>
              <ImageUploadPreview allowMultiple={false} value={editedSiteDetails.homePageLogoUrl} name="homePageLogoUrl" label="Upload Main Application Logo" labelSubText="Reccomended dimensions are 200 X 50" onChange={this.onImageUploadFinished.bind(this, "homePageLogoUrl")} jobType={JobTypeEnum.CdnUpload} />
            </Grid>
            <Grid item sm={6} xs={12} className="input-wrapper">
              <div>
                Select your theme:<br />
                <SingleSelectDropDown
                  {...themeDropDownProps}
                />
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <ImageUploadPreview allowMultiple={false} value={editedSiteDetails.faviconUrl} name="faviconUrl" label="Upload a 16x16 pixel favicon for the browser tab" onChange={this.onImageUploadFinished.bind(this, "faviconUrl")} jobType={JobTypeEnum.CdnWhiteLabelUpload} />
            </Grid>
            <Grid item sm={6} xs={12} className="input-wrapper">
              <TextInput
                type="text"
                defaultValue={editedSiteDetails.browserTitle}
                name="browserTitle"
                label="Browser Tab Name"
                maxLength={100}
                disabled={isSaving}
                onChange={this.onFormInputChangeEvent}
              />
            </Grid>

          </Grid>
        </CollapsibleSection>
        <CollapsibleSection title="Email Details">
          <>
            <Grid container>
              <Grid item sm={4} xs={12}>
                <ImageUploadPreview allowMultiple={false} value={editedSiteDetails.emailLogoUrl} name="emailLogoUrl" label="Upload Email Template Logo" labelSubText="Reccomended dimensions are 250 X 50" onChange={this.onImageUploadFinished.bind(this, "emailLogoUrl")} jobType={JobTypeEnum.CdnWhiteLabelUpload} />
              </Grid>
              <Grid item sm={6} xs={12} className="input-wrapper">
                <TextInput
                  type="text"
                  defaultValue={editedSiteDetails.fromEmail}
                  name="fromEmail"
                  label="Sent From Email"
                  maxLength={100}
                  disabled={isSaving}
                  validations={[
                    { name: "EmailOptional", errorMessage: "Please enter a valid email if you are using this field." },
                    {
                      name: "RegexIfValue",
                      errorMessage: "If using email, it must contain 'noreply' or 'no-reply'",
                      regex: /noreply|no-reply/
                    }
                  ]}
                  onChange={this.onFormInputChangeEvent}
                />
              </Grid>
            </Grid>
            <BrandedEmails siteDetails={editedSiteDetails} onFormInputChangeEvent={this.onFormInputChangeEvent.bind(this)} />
          </>
        </CollapsibleSection>
      </div>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(EditSite);

