import Home from "../components/home/Home";
import UserGroups from "../components/administration/user/usergroups/UserGroups";
import RolesList from "../components/administration/roles/RolesList";
import UserDetails from "../components/administration/user/UserDetails";
import UserList from "../components/administration/user/UserList";
import ContextUserList from "../components/administration/user/ContextUserList";
import UserActivityList from "../components/administration/user/useractivity/UserActivityList";
import UserBatchSendInviteList from "../components/administration/user/UserBatchSendInviteList";
import ProjectList from "../components/administration/project/ProjectList";
import CreateUserGroup from "../components/administration/user/usergroups/CreateUserGroup";
import UserGroupDetails from "../components/administration/user/usergroups/UserGroupDetails";
import ProjectDetails from "../components/administration/project/ProjectDetails";
import RoleDetails from "../components/administration/roles/RoleDetails";
import Report from "../components/administration/report/Report";
import Assignments from "../components/administration/resource/Assignments";
import ResourceGroupsList from "../components/administration/resource/resourcegroups/ResourceGroupsList";
import ResourceGroupDetails from "../components/administration/resource/resourcegroups/ResourceGroupDetails";
import Applications from "../components/apps/Applications";
import Overview from "../components/overview/Overview";
import FileList from "../components/files/FileList";
import Administration from "../components/administration/Administration";
import PageNotFound from "../components/PageNotFound";
import Error from "../components/Error";
import Login from "../components/Login/Login";
import UploadSuccess from "../components/UploadSuccess";
import ChangePassword from "../components/changepassword/ChangePassword";
import ServiceNowCreateRequest from "../components/servicerequest/servicenow/create/Request";
import ServiceNowRequest from "../components/servicerequest/servicenow/edit/Request";
import ServiceNowRequests from "../components/servicerequest/servicenow/ServiceNowRequests";
import PulseWorkRequests from "../components/servicerequest/pulse/PulseWorkRequests";
import AdminApplications from "../components/administration/application/Applications";
import AdminApplication from "../components/administration/application/Application";
import PrivacyStatement from "../components/privacystatement/PrivacyStatement";
import ApprovalList from "../components/job/ApprovalList";
import JobList from "../components/common/Job/JobList";
import EmployersList from "../components/administration/Employer/EmployersList";
import EmployerDetails from "../components/administration/Employer/EmployerDetails";
import DomainsList from "../components/administration/Employer/Domains/DomainsList";
import { Upload } from "@progress/kendo-react-upload";
import SsoGroupDetails from "../components/administration/user/SsoGroupDetails";
import SsoGroupList from "../components/administration/user/SsoGroupList";
import ResourceList from "../components/administration/resource/ResourceList";
import SecureUploadLocationsList from "../components/administration/secureupload/SecureUploadLocationsList";
import SecureUploadDetails from "../components/administration/secureupload/SecureUploadDetails";
import CreateAdminContext from "../components/administration/clientContext/CreateAdminContext";
import SiteBrandingList from "../components/administration/siteBranding/SiteBrandingList"
import SiteBrandingDetails from "../components/administration/siteBranding/SiteBrandingDetails";
import MessageList from "../components/administration/messages/MessageList";
import MessageDetails from "../components/administration/messages/MessageDetails";
import Agreements from "../components/basic/Agreements";
import ApprovalDetail from "../components/approval/ApprovalDetail";
import JobQueue from "../components/administration/Job/JobQueue";
//JWK Disabling this for now
//import SpeedTest from "../components/servicerequest/SpeedTest";

export enum Routes {
  Activation = "ACTIVATION",
  Administration = "ADMINISTRATION",
  Applications = "APPLICATIONS",
  ChangePassword = "CHANGEPASSWORD",
  Dashboard = "DASHBOARD",
  DashboardReport = "DASHBOARDREPORT",
  DashboardReportPage = "DASHBOARDREPORTPAGE",
  Default = "DEFAULT",
  Error = "ERROR",
  Files = "FILES",
  Home = "HOME",
  ImplicitCallback = "IMPLICITCALLBACK",
  Login = "LOGIN",
  PageNotFound = "PAGENOTFOUND",
  ResetPassword = "RESETPASSWORD",
  SupportList = "SUPPORTLIST",
  ApprovalDetails = "APPROVALDETAILS",
  SupportRequestCreate = "SUPPORTREQUESTCREATE",
  SupportRequestDetails = "SUPPORTREQUESTDETAILS",
  SupportWorkRequestList = "SUPPORTWORKREQUESTLIST",
  SupportWorkRequestCreate = "SUPPORTWORKREQUESTCREATE",
  SupportSpeedTest = "SUPPORTSPEEDTEST",
  PrivacyStatement = "PRIVACYSTATEMENT",
  Approvals = "APPROVALS",
  UploadSuccess = "UPLOADSUCCESS",
  Agreement = "AGREEMENT"
}

export enum AdminRoutes {
  Users = "USERS",
  UserActivityList = "USERACTIVITYLIST",
  UserGroups = "USERGROUPS",
  UserDetails = "USERDETAILS",
  UserGroupDetails = "USERGROUPDETAILS",
  UserList = "USERLIST",
  ContextUserList = "CONTEXTUSERLIST",
  AdminContext = "ADMINCONTEXT",
  SiteBranding = "SITEBRANDING",
  SiteBrandingDetails = "SITEBRANDINGDETAILS",
  UserBatchSendInviteList = "USERBATCHSENDINVITELIST",
  CreateUserGroup = "CREATEUSERGROUP",
  ProjectDetails = "PROJECTDETAILS",
  ProjectList = "PROJECTLIST",
  ResourceGroupDetails = "RESOURCEGROUPDETAILS",
  ResourceGroupsList = "RESOURCEGROUPSLIST",
  RoleDetails = "ROLEDETAILS",
  RolesList = "ROLESLIST",
  Activity = "ACTIVITY",
  Assignments = "ASSIGNMENTS",
  Report = "REPORT",
  AdminApplications = "APPLICATIONS",
  AdminApplication = "APPLICATION",
  Employers = "EMPLOYERS",
  EmployerDetails = "EMPLOYERDETAILS",
  Domains = "DOMAINS",
  SsoGroupDetails = "SSOGROUPDETAILS",
  SsoGroups = "SSOGROUPS",
  ResourceList = "RESOURCELIST",
  SecureUploadLocationsList = "SECUREUPLOADLOCATIONS",
  SecureUploadDetails = "SECUREUPLOADDETAILS",
  Messages = "MESSAGES",
  MessageDetails = "MESSAGEDETAILS",
  NotificationDetails = "NOTIFICATIONDETAILS",
  JobQueue = "JOBQUEUE"
}

export enum RouteType {
  Authenticated,
  NotAuthenticated,
  BasicAuthenticated
}

export interface IRoute {
  id: Routes | AdminRoutes;
  exact?: boolean;
  path?: string;
  lookupSegment?: string;
  component: any;
  routeType: RouteType;
}

//ORDER MATTERS
export const routes: Record<string, IRoute> = {
  [Routes.Activation]: {
    id: Routes.Activation,
    exact: true,
    path: "/welcome/:activationToken",
    component: Login,
    routeType: RouteType.NotAuthenticated
  },
  [Routes.Error]: {
    id: Routes.Error,
    exact: true,
    path: "/Error",
    component: Error,
    routeType: RouteType.NotAuthenticated
  },
  [Routes.UploadSuccess]: {
    id: Routes.UploadSuccess,
    exact: true,
    path: "/UploadSuccess",
    component: UploadSuccess,
    routeType: RouteType.NotAuthenticated
  },
  [Routes.ResetPassword]: {
    id: Routes.ResetPassword,
    exact: true,
    path: "/signin/reset-password/:token",
    component: Login,
    routeType: RouteType.NotAuthenticated
  },
  [Routes.PrivacyStatement]: {
    id: Routes.PrivacyStatement,
    exact: true,
    path: "/privacy-statement",
    component: PrivacyStatement,
    routeType: RouteType.NotAuthenticated
  },
  [Routes.Default]: {
    id: Routes.Default,
    exact: false,
    path: "/",
    component: Login,
    routeType: RouteType.NotAuthenticated
  }
};

export const adminRoutes: Record<string, IRoute> = {
  [AdminRoutes.RolesList]: {
    path: "/administration/roles",
    exact: false,
    component: RolesList,
    id: AdminRoutes.RolesList,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.UserDetails]: {
    path: "/administration/userDetails/:userId",
    lookupSegment: "/administration/userDetails/",
    exact: true,
    component: UserDetails,
    id: AdminRoutes.UserDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.UserList]: {
    path: "/administration/users",
    exact: false,
    component: UserList,
    id: AdminRoutes.UserList,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.ContextUserList]: {
    path: "/administration/context-users",
    exact: false,
    component: ContextUserList,
    id: AdminRoutes.ContextUserList,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.AdminContext]: {
    path: "/administration/admin-context/0",
    exact: false,
    component: CreateAdminContext,
    id: AdminRoutes.AdminContext,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.UserBatchSendInviteList]: {
    path: "/administration/userSendInvite",
    exact: false,
    component: UserBatchSendInviteList,
    id: AdminRoutes.UserBatchSendInviteList,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.UserActivityList]: {
    path: "/administration/userActivity",
    exact: false,
    component: UserActivityList,
    id: AdminRoutes.UserActivityList,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.UserGroups]: {
    path: "/administration/userGroups",
    exact: false,
    component: UserGroups,
    id: AdminRoutes.UserGroups,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.ProjectList]: {
    path: "/administration/projects",
    exact: false,
    component: ProjectList,
    id: AdminRoutes.ProjectList,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.CreateUserGroup]: {
    path: "/administration/createusergroup",
    exact: false,
    component: CreateUserGroup,
    id: AdminRoutes.CreateUserGroup,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.UserGroupDetails]: {
    path: "/administration/userGroupDetails/:groupId",
    lookupSegment: "/administration/userGroupDetails/",
    exact: true,
    component: UserGroupDetails,
    id: AdminRoutes.UserGroupDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.ProjectDetails]: {
    path: "/administration/projectDetails/:projectId",
    lookupSegment: "/administration/projectDetails/",
    exact: true,
    component: ProjectDetails,
    id: AdminRoutes.ProjectDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.RoleDetails]: {
    path: "/administration/roleDetails/:roleId",
    lookupSegment: "/administration/roleDetails/",
    exact: true,
    component: RoleDetails,
    id: AdminRoutes.RoleDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.Report]: {
    path: "/administration/reports",
    exact: false,
    component: Report,
    id: AdminRoutes.Report,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.Assignments]: {
    path: "/administration/assignments",
    exact: false,
    component: Assignments,
    id: AdminRoutes.Assignments,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.ResourceGroupsList]: {
    path: "/administration/resourceGroups",
    exact: false,
    component: ResourceGroupsList,
    id: AdminRoutes.ResourceGroupsList,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.ResourceGroupDetails]: {
    path: "/administration/resourceGroupDetails/:resourceGroupId",
    lookupSegment: "/administration/resourceGroupDetails/",
    exact: true,
    component: ResourceGroupDetails,
    id: AdminRoutes.ResourceGroupDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.AdminApplications]: {
    path: "/administration/applications",
    exact: false,
    component: AdminApplications,
    id: AdminRoutes.AdminApplications,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.AdminApplication]: {
    path: "/administration/app/details/:id",
    lookupSegment: "/administration/details/",
    exact: false,
    component: AdminApplication,
    id: AdminRoutes.AdminApplication,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.Employers]: {
    path: "/administration/employers",
    lookupSegment: "/administration/employers",
    exact: false,
    component: EmployersList,
    id: AdminRoutes.Employers,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.EmployerDetails]: {
    path: "/administration/employerDetails/:employerId",
    lookupSegment: "/administration/employerDetails/",
    exact: false,
    component: EmployerDetails,
    id: AdminRoutes.EmployerDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.Domains]: {
    path: "/administration/domains",
    lookupSegment: "/administration/domains",
    exact: false,
    component: DomainsList,
    id: AdminRoutes.Domains,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.SsoGroupDetails]: {
    path: "/administration/sso-group/:groupId",
    lookupSegment: "/administration/sso-group",
    exact: false,
    component: SsoGroupDetails,
    id: AdminRoutes.SsoGroupDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.SsoGroups]: {
    path: "/administration/sso-groups",
    lookupSegment: "/administration/sso-groups",
    exact: false,
    component: SsoGroupList,
    id: AdminRoutes.SsoGroups,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.ResourceList]: {
    path: "/administration/resources",
    exact: false,
    component: ResourceList,
    id: AdminRoutes.ResourceList,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.SecureUploadLocationsList]: {
    path: "/administration/secureuploadlocations",
    exact: false,
    component: SecureUploadLocationsList,
    id: AdminRoutes.SecureUploadLocationsList,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.SecureUploadDetails]: {
    path: "/administration/secureUploadDetails/:id",
    lookupSegment: "/administration/secureUploadDetails/",
    exact: false,
    component: SecureUploadDetails,
    id: AdminRoutes.SecureUploadDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.SiteBranding]: {
    path: "/administration/siteBranding",
    exact: false,
    component: SiteBrandingList,
    id: AdminRoutes.SiteBranding,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.SiteBrandingDetails]: {
    path: "/administration/siteBrandingDetails/:id",
    lookupSegment: "/administration/siteBrandingDetails/",
    exact: false,
    component: SiteBrandingDetails,
    id: AdminRoutes.SiteBrandingDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.Messages]: {
    path: "/administration/messages",
    lookupSegment: "/administration/messages",
    exact: false,
    component: MessageList,
    id: AdminRoutes.Messages,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.MessageDetails]: {
    path: "/administration/messageDetails/:messageId",
    lookupSegment: "/administration/messageDetails/",
    exact: false,
    component: MessageDetails,
    id: AdminRoutes.MessageDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.NotificationDetails]: {
    path: "/administration/notificationDetails/:notificationId",
    lookupSegment: "/administration/notificationDetails/",
    exact: true,
    component: MessageDetails,
    id: AdminRoutes.NotificationDetails,
    routeType: RouteType.Authenticated
  },
  [AdminRoutes.JobQueue]: {
    path: "/administration/job/jobqueue",
    lookupSegment: "/administration/job/jobqueue",
    exact: true,
    component: JobQueue,
    id: AdminRoutes.NotificationDetails,
    routeType: RouteType.Authenticated
  },
};

export const basicAuthenticatedRoutes: Record<string, IRoute> = {
  [Routes.Default]: {
    id: Routes.Default,
    exact: true,
    path: "/",
    component: Login,
    routeType: RouteType.BasicAuthenticated
  },
  [Routes.Agreement]: {
    id: Routes.Agreement,
    exact: true,
    path: "/agreement",
    component: Agreements,
    routeType: RouteType.BasicAuthenticated
  },
  [Routes.PageNotFound]: {
    id: Routes.PageNotFound,
    component: PageNotFound,
    routeType: RouteType.BasicAuthenticated
  }
}

//ORDER MATTERS - Default must be first item and PageNotFound must be the last item.
export const authenticatedRoutes: Record<string, IRoute> = {
  [Routes.Default]: {
    id: Routes.Default,
    exact: true,
    path: "/",
    component: Login,
    routeType: RouteType.Authenticated
  },
  [Routes.DashboardReportPage]: {
    id: Routes.DashboardReportPage,
    exact: true,
    path: "/dashboards/:id/:page",
    component: Overview,
    routeType: RouteType.Authenticated
  },
  [Routes.DashboardReport]: {
    id: Routes.DashboardReport,
    exact: true,
    path: "/dashboards/:id",
    component: Overview,
    routeType: RouteType.Authenticated
  },
  [Routes.Dashboard]: {
    id: Routes.Dashboard,
    exact: true,
    path: "/dashboards",
    component: Overview,
    routeType: RouteType.Authenticated
  },
  [Routes.Administration]: {
    id: Routes.Administration,
    exact: false,
    path: "/administration/:type",
    lookupSegment: "/administration/",
    component: Administration,
    routeType: RouteType.Authenticated
  },
  [Routes.Applications]: {
    id: Routes.Applications,
    exact: false,
    path: "/applications",
    component: Applications,
    routeType: RouteType.Authenticated
  },
  [Routes.ChangePassword]: {
    id: Routes.ChangePassword,
    exact: false,
    path: "/changepassword",
    component: ChangePassword,
    routeType: RouteType.Authenticated
  },
  [Routes.Files]: {
    id: Routes.Files,
    exact: false,
    path: "/filelist",
    component: FileList,
    routeType: RouteType.Authenticated
  },
  [Routes.Home]: {
    id: Routes.Home,
    exact: false,
    path: "/home",
    component: Home,
    routeType: RouteType.Authenticated
  },
  [Routes.Login]: {
    id: Routes.Login,
    exact: false,
    path: "/login",
    component: Login,
    routeType: RouteType.Authenticated
  },
  [Routes.SupportList]: {
    id: Routes.SupportList,
    exact: false,
    path: "/support/servicerequestlist",
    component: ServiceNowRequests,
    routeType: RouteType.Authenticated
  },
  [Routes.SupportWorkRequestList]: {
    id: Routes.SupportWorkRequestList,
    exact: false,
    path: "/support/workrequestlist",
    component: PulseWorkRequests,
    routeType: RouteType.Authenticated
  },
  [Routes.SupportWorkRequestCreate]: {
    id: Routes.SupportWorkRequestCreate,
    exact: false,
    path: "/support/createworkrequest",
    component: PulseWorkRequests,
    routeType: RouteType.Authenticated
  },
  [Routes.SupportRequestDetails]: {
    id: Routes.SupportRequestDetails,
    exact: false,
    path: "/support/servicerequest/:id",
    lookupSegment: "/support/servicerequest/",
    component: ServiceNowRequest,
    routeType: RouteType.Authenticated
  },
  //JWK Disabling this for now
  // [Routes.SupportSpeedTest]: {
  //   id: Routes.SupportSpeedTest,
  //   exact: false,
  //   path: "/support/speedtest",
  //   component: SpeedTest,
  //   routeType: RouteType.Authenticated
  // },
  [Routes.ApprovalDetails]: {
    id: Routes.ApprovalDetails,
    exact: false,
    path: "/approvals/:id",
    lookupSegment: "/approvals/",
    component: ApprovalList,
    routeType: RouteType.Authenticated
  },
  [Routes.Approvals]: {
    id: Routes.Approvals,
    exact: false,
    path: "/approvals",
    component: ApprovalList,
    routeType: RouteType.Authenticated
  },
  [Routes.UploadSuccess]: {
    id: Routes.UploadSuccess,
    exact: false,
    path: "/UploadSuccess",
    component: UploadSuccess,
    routeType: RouteType.Authenticated
  },
  [Routes.PageNotFound]: {
    id: Routes.PageNotFound,
    component: PageNotFound,
    routeType: RouteType.Authenticated
  }
};