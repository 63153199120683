import React, { useState, useEffect } from "react";
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridItemChangeEvent,
  GridRowClickEvent,
  GridCellProps,
  GridColumnProps,
  GridHeaderCellProps,
  GridRowProps
} from "@progress/kendo-react-grid";
import { User } from "../../../models/UserModels";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import "./EditGridField.scss";
import { updateValForField, updateStatusForField } from "./WizardElements";
import { IWizardLookupValues, IGridDataColumns, IFieldValidation } from "../../../models/Wizard";
import Tooltip from "@material-ui/core/Tooltip";

type gridUser = User & {
  isDirty: boolean;
}

const EditGridField = (fieldRenderProps: FieldRenderProps) => {
  const gridErrorMsg = `Update the errors in this table to proceed.`;
  const maxRecordCount: number = fieldRenderProps.fieldData.maxRecordCount;
  const disableAddRecord = fieldRenderProps.fieldData.disableAddRecordBtn;
  const fieldDataColumns: Array<IGridDataColumns> = fieldRenderProps.fieldData.columns;
  const lastColumnField =
    fieldDataColumns && fieldDataColumns.length > 0 && fieldDataColumns[fieldDataColumns.length - 1]["field"]
      ? fieldDataColumns[fieldDataColumns.length - 1]["field"]
      : null;
  const dataValue =
    fieldRenderProps && fieldRenderProps.valInfo && fieldRenderProps.valInfo.value
      ? fieldRenderProps.valInfo.value
      : [];
  const [data, setData] = React.useState<Array<gridUser>>([]);
  const [editID, setEditID] = React.useState<number | null>(null);
  const [disableAddRowBtn, setDisableAddRowBtn] = React.useState(
    disableAddRecord ? disableAddRecord : dataValue ? dataValue.length == maxRecordCount : false
  );

  useEffect(() => {
    if (dataValue.length === 0) {
      const importData = fieldRenderProps.fieldData.getBulkImport(fieldRenderProps.fieldValues);
      if (importData && importData.length > 0) {
        setBulkImportData(importData);
      } else {
        addRecord(null);
      }
    }
    else {
      setData(dataValue);
      validateDataOnLoad(dataValue);
    }
  }, []);

  const renderUPNHeaderCell = (props: GridHeaderCellProps) => {
    const column = fieldDataColumns.filter(f => f.field == props.field)[0];
    return (
      <Tooltip
        classes={{ tooltip: "mui-tooltip-no-maxwidth" }}
        title={column.toolTipText ? column.toolTipText : column.title}
        placement="top"
        arrow
      >
        <a className="k-link">{column.title}</a>
      </Tooltip>
    );
  };

  const isNewRow = (dataItem: any) => {
    let propCount = 0;
    if (dataItem) {
      Object.keys(dataItem).map((key: string) => {
        if (dataItem[key]) {
          propCount++;
        }
      });
    }
    return propCount < fieldDataColumns.length;
  };

  const handleTextCellChange = (dataItem: any, field: string, value: string) => {
    const inEditID = dataItem.id;
    const newDataItem = dataItem;
    newDataItem[field] = value;
    const newData = data.map(item => (item.id === inEditID ? { ...item, [field]: value, isDirty: true } : item));
    handleInputValueChangeOrBlur(dataItem, field, value, newData);
  };

  const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    const dataItem = props.dataItem;
    const isRowDataValid = isValidRowData(dataItem);
    const trProps: any = dataItem.isDirty && !isRowDataValid ? { className: "invalid" } : {};
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  const handleInputBlur = (dataItem: any, field: string, value: string) => {
    const newDataItem = dataItem;

    const hasEmailInColumns = fieldDataColumns.filter(f => f.field == "email").length > 0;
    if (field == "username" && hasEmailInColumns && !dataItem["email"]) {
      newDataItem["email"] = value;
      const newData = data.map(item => (item.id === dataItem.id ? { ...item, ["email"]: value, isDirty: true } : item));
      handleInputValueChangeOrBlur(dataItem, "email", value, newData);
    }
  }

  const handleInputValueChangeOrBlur = (dataItem: any, field: string, value: string, newData: gridUser[]) => {
    setData([...newData]);
    updateGridFieldData([...newData.filter(d => d.isDirty == true)]);
    const isValidData = invalidRowCount(newData);
    const hasEmptyRows = newData.filter(d => d.isDirty == false);
    enableOrDisableAddNextButton(isValidData, false, hasEmptyRows.length > 0 || newData.length == maxRecordCount);
  }

  const renderCell = (td: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps) => {
    const { field, dataItem } = cellProps;
    const value = dataItem && dataItem[field] ? dataItem[field] : "";

    const column = fieldDataColumns.filter(f => f.field == field)[0];
    const placeholder = column && column.placeHolder ? column.placeHolder : "";
    const cellValidationError = dataItem.isDirty ? validateCell(dataItem, column) : "";
    return (
      <td>
        <Tooltip title={cellValidationError} >
          <input
            className={cellValidationError ? "error-input" : ""}
            type="text"
            value={value}
            placeholder={placeholder}
            maxLength={100}
            onChange={e => handleTextCellChange(dataItem, field, e.target.value)}
            onBlur={e => handleInputBlur(dataItem, field, e.target.value)}
          />
        </Tooltip>
        {data.length > 1 &&
          lastColumnField == field && (
            <div className="remove-icon">
              <Tooltip title="Remove user from list" placement="top" arrow>
                <i className="fa-solid fa-trash" onClick={e => removeRecord(dataItem.id)} />
              </Tooltip>
            </div>
          )}
      </td>
    );
  };

  const columns = (() => {
    const cols = [];
    for (let c = 0; c <= fieldDataColumns.length - 1; c++) {
      cols.push(
        <GridColumn
          field={fieldDataColumns[c].field}
          headerCell={renderUPNHeaderCell}
          title={fieldDataColumns[c].title}
        />
      );
    }

    return cols;
  })();

  const rowClick = (event: GridRowClickEvent) => {
    setEditID(event.dataItem.id);
  };

  const validateCell = (dataItem: any, column: IGridDataColumns, updatedData: Array<any> = null) => {
    const fieldName = column.field;
    const fieldValidations = column.validations as Array<IFieldValidation>;
    const value = dataItem && dataItem[fieldName] ? dataItem[fieldName].trim() : "";

    if (!dataItem.isDirty) {
      return "";
    }

    if (fieldValidations && fieldValidations.length > 0) {
      const requiredValidation = fieldValidations.find(v => v.name == "required");
      if (requiredValidation && !value) {
        return requiredValidation.errorMessage;
      }

      const emailValidation = fieldValidations.find(v => v.name == "email");
      if (emailValidation && value && !/\S+@\S+\.\S+/.test(value)) {
        return emailValidation.errorMessage;
      }

      const customUserNameValidation = fieldValidations.find(v => v.name === "customUserName");
      const hasUsernameInColumns = fieldDataColumns.filter(f => f.field == "username").length > 0;
      const hasEmaiInColumns = fieldDataColumns.filter(f => f.field == "email").length > 0;
      if (customUserNameValidation && hasUsernameInColumns && hasEmaiInColumns && (fieldName == "username" || fieldName == "email")) {
        const emailFieldValue = dataItem["email"];
        const usernameFieldValue = dataItem["username"];
        if (!/\S+@\S+\.\S+/.test(emailFieldValue) && !/\S+@\S+\.\S+/.test(usernameFieldValue)) {
          return customUserNameValidation.errorMessage;
        }
      }

      const usernameFormatValidation = fieldValidations.find(v => v.name === "usernameFormat")
      if (usernameFormatValidation && value && !/\S+@\S+/.test(value)) {
        return usernameFormatValidation.errorMessage;
      }

      const customRegexFormatValidation = fieldValidations.find(v => v.name === "regexValidation")
      if (customRegexFormatValidation) {
        const regex = new RegExp(customRegexFormatValidation.regExp);
        if (customRegexFormatValidation && value && !regex.test(value)) {
          return customRegexFormatValidation.errorMessage;
        }
      }

      const uniqueValidation = fieldValidations.find(v => v.name == "unique");
      if (uniqueValidation && value) {
        const gridData = updatedData ? updatedData : data;
        const rowExistsWithSameEmail = gridData
          ? gridData.find(
            d => (d[fieldName] ? d[fieldName].toLowerCase() === value.toLowerCase() : false) && d.id != dataItem.id
          )
          : false;
        return !rowExistsWithSameEmail ? "" : uniqueValidation.errorMessage;
      }
    }
    return "";
  };

  const isValidRowData = (dataItem: any, updatedData: Array<any> = null) => {
    return fieldDataColumns.every(
      (column: IGridDataColumns) => validateCell(dataItem, column, updatedData).length == 0
    );
  };

  const invalidRowCount = (rows: Array<any>) => {
    let invallidRows = 0;

    for (const row in rows) {
      if (!isValidRowData(rows[row], rows)) {
        invallidRows++;
      }
    }
    return invallidRows;
  };

  const validateDataOnLoad = (rows: Array<any>) => {
    const invalidCount = invalidRowCount(rows);
    if (invalidCount === 0) {
      setData([...rows]);
      updateGridFieldData([...rows]);
    }
    enableOrDisableAddNextButton(invalidCount, false, rows.length == maxRecordCount);
  };

  const enableOrDisableAddNextButton = (
    invalidCount: number,
    hasOnlyOneRow: boolean = false,
    isAddNewRowClick: boolean = false
  ) => {
    updateStatusForField(
      false,
      fieldRenderProps,
      hasOnlyOneRow || invalidCount === 0 ? false : true,
      invalidCount === 0 ? "" : `Errors Detected: ${invalidCount}. ${gridErrorMsg}`
    );
    setDisableAddRowBtn(isAddNewRowClick ? true : invalidCount > 0);
  };

  const updateGridFieldData = (gridData: Array<any>) => {
    updateValForField([...gridData], fieldRenderProps, null, (fieldDataColumns as unknown) as IWizardLookupValues);
  };

  const addRecord = (e: any) => {
    const newRecord = { id: data.length == 0 ? data.length + 1 : Math.max(...data.map(u => u.id)) + 1, isDirty: false } as gridUser;
    const invalidCount = data.length > 0 ? invalidRowCount(data) : 0;
    const hasOnlyOneRow = data.length == 0 || (data.length == 1 && data[0] && data[0].isDirty != false);
    if (invalidCount === 0) {
      setData([newRecord, ...data]);
      setEditID(newRecord.id);
    }
    enableOrDisableAddNextButton(invalidCount, hasOnlyOneRow, true);
  };

  const removeRecord = (id: number) => {
    const gridData = [...data];
    let resultData = gridData.filter(f => f.id != id);
    if (resultData.length === 1) {
      resultData = resultData.map(item => ({ ...item, isDirty: true }))
      setData(resultData);
    }
    else {
      setData(resultData.length > 0 ? [...resultData] : [...gridData]);
    }
    updateGridFieldData(resultData.length > 0 ? [...resultData] : [...gridData]);
    const invalidCount = resultData.length > 0 && data.length > 1 ? invalidRowCount(resultData) : 0;
    enableOrDisableAddNextButton(invalidCount);
  };

  const setBulkImportData = async (importData: any) => {
    const impObject = importData.map((d: any, i: number) => {
      return {
        firstName: d["FirstName"],
        lastName: d["LastName"],
        username: d["Username"],
        email: d["Email"] ? d["Email"] : d["Username"],
        id: i + 1,
        isDirty: true
      } as gridUser
    });

    setData(impObject);
    validateDataOnLoad([...impObject]);
  }


  return (
    <div>
      {" "}
      <div className="edit-grid-user-wizard-element">
        <Button
          title="Add User"
          className="btn-add-user"
          icon={"plus"}
          primary={true}
          onClick={addRecord}
          disabled={disableAddRowBtn}
        >
          {fieldRenderProps.fieldData.addButtonLabel}
        </Button>
        <Grid
          data={data.map((item: gridUser) => ({ ...item, inEdit: item.id === editID }))}
          editField="inEdit"
          onRowClick={rowClick}
          cellRender={renderCell}
          rowRender={rowRender}
        >
          {columns}
        </Grid>
        <div className="grid-count">
          <i className="bi bi-file-text" />&nbsp; Total: {dataValue.length ? dataValue.length : data ? data.length : 0} of {maxRecordCount}
        </div>
      </div>
    </div>
  );
};

export default EditGridField;
