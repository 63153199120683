import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import * as InternalMessagingState from "../../../store/InternalMessaging";
import AdminService from "../../../services/AdminService";
import * as HeaderStore from "../../../store/Header";
import { v4 as uuid } from "uuid";
import { User } from "../../../models/UserModels";
import { NavLink } from "react-router-dom";
import BulkUserImport from "./BulkUserImport/BulkUserImport";
import { GridPagerSettings, GridSortSettings, GridColumnProps, GridColumnResizeEvent } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Dialog } from "@progress/kendo-react-dialogs";
import { AdvancedGrid, IGridParams } from "../../common/grid/AdvancedGrid";
import "./UserList.scss";
import { FilterDescriptor, CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import * as SessionStore from "../../../store/Session";
import { FilterDropdownData, GridPreferences, GridUserPreferences } from "../../../models/Preferences";
import { ColumnFromField, setSavedColumnsToGrid, setDefaultColumnsToGrid, getModifiedCorrectNumericFilter, getFields, saveColumnWidthPreferenceInDB } from "../../common/grid/columns/ColumnHelper";
import { ColumnFieldListBox } from "../../common/grid/columns/ColumnFieldListBox";
import SettingsIcon from "@material-ui/icons/Settings";
import { IGridPreference } from "../../../models/Preferences";
import { AccountStates } from "../../../models/UserModels";
import CommonHelper from "../../common/utilities/CommonHelper";

interface IProps {
  history: any;
}

interface UserType {
  name: string;
  id: number;
}

interface IUserFetchResult {
  totalUsers: number;
  users: Array<User>;
}

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  SessionStore.ISessionState &
  typeof InternalMessagingState.actionCreators;

type State = {
  dataState: IGridParams;
  importModal: boolean;
  disableCancel: boolean;
  fetchingUsers: boolean;
  fetchResults: IUserFetchResult;
  hasError: boolean;
  showSettingsDialog: boolean;
  availableFields: Array<GridUserPreferences>;
  selectedFields: Array<GridUserPreferences>;
};

const defaultColWidth = 200;
const preferenceType = "UserListGridPreferences";
const tableKey = "UserList";

const userListMasterFields = {
  [tableKey]: [{ field: "lastName | firstName", colWidth: defaultColWidth },
  { field: "username", colWidth: defaultColWidth },
  { field: "email", colWidth: defaultColWidth },
  { field: "superAdmin", colWidth: defaultColWidth },
  { field: "statusTypeId", colWidth: defaultColWidth },
  { field: "lastLoginDate", colWidth: defaultColWidth },
  { field: "invitationsentDate", colWidth: defaultColWidth },
  { field: "createdDate", colWidth: defaultColWidth },
  { field: "comments", colWidth: defaultColWidth },
  { field: "incidentNumber", colWidth: defaultColWidth },
  { field: "employerId", colWidth: defaultColWidth },
  { field: "idpId", colWidth: defaultColWidth },
  { field: "lastmodifiedDate", colWidth: defaultColWidth },
  { field: "createdByUser", colWidth: defaultColWidth },
  { field: "lastmodifiedByUser", colWidth: defaultColWidth }]
} as Record<string, Array<GridPreferences>>;

const accountStatusList = [{ name: AccountStates.Staged, id: 1 }, { name: AccountStates.Provisioned, id: 2 }, { name: AccountStates.Active, id: 3 },
{ name: AccountStates.PasswordExpired, id: 4 }, { name: AccountStates.LockedOut, id: 5 }, { name: AccountStates.Recovery, id: 6 },
{ name: AccountStates.Suspended, id: 7 }, { name: AccountStates.Deprovisioned, id: 8 }] as Array<FilterDropdownData>;

const gridUserPreferences = {
  ["lastName | firstName"]: { field: "lastName | firstName", type: "text", displayName: "Users", isDefault: true, isMandatory: true },
  ["username"]: { field: "username", type: "text", displayName: "Username", isDefault: true, isMandatory: true },
  ["email"]: { field: "email", type: "text", displayName: "Email", isDefault: true, isMandatory: true },
  ["superAdmin"]: { field: "superAdmin", type: "DropDown", displayName: "Account Type", filterDropdownData: [{ name: "User", id: 0 }, { name: "Global Admin", id: 1 }], isDefault: true },
  ["statusTypeId"]: { field: "statusTypeId", type: "DropDown", displayName: "Account Status", isDefault: true, filterDropdownData: CommonHelper.sortArrayObjectByString(accountStatusList, "name") },
  ["lastLoginDate"]: { field: "lastLoginDate", type: "DateTime", displayName: "Last Login", isDefault: true },
  ["invitationsentDate"]: { field: "invitationsentDate", type: "DateTime", displayName: "Invitation Sent", isDefault: true },
  ["createdDate"]: { field: "createdDate", type: "DateTime", displayName: "Date Created", isDefault: true },
  ["comments"]: { field: "comments", type: "text", displayName: "Comments" },
  ["incidentNumber"]: { field: "incidentNumber", type: "text", displayName: "Incident Number" },
  ["employerId"]: { field: "employerId", type: "Int", displayName: "Employer Id" },
  ["idpId"]: { field: "idpId", type: "text", displayName: "Okta Id" },
  ["lastmodifiedDate"]: { field: "lastmodifiedDate", type: "DateTime", displayName: "Last Modified Date" },
  ["createdByUser"]: { field: "createdByUser", type: "text", displayName: "Created By" },
  ["lastmodifiedByUser"]: { field: "lastmodifiedByUser", type: "text", displayName: "Last Modified By" }
} as Record<string, GridUserPreferences>

class UserList extends React.PureComponent<Props, State> {
  lastSelectedIndex: any;
  pagerSettings: GridPagerSettings;
  sortSettings: GridSortSettings;
  columns: Array<GridColumnProps>;
  myGrid: AdvancedGrid<User, IGridParams>;

  constructor(props: Props) {
    super(props);

    this.state = {
      dataState: {
        skip: 0,
        take: 100,
        sort: [{ field: "lastName | firstName | username", dir: "asc" }]
      },
      importModal: false,
      disableCancel: false,
      fetchingUsers: true,
      fetchResults: {
        totalUsers: 0,
        users: new Array<User>()
      },
      hasError: false,
      showSettingsDialog: false,
      availableFields: new Array<GridUserPreferences>(),
      selectedFields: new Array<GridUserPreferences>()
    };

    this.columns = this.intializeColumns();

    props.requestAdminContextClear();
  }

  intializeColumns = () => {
    let cols = [] as Array<GridColumnProps>;
    return cols;
  }

  setDynamicColumnsToGrid = () => {
    const savedGridColumns = this.props.sessionData.userPrefs.UserListGridPreferences as IGridPreference;
    if (savedGridColumns && savedGridColumns[tableKey]) {
      this.columns = setSavedColumnsToGrid(savedGridColumns[tableKey], gridUserPreferences, this.setColumnProperties, defaultColWidth);
    }
    else {
      this.columns = setDefaultColumnsToGrid(userListMasterFields[tableKey], gridUserPreferences, this.setColumnProperties);
    }
  }

  setColumnProperties = (field: GridUserPreferences) => {
    const col = ColumnFromField(field);
    if (col.title.toLowerCase() === "users") {
      col.cell = this.props.sessionData.permissions.has("EpiqAdminGetUser") ? this.usersBackground : this.usersBackgroundNoLink;
      col.field = "lastName | firstName | username";
    }
    else if (col.title.toLowerCase() === "account type")
      col.cell = this.isSuperAdmin;
    else if (col.title.toLowerCase() === "username")
      col.cell = this.userEmail;
    else if (col.title.toLowerCase() === "account status") {
      col.cell = this.accountStatus;
    }

    return col;
  }

  handleColumnResize = (event: GridColumnResizeEvent) => {
    if (event.end) {//Indicates that resizing is complete and the user has dropped the resize handler.
      let field = event.columns[event.index].field;

      if (field === "lastName | firstName | username") { // this is temparory fix, after reseting the user grid preferecenes data in db, we can remove this condition.
        field = "lastName | firstName";
      }

      const newWidth = event.columns[event.index].width;
      const savedGridColumns = this.props.sessionData.userPrefs.UserListGridPreferences as IGridPreference;
      const masterFields = userListMasterFields[tableKey] as Array<GridPreferences>;
      const gridPref = ((savedGridColumns && savedGridColumns[tableKey]) ? savedGridColumns[tableKey] : masterFields.filter(x => (gridUserPreferences[x.field] && gridUserPreferences[x.field].isDefault === true)) as Array<GridPreferences>);
      saveColumnWidthPreferenceInDB(tableKey, preferenceType, gridPref, field, newWidth);
    }
  }

  componentDidMount() {
    this.setDynamicColumnsToGrid();
    this.setState({ ...this.state, fetchingUsers: false })
  }

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
  }

  disableCancelButton = (disable: boolean) => {
    this.setState({ disableCancel: disable });
  };

  async fetchUsers(dataState: IGridParams) {
    let filters = this.getModifiedCorrectDateFilter(dataState.filters);
    filters = this.getModifiedCorrectDateFilter(filters, "invitationsentDate");
    filters = this.getModifiedCorrectDateFilter(filters, "createdDate");
    filters = this.getModifiedCorrectDateFilter(filters, "lastmodifiedDate");
    filters = getModifiedCorrectNumericFilter(filters, "employerId");

    const userListFilters = filters
      ? filters.map(compositeFilter => {
        const userTypeFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "superAdmin"
        ) as FilterDescriptor;

        if (userTypeFilter) {
          const userTypeFilterValue = userTypeFilter.value
            ? userTypeFilter.value.id == 0 || userTypeFilter.value.id == 1
              ? userTypeFilter.value.id == 0
                ? false
                : true
              : null
            : false;

          return {
            ...compositeFilter,
            filters: [
              {
                ...userTypeFilter,
                field: "superAdmin",
                operator: "eq",
                value: userTypeFilterValue
              }
            ]
          };
        }

        const statusTypeFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "statusTypeId"
        ) as FilterDescriptor;

        if (statusTypeFilter) {
          const statusTypeFilterValue = statusTypeFilter.value && statusTypeFilter.value.id;

          return {
            ...compositeFilter,
            filters: [
              {
                ...statusTypeFilter,
                field: "statusTypeId",
                operator: "eq",
                value: statusTypeFilterValue
              }
            ]
          };
        }

        return compositeFilter;
      })
      : [];
    let reverseSortDescriptors: SortDescriptor[] = this.updateUserTypeSortDirection(dataState);

    this.setState({ ...this.state, fetchingUsers: true }, async () => {
      const result = await AdminService.getUserList({ ...dataState, filters: userListFilters, sort: reverseSortDescriptors });

      let updateSortDescriptors: SortDescriptor[] = this.updateUserTypeSortDirection(dataState);
      this.setState({ ...this.state, dataState: { ...dataState, sort: updateSortDescriptors } });

      this.setState({
        ...this.state,
        fetchingUsers: false,
        hasError: !result.ok,
        fetchResults: {
          ...this.state.fetchResults,
          users: result.ok ? result.data.results : new Array<User>(),
          totalUsers: result.ok && result.data.results ? (result.data.results as Array<User>).length : 0
        }
      });
    });
  }

  updateUserTypeSortDirection = (dataState: IGridParams) => {
    const sort: SortDescriptor[] = dataState.sort ? [...dataState.sort] : [];
    return sort.map((item, index) => {

      if (item.field === "superAdmin") {
        item.dir = item.dir === "asc" ? "desc" : "asc"
      }

      return item;
    });
  }

  getModifiedCorrectDateFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    dateColumnName: string = "lastLoginDate"
  ) => {
    // This function is used for date column filter in all advance grid pages.
    let isCreatedDateFilterExists = false;
    let createdDateValue: Date;
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const createdDateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === dateColumnName
        ) as FilterDescriptor;

        if (createdDateFilter) {
          const dateFilterValue = createdDateFilter.value;

          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            createdDateFilter.operator = 'eq';
          }

          createdDateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...createdDateFilter,
                  field: dateColumnName,
                  operator: typeof dateFilterValue === "string" && createdDateFilter.operator === 'isnotnull' ? "eq" : createdDateFilter.operator,
                  value: createdDateValue
                }
              ]
            };
          }

          isCreatedDateFilterExists = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...createdDateFilter,
                field: dateColumnName,
                operator: "gte",
                value: new Date(createdDateValue.toUTCString())
              }
            ]
          };
        }
        return compositeFilter;
      })
      : [];

    if (isCreatedDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: dateColumnName,
            operator: "lt",
            value: new Date(new Date(createdDateValue.setUTCDate(createdDateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    return filters;
  };

  filterElements(element: any) {
    if (element.tagName === "EM") {
      return true;
    }
    return false;
  }

  handleSendInviteClick() {
    this.props.history.push("/administration/userSendInvite");
  }

  handleCreateUserClick() {
    this.props.history.push("/administration/userDetails/0");
  }

  handleImportClick() {
    this.setState({ ...this.state, importModal: true });
  }

  handleShowSettingDialog() {
    const savedGridColumns = this.props.sessionData.userPrefs.UserListGridPreferences as IGridPreference;
    const masterFields = userListMasterFields[tableKey] as Array<GridPreferences>;
    const fields = getFields(savedGridColumns ? savedGridColumns[tableKey] : null, masterFields, gridUserPreferences);
    this.setState({ ...this.state, availableFields: fields.availableFields, selectedFields: fields.selectedFields, showSettingsDialog: true })
  }

  handleImportModalClose = (reload: boolean) => {
    this.setState({ ...this.state, importModal: false });

    if (reload) {
      this.myGrid.resetGridState({
        ...this.state.dataState, sort: [], filters: [
          {
            logic: "or",
            filters: [{ field: "lastName", operator: "contains", value: uuid() }
            ] as Array<FilterDescriptor>
          }
        ]
      });
    }
  };

  isSuperAdmin(props: any) {
    const superAdmin = props.dataItem.superAdmin ? "Global Admin" : "User";
    return (
      <td>
        <div>{superAdmin}</div>
      </td>
    );
  }

  accountStatus(props: any) {
    const status = accountStatusList.find(x => x.id === props.dataItem.statusTypeId);

    return (
      <td>
        <div>{status.name}</div>
      </td>
    );
  }

  async onRetryClick() {
    await this.fetchUsers(this.state.dataState);
  }

  usersBackground(props: any) {
    const fullName = props.dataItem.lastName + ", " + props.dataItem.firstName;
    return (
      <td>
        <div className="user-details">
          <div className="user-name">
            <NavLink to={`/administration/userDetails/${props.dataItem.id}`}>
              <em title={fullName}>{fullName}</em>
            </NavLink>
          </div>
        </div>
      </td>
    );
  }
  usersBackgroundNoLink(props: any) {
    const fullName = props.dataItem.lastName + ", " + props.dataItem.firstName;
    return (
      <td>
        <div className="user-details">
          <div className="user-name">
            <em title={fullName}>{fullName}</em>
          </div>
        </div>
      </td>
    );
  }

  userEmail(props: any) {
    const username = props.dataItem.username;
    return (
      <td className="email-cell">
        <em title={username}>{username}</em>
      </td>
    );
  }

  gridToolBarRender(): JSX.Element {
    return (
      <div className="toolbar-button-container">
        <Button className="user-setting" onClick={this.handleShowSettingDialog.bind(this)}>
          <SettingsIcon />
        </Button>
        {this.props.sessionData.permissions.has("EpiqAdminCreateUser") && (
          <>
            <Button className="add-user-btn" icon={"plus"} primary={true} onClick={this.handleCreateUserClick.bind(this)}>
              User
            </Button>
            <Button className="send-invite-user-btn btn-secondary" primary={false} onClick={this.handleImportClick.bind(this)}>
              Import
            </Button>
          </>
        )}
        {this.props.sessionData.permissions.has("EpiqAdminSendInvite") && (
          <Button className="import-user-btn btn-secondary" primary={false} onClick={this.handleSendInviteClick.bind(this)}>
            Send Invites
          </Button>
        )}
      </div>);
  }

  handleCloseSettingDialog(reload: boolean) {
    this.setState({
      ...this.state, showSettingsDialog: false, fetchingUsers: reload ? true : false
    });
    if (reload) {
      setTimeout(() => {
        this.setDynamicColumnsToGrid();
        if (this.state.dataState && this.state.dataState.filter) {
          this.setState({ ...this.state, fetchingUsers: false, dataState: { sort: null, filter: {} as CompositeFilterDescriptor, filters: null } });

          this.myGrid.resetGridState({
            ...this.state.dataState, sort: [{ field: "lastName", dir: "asc" }, { field: "firstName", dir: "asc" }], filter:
            {
              logic: "or",
              filters: [{ field: "lastName | firstName | username", operator: "contains", value: uuid() }
              ] as Array<FilterDescriptor>
            }
          });
        }
        else {
          this.setState({ ...this.state, fetchingUsers: false });
        }
      }, 500) // session object is taking some time to update when set the preferences, so I put the delay time out.
    }
  }

  render() {
    return (
      <>
        <div className="userList-grid-wrapper">
          <div className="userList-header-section">
            <h3>Users <span className="total-count">{this.state.fetchResults.totalUsers >= this.state.dataState.take ? `${this.state.dataState.take}+` : this.state.fetchResults.totalUsers.toString()}</span></h3>
            <p>View users by account status, which is updated daily via synchronization with Okta.</p>
          </div>
          <Tooltip openDelay={2} position="right" filter={this.filterElements}>
            <AdvancedGrid
              ref={(gridInstance: AdvancedGrid<User, IGridParams>) => {
                this.myGrid = gridInstance;
              }}
              showErrorState={this.state.hasError}
              showLoadingIndicator={this.state.fetchingUsers}
              sortable={{ mode: "multiple" }}
              data={this.state.fetchResults.users}
              dataFetch={this.fetchUsers.bind(this)}
              dataState={this.state.dataState}
              columns={this.columns}
              paging={false}
              noRecordsRender={<p>No users found.</p>}
              noLoadOnMount={true}
              filteredDataOnly={<p>Please filter to search for users.</p>}
              multiFieldFilterDelimiter="|"
              filterOperators={{
                text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }],
                numeric: [{ text: "grid.filterEqOperator", operator: "eq" }],
                boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }]
              }}
              gridToolbarContent={this.gridToolBarRender()}
              columnVirtualization={false}
              onColumnResize={this.handleColumnResize.bind(this)}
            />
          </Tooltip>

        </div>
        {this.state.importModal && (
          <Dialog
            onClose={this.handleImportModalClose.bind(this, false)}
            aria-labelledby="customized-dialog-title"
            className="modal bulk-import-modal"
          >
            <DialogTitle className="modal-title">
              Bulk Import Users
              <IconButton
                className="modal-close"
                aria-label="close"
                disabled={this.state.disableCancel}
                onClick={this.handleImportModalClose.bind(this, false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <BulkUserImport
                disableCancelButton={this.disableCancelButton.bind(this)}
                handleImportModalClose={this.handleImportModalClose.bind(this)}
              />
            </DialogContent>
          </Dialog>
        )}

        <ColumnFieldListBox
          showSettingFieldsDialog={this.state.showSettingsDialog}
          availableFields={this.state.availableFields}
          selectedFields={this.state.selectedFields}
          onSettingsDialogClose={this.handleCloseSettingDialog.bind(this)}
          tableKey={tableKey}
          preferenceName={preferenceType}
        />
      </>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.headerState, ...state.sessionState }), {
  ...HeaderStore.actionCreators,
  ...InternalMessagingState.actionCreators
})(UserList as any);
