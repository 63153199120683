import React, { useEffect, useState } from "react";
import { ComboBox, DropDownList, ListItemProps, MultiSelect, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import { Checkbox, Input, TextArea, NumericTextBox, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { DatePicker, DateTimePicker } from "@progress/kendo-react-dateinputs";
import { FieldRenderProps } from "@progress/kendo-react-form";
import SingleSelectDropdown, {
  ISingleSelectDropSelectedEvent,
  SingleSelectDropDownChangeEvent
} from "../../common/SingleSelectDropDown";
import {
  IFieldDataDropdown,
  IWizardValues,
  IWizardLookupValues,
  IWizardSingleSelectItem,
  WizardComponentTypes,
  IWizardDropDownListItem,
  IFieldDataApi

} from "../../../models/Wizard";
import FileUploadField from "./FileUploadField";
import ListBoxField from "./ListBoxField";
import ListViewField from "./ListViewField";
import TableViewField from "./TableViewField";
import EditGridField from "./EditGridField";
import HiddenLookupField from "./HiddenLookupField";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../../Localization/en.json';
import SecureFileUploadForm from "./SecureFileUploadForm";
import { RadioGroup } from "@progress/kendo-react-inputs/dist/es/main";
import { ITextInputChipProps, TextInputChipList, ValidationRules } from "../TextInputChipList";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, Slider as MuiSlider } from "@material-ui/core";
import CommonHelper from "../utilities/CommonHelper";
import { faSortNumericUp } from "@fortawesome/free-solid-svg-icons";
import SelectGridField from "./SelectGridField";
import WizardHelper from "../utilities/WizardHelper";
loadMessages(bgMessages, 'en-US');

export const DropDownField = (fieldRenderProps: FieldRenderProps) => {
  const fieldData = fieldRenderProps.fieldData as IFieldDataDropdown;
  const valInfo = fieldRenderProps.valInfo as IWizardValues;
  const selectOne = fieldData.hideSelectOne
    ? []
    : ([{ id: null, title: "(select one)" }] as Array<IWizardDropDownListItem>);

  let options = new Array<IWizardDropDownListItem>();

  if (!fieldData.DropDownOptions) {
    const getOptions = fieldData.getDropdownOptions && fieldData.getDropdownOptions(fieldRenderProps.fieldValues);
    if (getOptions) {
      options = selectOne.concat(getOptions);
      fieldData.DropDownOptions = getOptions;
    } else {
      options = selectOne;
    }
  } else {
    const regularOptions = fieldData.DropDownOptions.filter(o => {
      return !o.dependency || WizardHelper.isConditionValidWLookup(fieldRenderProps.fieldValues, o.dependency);
    });

    options = selectOne.concat(regularOptions);
  }

  let defaultValue;
  const defaultFieldData = options.find(o => o.id === fieldRenderProps.fieldData.value);
  const defaultSelection = options.find(o => o.selected === true);

  if (valInfo && valInfo.value != null) {
    defaultValue = options.find(o => o.id === valInfo.value);
  } else if (defaultSelection) {
    defaultValue = defaultSelection;
  } else if (defaultFieldData) {
    defaultValue = defaultFieldData;
  } else {
    defaultValue = options[0];
  }

  const isExist = Object.keys(fieldRenderProps.fieldValues).length == 0 ? false : fieldRenderProps.fieldValues[fieldData.fieldId];

  if ((defaultValue && defaultValue.id != null) && ((valInfo && valInfo.value == null) || !isExist)) {
    updateValForField(defaultValue.id, fieldRenderProps, defaultValue.title, defaultValue.lookupValues);
  }

  return (
    <DropDownList
      name={fieldData.name}
      data={options}
      defaultValue={defaultValue}
      key={`dropdownfield${fieldRenderProps.seedKey}`}
      textField="title" // taken from DropDownListItem
      dataItemKey="id" // taken from DropDownListItem
      defaultItem={defaultValue && defaultValue.id}
      value={defaultValue}
      onChange={e => {
        const dd = fieldData.DropDownOptions.find(item => item.id === e.target.value.id);
        updateValForField(
          e.target.value ? e.target.value.id : null,
          fieldRenderProps,
          e.target.value ? e.target.value.title : null,
          dd && dd.lookupValues ? dd.lookupValues : null
        );
      }
      }
    />
  );
};

export const MultiDropDownField = (fieldRenderProps: FieldRenderProps) => {
  const fieldData = fieldRenderProps.fieldData as IFieldDataDropdown;
  const valInfo = fieldRenderProps.valInfo as IWizardValues;

  if (!fieldData.DropDownOptions) {
    fieldData.DropDownOptions = fieldData.getDropdownOptions(fieldRenderProps.fieldValues);
  }

  const options = fieldData.DropDownOptions as Array<IWizardDropDownListItem>;

  let defaultValue = null as Array<IWizardDropDownListItem>;
  if (valInfo && valInfo.value != null) {
    defaultValue = fieldData.DropDownOptions.filter(item => (valInfo.value as Array<string>).find(v => v === item.id));
  }

  return (
    <LocalizationProvider language="en-US">
      <IntlProvider locale="en" >
        <MultiSelect
          name={fieldData.name}
          data={options}
          defaultValue={defaultValue}
          key={`dropdownfield${fieldRenderProps.seedKey}`}
          textField="title" // taken from DropDownListItem
          dataItemKey="id" // taken from DropDownListItem
          // defaultItem={defaultValue}
          placeholder={fieldData.hideSelectOne ? "" : "(select one or more)"}
          onChange={e => {
            updateValForField(
              e.target.value ? e.target.value.map(v => v.id) : null,
              fieldRenderProps,
              e.target.value ? e.target.value.map(v => v.title).join(";") : null
            );
          }
          }
        />
      </IntlProvider>
    </LocalizationProvider>
  );
};

export const ComboBoxField = (fieldRenderProps: FieldRenderProps) => {
  const fieldData = fieldRenderProps.fieldData as IFieldDataDropdown;
  const valInfo = fieldRenderProps.valInfo as IWizardValues;
  const options = fieldData.DropDownOptions as Array<IWizardDropDownListItem | string>;
  const defaultValue = valInfo && valInfo.value != null ? options.find(o => (typeof (o) === "string" ? o : o.id) === valInfo.value) || valInfo.value.toString() : null;

  if (valInfo && valInfo.value == null && defaultValue != null) {

    if (typeof (defaultValue) === "string") {
      updateValForField(defaultValue, fieldRenderProps)
    }
    else if (defaultValue.id != null) {
      updateValForField(defaultValue.id, fieldRenderProps, defaultValue.title, defaultValue.lookupValues);
    }

  }

  return (
    <ComboBox
      data={options}
      defaultValue={defaultValue}
      allowCustom={true}
      onChange={e => {
        updateValForField(
          e.value ? e.value : null,
          fieldRenderProps,
          e.value ? e.value : null,
        );
      }}
    />
  );
};

export const updateStatusForField = (
  isBusy: boolean,
  fieldRenderProps: FieldRenderProps,
  isError: boolean,
  error?: string
) => {
  const errorMsg = isError ? error || fieldRenderProps.fieldData.lookupError || "Unhandled error" : null;
  const update = {
    error: errorMsg,
    fieldId: fieldRenderProps.fieldData.fieldId,
    isBusy
  };

  fieldRenderProps.fieldStatusUpdate(update);
};

export const updateValForField = (
  value: any,
  fieldRenderProps: FieldRenderProps,
  text?: string,
  lookupValues?: IWizardLookupValues
) => {
  if (!fieldRenderProps.fieldData) {
    console.log("garbage field change output from " + fieldRenderProps.name);
    return;
  }

  const change = {
    fieldId: fieldRenderProps.fieldData.fieldId,
    name: fieldRenderProps.fieldData.name,
    value,
    text: text || null,
    lookupValues,
    validation: fieldRenderProps.validation,
    resetForField: fieldRenderProps.fieldData.resetForField,
    postChange: fieldRenderProps.fieldData.postChange
  };

  fieldRenderProps.onChange(change);
  return change;
};

export const SingleSelectListField = (fieldRenderProps: FieldRenderProps) => {
  const valInfo = fieldRenderProps.valInfo as IWizardValues;
  const preSelectText = fieldRenderProps.fieldData.preSelectTextFunc && fieldRenderProps.fieldData.preSelectTextFunc(fieldRenderProps.fieldValues);

  return (
    <SingleSelectDropdown
      getItems={text =>
        fieldRenderProps.fieldData.GetData(fieldRenderProps.fieldData.GetDataParams(text, fieldRenderProps.fieldValues))
      }
      name={`singleselect${fieldRenderProps.name}`}
      key={`singleselect${fieldRenderProps.seedKey}`}
      preselectedValue={
        valInfo
          ? { id: valInfo.value as number, text: valInfo.text, data: { lookupValues: valInfo.lookupValues } }
          : null
      }
      preSelectText={preSelectText ? preSelectText : fieldRenderProps.fieldData.preSelectText}
      typeToListConverter={fieldRenderProps.fieldData.DataConverter}
      disableFiltering={fieldRenderProps.fieldData.disableFiltering}
      preSelectFromGetData={fieldRenderProps.fieldData.AutoSelectPreference}
      preloadGetData={fieldRenderProps.fieldData.preloadGetData}
      resetToPreSelectChangeField={fieldRenderProps.fieldData.resetToPreSelectChangeField}
      existingFieldValues={fieldRenderProps.fieldValues}
      placeholder={fieldRenderProps.fieldData.placeholder}
      onChange={(
        selected: ISingleSelectDropSelectedEvent<IWizardSingleSelectItem>,
        e: SingleSelectDropDownChangeEvent
      ) => {
        updateValForField(
          selected ? e.value.id : null,
          fieldRenderProps,
          selected ? e.value.text : null,
          (selected && selected.data) ? selected.data.lookupValues : null
        );
      }}
    />
  );
};

export const MultiSelectListField = (fieldRenderProps: FieldRenderProps) => {
  const [itemslist, setItemslist] = useState([]);
  const fieldData = fieldRenderProps.fieldData as IFieldDataApi;
  const valInfo = fieldRenderProps.valInfo as IWizardValues;
  let defaultValue = null as Array<IWizardDropDownListItem>;

  if (valInfo && valInfo.value != null && valInfo.text != null) {
    defaultValue = new Array<IWizardDropDownListItem>();
    const idsList = valInfo.value as Array<string>;
    const titleList = valInfo.text.split(";");
    idsList.map((item, index) => {
      defaultValue.push({ id: item, title: titleList[index] });
    });
  }

  const formattedlastlogindate = (userlastlogindate?: Date) => {
    return userlastlogindate
      ? CommonHelper.convertUTCDateToLocalDateString(userlastlogindate, "MMDDYYY", "/")
      : "-";
  };

  const usersItems = (dataItem: any) => {
    console.log(dataItem);
    const fullName = dataItem.fullName;
    const userName = dataItem.userName;
    const lastLoginDate = formattedlastlogindate(dataItem.lastLoginDate);

    return (
      <div key={dataItem.id} className="dd-item-details">
        <Grid container>
          <Grid item md={9} sm={9} className="user-name-wrapper">
            <div className="dd-item-name">
              <em title={fullName}>{fullName}</em>
            </div>
            <p className="email">
              {" "}
              <em title={userName}>{userName}</em>{" "}
            </p>
          </Grid>
          <Grid item md={3} sm={3} className="last-login-wrapper">
            <div className="last-login-container">
              <p className="last-login-label">Last Login</p>
              <p className="last-login">
                {" "}
                <em title={lastLoginDate}>{lastLoginDate}</em>{" "}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <div className="dd-item-name">
      <em title={itemProps.dataItem.id}>{itemProps.dataItem.title}</em>
    </div>
    return React.cloneElement(li, li.props, itemChildren);
  };

  const userItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = usersItems(itemProps.dataItem);
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <LocalizationProvider language="en-US">
      <IntlProvider locale="en" >
        <MultiSelect
          name={fieldData.name}
          data={itemslist}
          onOpen={async (e) => {
            const result = await fieldRenderProps.fieldData.GetData(fieldRenderProps.fieldData.GetDataParams(fieldRenderProps.fieldValues))

            if (result.ok) {
              setItemslist(fieldRenderProps.fieldData.DataConverter(result.data.results || result.data));
            }

          }}
          itemRender={fieldData.renderType == "users" ? userItemRender : itemRender}
          defaultValue={defaultValue}
          filterable={fieldData.filterable}
          onFilterChange={fieldData.filterable ? (async (event: MultiSelectFilterChangeEvent) => {
            const result = await fieldRenderProps.fieldData.GetData(fieldRenderProps.fieldData.GetDataParams(fieldRenderProps.fieldValues, event.filter.value.trim()))

            if (result.ok) {
              setItemslist(fieldRenderProps.fieldData.DataConverter(result.data.results || result.data));
            }

          }) : null}
          key={`dropdownfield${fieldRenderProps.seedKey}`}
          textField="title"
          dataItemKey="id"
          onChange={e => {
            updateValForField(
              e.target.value ? e.target.value.map(v => v.id) : null,
              fieldRenderProps,
              e.target.value ? e.target.value.map(v => v.title).join(";") : null,
              e.target.value ? { "userIds": e.target.value.map(v => v.userId) } : null
            );
          }
          }
        />
      </IntlProvider>
    </LocalizationProvider>
  );
};

export const CheckboxField = function (fieldRenderProps: FieldRenderProps) {
  const valInfo = fieldRenderProps.valInfo as IWizardValues;

  return (
    <Checkbox
      key={`checkboxfield${fieldRenderProps.seedKey}`}
      defaultChecked={valInfo && valInfo.value ? (valInfo.value as boolean) : false}
      onChange={e => updateValForField(e.value, fieldRenderProps)}
      label={fieldRenderProps.fieldData.label}
    />
  );
};

export const DatePickerField = function (fieldRenderProps: FieldRenderProps) {
  const valInfo = fieldRenderProps.valInfo as IWizardValues;

  return (
    <DatePicker
      min={fieldRenderProps.fieldData.min || new Date(1921, 1, 1)}
      max={fieldRenderProps.fieldData.max}
      key={`datepickerfield${fieldRenderProps.seedKey}`}
      name={`datepickerfield${fieldRenderProps.seedKey}${fieldRenderProps.name}`}
      defaultValue={valInfo && valInfo.value ? new Date(valInfo.value.toString()) : null}
      onChange={e =>
        updateValForField(
          e.target.value ? e.target.value.toISOString() : null,
          fieldRenderProps,
          e.target.value.toLocaleString()
        )
      }
    />
  );
};

export const DateTimePickerField = function (fieldRenderProps: FieldRenderProps) {
  const valInfo = fieldRenderProps.valInfo as IWizardValues;
  return (
    <DateTimePicker
      min={fieldRenderProps.fieldData.min || new Date(1921, 1, 1)}
      max={fieldRenderProps.fieldData.max}
      key={`datetimepickerfield${fieldRenderProps.seedKey}`}
      defaultValue={valInfo && valInfo.value ? new Date(valInfo.value.toString()) : null}
      onChange={e =>
        updateValForField(
          e.target.value ? e.target.value.toISOString() : null,
          fieldRenderProps,
          e.target.value.toLocaleString()
        )
      }
    />
  );
};

export const TextAreaField = function (fieldRenderProps: FieldRenderProps) {
  let valInfo: any = fieldRenderProps.valInfo as IWizardValues;

  if (fieldRenderProps.fieldData.defaultValueFunc && !valInfo) {
    const val = fieldRenderProps.fieldData.defaultValueFunc(fieldRenderProps.fieldValues);
    valInfo = updateValForField(val, fieldRenderProps);
  }

  const placeholder = fieldRenderProps.fieldData.placeholder && typeof fieldRenderProps.fieldData.placeholder === "function" ? fieldRenderProps.fieldData.placeholder(fieldRenderProps.fieldValues) :
    fieldRenderProps.fieldData.placeholder && fieldRenderProps.fieldData.placeholder;
  const handleOnValueChange = (e: TextAreaChangeEvent) => {
    updateValForField(e.value.toString().trim() ? e.value.toString().trim() : null, fieldRenderProps)
  };

  return (
    <div className="wizard-textarea-container">
      <TextArea
        key={`textareafield${fieldRenderProps.seedKey}`}
        defaultValue={valInfo ? (valInfo.value as string) : ""}
        onChange={handleOnValueChange}
        rows={fieldRenderProps.fieldData.textAreaRows || 4}
        autoSize={true}
        {...fieldRenderProps.fieldData}
        placeholder={placeholder}
      />
      {fieldRenderProps.fieldData.showCharacterCount && (
        <span><b>{`Characters Remaining: ${(valInfo && valInfo.value) ? (valInfo.value as string).length : 0}/${fieldRenderProps.fieldData.maxLength}`}</b></span>)}
    </div>
  );
};

export const InputField = function (fieldRenderProps: FieldRenderProps) {
  let valInfo = fieldRenderProps.valInfo as IWizardValues;

  if (!valInfo && fieldRenderProps.fieldData.value) {
    updateValForField(fieldRenderProps.fieldData.value, fieldRenderProps)
  }

  if (fieldRenderProps.fieldData.defaultValueFunc && (!valInfo || !(valInfo.value))) {
    const val = fieldRenderProps.fieldData.defaultValueFunc(fieldRenderProps.fieldValues);
    valInfo = updateValForField(val, fieldRenderProps);
  }

  const fieldDataValue = fieldRenderProps.fieldData.value ? fieldRenderProps.fieldData.value as string : "";

  return (
    <Input
      key={`inputfield${fieldRenderProps.seedKey}`}
      title={valInfo ? (valInfo.value as string) : fieldDataValue}
      placeholder={fieldRenderProps.fieldData.placeholder}
      minLength={fieldRenderProps.fieldData.min}
      maxLength={fieldRenderProps.fieldData.max}
      type={fieldRenderProps.fieldData.subType}
      value={valInfo ? (valInfo.value as string) : fieldDataValue}
      onChange={e => updateValForField(e.target.value, fieldRenderProps)}
    />
  );
};


export const InputCheckList = function (fieldRenderProps: FieldRenderProps) {
  const valInfo = fieldRenderProps.valInfo as IWizardValues;

  let externalWatchList = new Array<ITextInputChipProps>();
  if (valInfo && valInfo.value) {
    valInfo.value.toString().split(",").forEach(x => {
      const newChip: ITextInputChipProps = {
        text: x.trim(),
        value: x.trim(),
        type: "info",
        isValid: true
      };
      externalWatchList.push(newChip);
    });
  }

  const inputValueChange = (value: string) => {
    const lookupValues = { "inputText": value } as IWizardLookupValues;
    const fieldValue = fieldRenderProps.fieldValues && fieldRenderProps.fieldValues[fieldRenderProps.fieldData.fieldId];
    updateValForField(fieldValue && fieldValue.value, fieldRenderProps, fieldValue && fieldValue.text, lookupValues);
  };

  return (
    <TextInputChipList
      name={fieldRenderProps.fieldData.name}
      placeholder="example@epiqglobal.com"
      chipsRemovable={true}
      noInputText={fieldRenderProps.fieldData.noInputText}
      existingChips={externalWatchList}
      validations={[{ rule: ValidationRules.Email, errorMessage: "Please enter a valid email." }]}
      onValueChange={inputValueChange}
      onDataChange={(newState: Array<ITextInputChipProps>, name: string, isValid: boolean, inputText: string) => {
        let externalWatchList = new Array<string>();
        newState.forEach(chipProps => {
          return externalWatchList.push(chipProps.text);
        });
        const lookupValues = { "inputText": inputText } as IWizardLookupValues;
        updateValForField(externalWatchList.join(", "), fieldRenderProps, fieldRenderProps.fieldData.text, lookupValues);
      }}
      inputText={(valInfo && valInfo.lookupValues) ? valInfo.lookupValues["inputText"] as string : ""}
    />
  );
}

export const PasswordField = function (fieldRenderProps: FieldRenderProps) {
  const valInfo = fieldRenderProps.valInfo as IWizardValues;
  const [params, setParams] = useState({ type: fieldRenderProps.fieldData.subType, iconClass: "far fa-eye-slash" });

  const handleClick = () => {

    setParams(params.type == "text"
      ? { type: "password", iconClass: "far fa-eye-slash" }
      : { type: "text", iconClass: "far fa-eye" });
  }

  return (
    <div className="password-container">
      <Input
        key={`inputfield${fieldRenderProps.seedKey}`}
        minLength={fieldRenderProps.fieldData.min}
        maxLength={fieldRenderProps.fieldData.max}
        type={params.type}
        defaultValue={valInfo ? (valInfo.value as string) : ""}
        onChange={e => updateValForField(e.target.value, fieldRenderProps)}
      />
      <i className={params.iconClass} onClick={handleClick} ></i>
    </div>
  );
}

export const HiddenField = function (fieldRenderProps: FieldRenderProps) {
  const valInfo = fieldRenderProps.valInfo as IWizardValues;

  const defaultValue = valInfo && valInfo.value != null
    ? valInfo.value
    : (!valInfo && fieldRenderProps.fieldData.value ? fieldRenderProps.fieldData.value : "");

  if (!valInfo && fieldRenderProps.fieldData.value) {
    updateValForField(fieldRenderProps.fieldData.value, fieldRenderProps, fieldRenderProps.fieldData.altDisplayValue || fieldRenderProps.fieldData.text);
  } else if (valInfo && valInfo.value == null && defaultValue.value != "") {
    updateValForField(valInfo.value, fieldRenderProps, valInfo.text);
  }

  return (
    <div style={{ display: "none" }}>
      <Input
        key={`inputfieldhidden${fieldRenderProps.seedKey}`}
        defaultValue={defaultValue}
        hidden={true}
      /></div>
  );
}

export const NumericTextField = function (fieldRenderProps: FieldRenderProps) {
  const valInfo = fieldRenderProps.valInfo as IWizardValues;

  return (
    <NumericTextBox
      key={`inputfield${fieldRenderProps.seedKey}`}
      min={fieldRenderProps.fieldData.min}
      max={fieldRenderProps.fieldData.max}
      //type={fieldRenderProps.fieldData.subType}
      defaultValue={valInfo ? (valInfo.value as number) : null}
      onChange={e => updateValForField(e.target.value, fieldRenderProps)}
    />
  );
};

export const RadioGroupField = function (fieldRenderProps: FieldRenderProps) {
  const valInfo = fieldRenderProps.valInfo as IWizardValues;
  const data = (fieldRenderProps.fieldData.radioButtonOptions as Array<IWizardDropDownListItem>).filter(o => {
    return !o.dependency || WizardHelper.isConditionValidWLookup(fieldRenderProps.fieldValues, o.dependency);
  });

  const radioButtonProps = data.map(item => { return { "value": item.id, "label": item.title } });
  const defaultSelectedVal = data.find(item => item.selected === true);
  const defaultValue = ((valInfo && valInfo.value !== null) ? valInfo.value : (defaultSelectedVal ? defaultSelectedVal.id : null)) as number | string;

  if ((!valInfo || valInfo.value === null) && defaultSelectedVal) {
    updateValForField(defaultSelectedVal.id, fieldRenderProps, defaultSelectedVal.title);
  }

  return (
    <RadioGroup key={`radiobtn${fieldRenderProps.seedKey}`} defaultValue={defaultValue} value={defaultValue} data={radioButtonProps} layout={fieldRenderProps.fieldData.layout} className={fieldRenderProps.fieldData.cssClass}
      onChange={e => {
        const selectedOption = data.find(item => item.id == e.value);
        updateValForField(e.value, fieldRenderProps, selectedOption ? selectedOption.title : "");
      }
      } />
  );
};

export const DisplayOnlyField = function (fieldRenderProps: FieldRenderProps) {

  let valInfo = fieldRenderProps.valInfo as IWizardValues;

  if (fieldRenderProps.fieldData.defaultValueFunc && (!valInfo || valInfo.value == null)) {
    const val = fieldRenderProps.fieldData.defaultValueFunc(fieldRenderProps.fieldValues);
    if (val) {
      valInfo = updateValForField(val, fieldRenderProps);
    }
  }

  if (valInfo && valInfo.value) {
    return (
      <div className="read-only-val">
        <Tooltip
          key={"displayelementtooltip" + fieldRenderProps.fieldData.fieldId}
          position="top"
        >
          <span title={valInfo.value.toString()}>{valInfo.value}</span>
        </Tooltip>
      </div>);
  }

  return (<></>);
};

export const DownLoadExcelTemplate = function (fieldRenderProps: FieldRenderProps) {
  let exportExcel: any;

  const handleDownLoadClick = () => {
    save(exportExcel);
  };
  const save = (component: any) => {
    const exportOptions = {};
    component.save(exportOptions);
  };

  return (
    <div>
      <div className="download-label-description">{fieldRenderProps.fieldData.description}</div>
      <Button
        title="DownLoad"
        className="btn-download btn-secondary"
        icon={"download"}
        primary={false}
        onClick={handleDownLoadClick}
      >
        {fieldRenderProps.fieldData.addButtonLabel}
      </Button>
      <ExcelExport ref={(exporter: any) => (exportExcel = exporter)} fileName={fieldRenderProps.fieldData.exportExcelSettings().fileName}>
        {
          fieldRenderProps.fieldData.exportExcelSettings() && fieldRenderProps.fieldData.exportExcelSettings().fields.map((f: any) =>
            <ExcelExportColumn field={f.title} key={f.field} />
          )
        }
      </ExcelExport>
    </div>)
};

const MuiSliderField = function (fieldRenderProps: FieldRenderProps) {
  const data = fieldRenderProps.fieldData.radioButtonOptions as Array<IWizardDropDownListItem>;

  const valInfo = fieldRenderProps.valInfo as IWizardValues;
  const defaultSelectedVal = data.find(item => item.selected === true);
  const defaultValue = valInfo ? valInfo.value : (defaultSelectedVal ? defaultSelectedVal.id : null);

  if (!valInfo && defaultSelectedVal) {
    const description = (defaultSelectedVal.lookupValues && defaultSelectedVal.lookupValues["description"]) ? `: ${defaultSelectedVal.lookupValues["description"]}` : "";
    const text = defaultSelectedVal ? `${defaultSelectedVal.title}${description}` : "";
    updateValForField(defaultSelectedVal.id, fieldRenderProps, text);
  }

  const sliderData = data.map((item) => {
    return {
      value: item.id as number,
      label: <div className="wizard-slider-container-label"><label>{item.title}</label> <br />
        <span>{item.lookupValues && item.lookupValues["description"]}</span></div>
    }
  });

  return (
    <MuiSlider aria-valuenow={defaultValue as number}
      key="mui-wizard-slider"
      defaultValue={defaultValue as number}
      value={defaultValue as number}
      step={null}
      marks={sliderData}
      min={0}
      max={sliderData.length + 1}
      onChange={(e, value) => {
        const selectedData = data.find(x => x.id === value);
        const description = (selectedData.lookupValues && selectedData.lookupValues["description"]) ? `: ${selectedData.lookupValues["description"]}` : "";
        const text = selectedData ? `${selectedData.title}${description}` : "";
        updateValForField(value, fieldRenderProps, text);
      }}
      className="wizard-slider-container"
      track="inverted"
    />
  );
}

export const componentFromType = function (type: WizardComponentTypes) {
  switch (type) {
    case "dropdown":
      return DropDownField;
    case "singleselect":
      return SingleSelectListField;
    case "checkbox":
      return CheckboxField;
    case "date":
      return DatePickerField;
    case "datetime":
      return DateTimePickerField;
    case "textarea":
      return TextAreaField;
    case "fileupload":
      return FileUploadField;
    case "hiddenlookup":
      return HiddenLookupField;
    case "displayOnlyField":
      return DisplayOnlyField;
    case "numericTextField":
      return NumericTextField;
    case "multidropdown":
      return MultiDropDownField;
    case "multiselect":
      return MultiSelectListField;
    case "combobox":
      return ComboBoxField;
    case "listview":
      return ListViewField;
    case "listbox":
      return ListBoxField;
    case "securefileupload":
      return SecureFileUploadForm;
    case "hiddenField":
      return HiddenField;
    case "passwordField":
      return PasswordField;
    case "radiogroup":
      return RadioGroupField;
    case "inputChiplist":
      return InputCheckList;
    case "tableViewField":
      return TableViewField;
    case "editGridField":
      return EditGridField;
    case "downLoadExcelTemplate":
      return DownLoadExcelTemplate;
    case "sliderField":
      return MuiSliderField;
    case "selectGrid":
      return SelectGridField;
    default:
      return InputField;
  }
};
