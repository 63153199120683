import React, { useEffect, useRef, useState } from "react";
import ApprovalService from "../../../services/ApprovalService";
import NotificationService from "../../../services/NotificationService";
import ViewMore from "../../common/ViewMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TicketTypes } from "../../../models/ServicenowSupportRequestSettings";

interface IProps {
  projectId: number;
  requestTypeCode: string;
  permissions: Set<string>;
}

const ProjectApprovers = (props: IProps) => {
  const [approvers, setApprovers] = useState([]);
  var approverList = new Array<string>();

  useEffect(() => {
    if (!props.projectId) return;

    const getApproval = async function () {
      const result = await ApprovalService.getApproversFromProject(props.projectId, props.requestTypeCode);

      if (!result.ok || !result.data) {
        NotificationService.showErrorToast("Error getting approvers information from project");
        return;
      }
      else {
        result.data.forEach((approver: string) =>
          approverList.push(approver));
      }
      setApprovers(approverList);
    }
    getApproval();

  }, []);

  return (
    <>
      {((props.requestTypeCode === TicketTypes.ManageRelativityUsers && !props.permissions.has("EpiqAdminSubmitTechnicalTicket-RelativityUserRequestBypassApproval")) ||
        (props.requestTypeCode === TicketTypes.ManageEpiqAccessUsers && !props.permissions.has("EpiqAdminSubmitTechnicalTicket-EpiqAccessUserRequestBypassApproval"))) && approvers.length > 0 && <div className="wizard-summary-alert">When you submit this request, it is sent to an approval queue for review. Once approved, the support ticket will be available for you to track.
          <div className="wizard-summary-approvers">Approvers:</div>
          <div className="wizard-summary-approver-list">
            {approvers.length < 1 && <CircularProgress size={20} />}
            <ViewMore lineHeight={34}>{approvers.map(v => v.toString()).join(", ")}</ViewMore>
          </div>
        </div>}
    </>
  );
};

export default ProjectApprovers;