import NotificationService from "../../../services/NotificationService";
import JobsService, {
  IInitializeJobRequestData,
  ISubmitJobRequestData
} from "../../../services/JobService";
import { ServiceBase } from "../../../services/ServiceBase";
import { SendInvite } from "../../../models/UserModels";
import { JobTypeEnum } from "../../../models/Enums";

enum JobStates {
  ImportReadyForProcessing = 5,
  ImportProcessing = 6,
  ImportProcessedError = 7,
  ImportProcessedSuccess = 8,
  ImportProcessedPartialSuccess = 9,
  ImportCancelRequested = 10,
  ImportCancelling = 11,
  ImportCancelled = 12
}

export interface ISendInvitationEmailJobSubmitModel {
  sendInvites: SendInvite[];
  bulk: boolean;
  siteId?: number;
  overrideSendInvite?: boolean;
  jobSubmittedSuccessMessage?: string;
  jobSubmittedFailureMessage?: string;
}

interface ISendInvitationJobSubmitModel extends ISubmitJobRequestData, ISendInvitationEmailJobSubmitModel {
  readyForProcessing: boolean;
}

export default class JobManager {
  private jobData: ISendInvitationJobSubmitModel;
  private jobSubmittedSuccessMessage: string;
  private jobSubmittedFailureMessage: string;

  constructor(jobData: ISendInvitationEmailJobSubmitModel) {
    this.jobData = { ...jobData, ...{ readyForProcessing: false } } as ISendInvitationJobSubmitModel;
    this.jobData.jobType = JobTypeEnum.Invitation;
    this.jobSubmittedSuccessMessage =
      this.jobData.jobSubmittedSuccessMessage ||
      "Bulk invites process has been initiated. We will notify you once complete.";
    this.jobSubmittedFailureMessage =
      this.jobData.jobSubmittedFailureMessage ||
      "Failed to submit job for invitation process. Please re-initiate the invitation process.";
  }

  async submitBulkSendInviteJob() {
    await this.initializeJobForProcessing();
  }

  private async fetchJobStatus() {
    if (this.jobData.jobId) {
      const { ok, data } = await JobsService.getJobStatus(this.jobData.jobId);

      if (ok) {
        const jobData = data.data ? JSON.parse(data.data) : null;
        const bulkInvitationJobDataError = jobData && (jobData.Error as string);

        switch (data.statusId) {
          case JobStates.ImportReadyForProcessing:
          case JobStates.ImportProcessing:
            await ServiceBase.setTimeoutPromise(1000);
            await this.fetchJobStatus();
            break;
          case JobStates.ImportProcessedError:
            if (bulkInvitationJobDataError) {
              NotificationService.showErrorToast(bulkInvitationJobDataError);
            } else {
              NotificationService.clearToast();
            }
            break;
          case JobStates.ImportProcessedPartialSuccess:
            if (bulkInvitationJobDataError) {
              NotificationService.showErrorToast(bulkInvitationJobDataError);
            } else {
              NotificationService.showSuccessToast(
                "Mail sent successfully.<br/> One or more invites could not be sent.",
                true
              );
            }
            break;
          case JobStates.ImportProcessedSuccess:
            if (bulkInvitationJobDataError) {
              NotificationService.showErrorToast(bulkInvitationJobDataError);
            } else {
              NotificationService.showSuccessToast("Mail sent successfully.", true);
            }
            break;
        }
      } else {
        console.log("Failed to get job status. Please re-initiate the send invitation process.");
        NotificationService.showErrorToast("Failed to get job status. Please re-initiate the send invitation process.");
      }
    }
  }

  private async initializeJobForProcessing() {
    const initializeJobRequest: IInitializeJobRequestData = {
      jobType: this.jobData.jobType
    };

    const response = await JobsService.initializeJob(initializeJobRequest);

    if (response.ok) {
      this.jobData.jobId = response.data.jobId;

      await this.submitJobForProcessing();
    } else {
      console.log("Failed to initialize the invitation process job. Please re-initiate the  invitation process.");
      NotificationService.showErrorToast(
        "Failed to initialize the invitation process job. Please re-initiate the invitation process."
      );
    }
  }

  private async submitJobForProcessing() {
    this.jobData.readyForProcessing = true;

    const submitJobResponse = await JobsService.submitJob(this.jobData);

    if (submitJobResponse.ok) {
      NotificationService.showInfoToast(this.jobSubmittedSuccessMessage, true);

      await this.fetchJobStatus();
    } else {
      NotificationService.showErrorToast(this.jobSubmittedFailureMessage);
    }
  }
}
