import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "./store";
import * as AuthenticationStore from "./store/Authentication";
import * as SessionStore from "./store/Session";
import * as ReportStore from "./store/Report";
import * as JobStore from "./store/Jobs";
import { Routing } from "./Routing";
import { RouteType, Routes, routes, authenticatedRoutes } from "./models/Routing";
import { ISessionManager } from "./SessionManager";
import JobManager from "./JobManager";
import ReportManager from "./ReportManager";
import { AppInsights, AppInsightsHelper } from "./AppInsights";
import AuthenticationService from "./services/AuthenticationService";
import CircularProgress from "@material-ui/core/CircularProgress";
import authenticationStorageApi from "./apis/storage/AuthenticationStorageApi";
import "toastr/toastr.scss";
import "./App.scss";
import "./custom.scss";
import SessionStorageApi from "./apis/storage/SessionStorageApi";
import DialogBox from "./components/common/DialogBox";
import { v4 as uuid } from "uuid";
import { IStyleOverrides, SiteBrandingSettings } from "./models/SiteBrandingModel";
import cookieStorageApi from "./apis/storage/CookieStorageApi";
import SiteBrandingService from "./services/SiteBrandingService";


declare global {
  interface Window {
    ApplicationVariables: any;
  }
}

interface IProps {
  sessionManager: ISessionManager;
  jobManager: JobManager;
  reportManager: ReportManager;
}

type Props = IProps &
  AuthenticationStore.IAuthenticationState &
  typeof AuthenticationStore.actionCreators &
  SessionStore.ISessionState &
  typeof SessionStore.actionCreators

type State = {
  checkingAuthentication: boolean;
  checkingDomain: boolean;
  originalBaseUrl: null;
  routeType: RouteType;
};

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      checkingAuthentication: true,
      checkingDomain: true,
      originalBaseUrl: null,
      routeType: RouteType.NotAuthenticated
    };
  }


  async setupWhiteLabelVars() {

    let lookup = {};

    // TODO I need to skip sometimes?
    //if (window.location.origin === window.ApplicationVariables.baseUrl) {
    //  return window.ApplicationVariables.baseUrl;
    //}

    try {
      lookup = await fetch(window.ApplicationVariables.cdnSettingsUrl + "?" + uuid()).then(r => r.json()).catch(() => { });
      lookup = lookup || {};
    }
    catch (e) {
      console.log("Could not lookup whitelabel info", e);
    }

    const siteInfo: IStyleOverrides = (lookup as Record<string, IStyleOverrides>)[window.location.origin.toString()];
    var favIcon = document.getElementById("favicon") as HTMLLinkElement;
    const originalUrl = window.ApplicationVariables.baseUrl;


    if (!siteInfo) {
      console.log("there is not site info for " + window.location.origin);
      favIcon.href = `${process.env.PUBLIC_URL}/favicon.ico`;
      document.title = "Epiq Access";

      this.setState({ ...this.state, checkingDomain: false });
      return originalUrl;
    }

    window.ApplicationVariables.baseUrl = window.location.origin;
    window.ApplicationVariables.initiateLoginUri = window.ApplicationVariables.initiateLoginUri.replace(originalUrl, window.location.origin) + "?site=" + encodeURI(window.location.host);
    window.ApplicationVariables.apiUri = window.ApplicationVariables.apiUri
      .replace(":5001/", ":5000/")
      .replace(originalUrl, window.location.origin)
      .replace(":5000/", ":5001/"); // Only one place we have different ports

    if (!siteInfo || !siteInfo.CSSUrl) {
      console.log("No style information for " + window.location.origin);
      this.setState({ ...this.state, checkingDomain: false });

      return originalUrl;
    }

    SessionStorageApi.setBranding(siteInfo);

    if (siteInfo.Data) {
      if (favIcon && siteInfo.Data.FaviconUrl) {
        favIcon.href = siteInfo.Data.FaviconUrl;
      }

      if (siteInfo.Data.BrowserTitle) {
        document.title = siteInfo.Data.BrowserTitle;
      }
    }

    if (siteInfo.CSSUrl) {
      var cssId = 'loginBrandingOverrides';
      if (!document.getElementById(cssId)) {
        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.id = cssId;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = siteInfo.CSSUrl;
        link.onload = (e) => {
          setTimeout(() => {
            this.setState({ ...this.state, checkingDomain: false });
          }, 1);
        };
        link.onerror = () => {
          setTimeout(() => {
            this.setState({ ...this.state, checkingDomain: false });
          }, 1);
        };
        head.appendChild(link);
      }
    }

    this.setState({ ...this.state, originalBaseUrl: originalUrl });
  }

  initiateSso() {
    authenticationStorageApi.clearAll();
    SessionStorageApi.setImpersonatedUser(null);
    window.location.href = "/initiate-login" + (this.state.originalBaseUrl === window.location.origin ? "" : "?site=" + encodeURI(window.location.host));
  }

  async authenticate() {
    console.log(`Valid existing session exists. Will fetch session data and authenticate user.`);

    const sessionData = await this.props.sessionManager.retrieveAndStoreSessionData(authenticationStorageApi.getUserId())
    
    if (sessionData) {
      this.props.setAuthentication(true);
      this.setAppInsightsAuthUserContext();

      this.setState({ ...this.state, checkingAuthentication: false, routeType: sessionData.destination.routeType });
    } else {
      console.log("Token is likely stale or invalid.  Need to re-initiate login");
      this.initiateSso();
    }
  }

  checkSsoCompleted() {
    var now = new Date();
    var exp = cookieStorageApi.getSsoCompletedExp();

    return ((exp !== null) && (now < exp));
  }

  async componentDidMount() {

    const setupWhiteLabelTask = this.setupWhiteLabelVars();
    await setupWhiteLabelTask;

    if (window.location.pathname.toLowerCase() === "/error") {
      SessionStorageApi.setImpersonatedUser(null);
      this.setState({ ...this.state, checkingAuthentication: false, checkingDomain: false });
      return;
    }

    console.log(`Checking for existing EA session.`);

    //////////////
    // 1. Check if we already have a valid Epiq Access access token in localStorage
    var verifySessionResponse = await AuthenticationService.checkEpiqAccessSession();

    if(verifySessionResponse && verifySessionResponse.exists) {
      console.log(`EA Session exists. Authenticating.`);
      await this.authenticate();
      return;
    }

    //////////////
    // 2. Check if we have a valid Epiq Access access token stored in the cookie (at the
    // end of the SSO process). This call will validate whether the token is in fact a 
    // valid session.
    console.log(`No EA Session found. Checking for access token cookie after SSO.`);
    
    var accessTokenData = await AuthenticationService.checkAccessTokenCookie();
    
    if(accessTokenData !== null && accessTokenData.accessToken !== null && accessTokenData.idpUserId !== null) {
      console.log(`Access token found and validated. Authenticating.`);

      authenticationStorageApi.storeAuthenticationData(accessTokenData.idpUserId, accessTokenData.accessToken);

      await this.authenticate();
      
      return;
    }

    //////////////
    // 3. Check if we already have an existing Okta session. This call will probably fail for
    // domains not in *.epiqglobal.com due to third-party cookies being blocked, so it's just
    // to take advantage of if the call does succeed (can automatically trigger SSO if they're
    // already signed into Okta).
    console.log(`No access token cookie useable. Checking for exisiting Okta session if possible.`);

    var oktaSessionExists = await AuthenticationService.checkOktaSession();

    if(oktaSessionExists) {
      console.log(`Okta session exists.`);

      this.initiateSso();

      return;
    }

    this.setState({ ...this.state, checkingAuthentication: false, checkingDomain: false });
    return;
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (!prevProps.changingSession && this.props.changingSession) {
      this.props.reportManager.clearReports();
      this.props.jobManager.clearAllJobs();
    }
   }

  setAppInsightsAuthUserContext = async () => {
      await AppInsightsHelper.addTelemetryInitializer(false);
        AppInsightsHelper.trackCustomEvent("Login to Epiq Access");
        sessionStorage.setItem("isUserLoggedInEventCaptured", "true");
  };

  toggleDialogEvent = async(event: React.MouseEvent<HTMLElement>) => {
    this.props.sessionManager.endImpersonation();
  }

  render() {

    const routeType = (this.props.authenticated && this.state.routeType === RouteType.BasicAuthenticated) ? RouteType.BasicAuthenticated : this.props.authenticated ? RouteType.Authenticated : RouteType.NotAuthenticated;

    const content = this.state.checkingAuthentication || this.props.changingSession ? <></> : <div className={this.state.checkingDomain ? "hide-authed-app" : ""} ><div className="warning">
      <DialogBox
        title={"Locked During View As"}
        content={"The View As feature that allows you to impersonate another user is running in another Access tab. This tab will remain locked until you Exit View As in that tab."}
        okButtonLabelText={""}
        cancelButtonLabelText={"EXIT VIEW AS MODE"}
        visible={this.props.sessionDeSync == true ? true : false}
        toggleDialogEvent={this.toggleDialogEvent}
      /></div>
      <Routing
        type={routeType}
        sessionData={this.props.sessionData}
        sessionManager={this.props.sessionManager}
      /></div>;

    return (
      <>
        {(this.state.checkingAuthentication || this.props.changingSession || this.state.checkingDomain) && <div className="spin-container">
          <CircularProgress />
        </div>}
        {content}
      </>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.authenticationState, ...state.sessionState }), {
  ...AuthenticationStore.actionCreators,
  ...SessionStore.actionCreators,
})(App);
