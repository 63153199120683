import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../store";
import * as SessionStore from "../../../../store/Session";
import { PanelBar, PanelBarItem, PanelBarItemProps, PanelBarSelectEventArguments } from "@progress/kendo-react-layout";
import { adminRoutes, AdminRoutes, Routes } from "../../../../models/Routing";
import CloseIcon from "@material-ui/icons/Close";
import { ISubFeature } from "../../../../SessionManager";
import "./Panel.scss";
import { AppInsightsHelper } from "../../../../AppInsights";

interface IAdminPanelBarItemProps extends PanelBarItemProps {
  index?: string;
  subRoutes?: Array<AdminRoutes>;
  route: AdminRoutes;
}

interface IProps {
  history: any;
  onCloseClick: () => void;
}

type Props = IProps & SessionStore.ISessionState;

export const AdminPanel = (props: Props) => {
  const navItemsDictionary: Record<string, IAdminPanelBarItemProps> = {
    [AdminRoutes.UserActivityList]: {
      title: "Activity",
      route: AdminRoutes.UserActivityList
    },
    [AdminRoutes.AdminApplications]: {
      title: "Applications",
      route: AdminRoutes.AdminApplications
    },
    [AdminRoutes.Assignments]: {
      title: "Assignments",
      route: AdminRoutes.Assignments
    },
    [AdminRoutes.Domains]: {
      title: "Domains",
      route: AdminRoutes.Domains
    },
    [AdminRoutes.Employers]: {
      title: "Employers",
      route: AdminRoutes.Employers,
      subRoutes: [AdminRoutes.EmployerDetails]
    },
    [AdminRoutes.JobQueue]: {
      title: "Job Queue",
      route: AdminRoutes.JobQueue
    },
    [AdminRoutes.ContextUserList]: {
      title: "Manage Users",
      route: AdminRoutes.ContextUserList,
      subRoutes: [AdminRoutes.UserDetails]
    },
    [AdminRoutes.Messages]: {
      title: "Messages",
      route: AdminRoutes.Messages,
      subRoutes: [AdminRoutes.MessageDetails]
    },
    [AdminRoutes.SsoGroups]: {
      title: "Okta Groups",
      route: AdminRoutes.SsoGroups
    },
    [AdminRoutes.ProjectList]: {
      title: "Projects",
      route: AdminRoutes.ProjectList,
      subRoutes: [AdminRoutes.ProjectDetails]
    },
    [AdminRoutes.Report]: {
      title: "Reports",
      route: AdminRoutes.Report
    },
    [AdminRoutes.ResourceGroupsList]: {
      title: "Resource Groups",
      route: AdminRoutes.ResourceGroupsList,
      subRoutes: [AdminRoutes.ResourceGroupDetails]
    },
    [AdminRoutes.ResourceList]: {
      title: "Resources",
      route: AdminRoutes.ResourceList,
      subRoutes: [AdminRoutes.ResourceList]
    },
    [AdminRoutes.RolesList]: {
      title: "Roles",
      route: AdminRoutes.RolesList,
      subRoutes: [AdminRoutes.RoleDetails]
    },
    [AdminRoutes.SecureUploadLocationsList]: {
      title: "Secure Upload",
      route: AdminRoutes.SecureUploadLocationsList,
      subRoutes: [AdminRoutes.SecureUploadDetails]
    },
    [AdminRoutes.AdminContext]: {
      title: "Set Up Rel Admin Group",
      route: AdminRoutes.AdminContext
    },
    [AdminRoutes.SiteBranding]: {
      title: "Site Branding",
      route: AdminRoutes.SiteBranding
    },
    [AdminRoutes.UserGroups]: {
      title: "User Groups",
      route: AdminRoutes.UserGroups,
      subRoutes: [AdminRoutes.CreateUserGroup, AdminRoutes.UserGroupDetails]
    },
    [AdminRoutes.UserList]: {
      title: "Users",
      route: AdminRoutes.UserList,
      subRoutes: [AdminRoutes.UserDetails]
    }
  };
  const [selectedId, setSelectedId] = useState(null);
  const [navItems, setNavItemsState] = useState({} as Record<string, IAdminPanelBarItemProps>);
  const handleOnSelect = (event: PanelBarSelectEventArguments) => {
    setSelectedId(event.target.props.uniquePrivateKey);
    props.history.push(adminRoutes[event.target.props.route].path);
  };
  const setNavItems = () => {
    const navItems: Record<string, IAdminPanelBarItemProps> = {};
    const { subFeatures } = props.sessionData.featurePermissions[Routes.Administration] as ISubFeature;
    let iterator = 0;

    Object.keys(navItemsDictionary).forEach(key => {
      if (subFeatures[key].authorized) {
        navItems[key] = navItemsDictionary[key];
        navItems[key].index = `.${iterator}`;
        iterator++;
      }
    });

    setNavItemsState(navItems);
  };
  const setSelectedNavItem = () => {
    const currentLocationPath = props.history.location.pathname;
    const selectedIndex = Object.keys(navItems).find(key => {
      const route = adminRoutes[navItems[key].route];
      const routePathMatch = currentLocationPath.startsWith(route.path);
      const subRoutePathMatch =
        navItems[key].subRoutes &&
        navItems[key].subRoutes.some(subRoute =>
          currentLocationPath.startsWith(adminRoutes[subRoute].lookupSegment || adminRoutes[subRoute].path)
        );

      return routePathMatch || subRoutePathMatch;
    });

    if (navItems[selectedIndex]) {
      AppInsightsHelper.addTelemetryInitializer();
      setSelectedId(navItems[selectedIndex].index);
    }
  };

  useEffect(() => {
    setNavItems();
  }, []);

  useEffect(() => {
    setSelectedNavItem();
  }, [props.history.location.pathname, navItems]);

  return (
    <div className="admin-panel-bar">
      <PanelBar onSelect={handleOnSelect} selected={selectedId}>
        {Object.keys(navItems).map(key => (
          <PanelBarItem {...navItems[key]} />
        ))}
      </PanelBar>
      <div className="close-icon-button" aria-label="close" onClick={props.onCloseClick}>
        <CloseIcon className="close-icon" />
      </div>
    </div>
  );
};

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(AdminPanel);
