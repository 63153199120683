import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../store";
import * as SessionStore from "../../../../store/Session";
import SupportService, { ITicketDetails, ITicketPersona } from "../../../../services/SupportService";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import Paper from "@material-ui/core/Paper";
import { ServiceNowDataSource } from "../../common/RequestGrid";
import Details from "./Details";
import Attachments from "./Attachments";
import ActivityLog from "./ActivityLog";
import { DataLoader } from "../../../common/DataLoader";
import ResourceService from "../../../../services/ResourceService";
import "./Request.scss";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import CollapsibleSection from "../../../administration/common/CollapsibleSection";
import AddAttachmentsSlide from "../create/AddAttachmentsSlide";
import { Checkbox } from "@progress/kendo-react-inputs";
import BreadCrumb, { IBreadCrumbPart } from "../../../common/BreadCrumb";

interface IProps {
  match?: any;
  history: any;
}

type Props = IProps & SessionStore.ISessionState;

type State = {
  isLoading: boolean;
  ticketDetails: ITicketDetails;
  hasError: boolean;
  openAddAttachmentsSlide: boolean;
  breadcrumbParts?: Array<IBreadCrumbPart>;
  watchListDataSource: boolean;
};

class Request extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const emptyPersona: ITicketPersona = {
      email: "",
      firstName: "",
      lastName: ""
    };

    let sourceTitle = "Tickets";

    if (this.props.history.location.state && (this.props.history.location.state.source === ServiceNowDataSource.PROJECTREQS ||
      this.props.history.location.state.source === ServiceNowDataSource.WATCHING)) {
      sourceTitle = "Followed Tickets";
    } else if (this.props.history.location.state && this.props.history.location.state.source === ServiceNowDataSource.MYREQUESTS) {
      sourceTitle = "My Tickets";
    }

    this.state = {
      isLoading: true,
      hasError: false,
      openAddAttachmentsSlide: false,
      ticketDetails: {
        callBackNumber: "",
        description: "",
        externalEmail: "",
        externalWatchList: "",
        incidentId: "",
        project: "",
        projectId: -1,
        shortDescription: "",
        status: "",
        submitted: "",
        supportTicketType: "",
        contact: emptyPersona,
        requestedBy: emptyPersona,
        externalWatchListAsArray: new Array<string>(),
        sysId: "",
        serviceNowDataSource: "",
        sapBillToClient: ""
      },
      breadcrumbParts: [{ title: sourceTitle }, { title: this.props.match.params.id }],
      watchListDataSource: false
    };
  }

  async dataFetch() {
    let dataSource = null;

    if (this.props.history.location.state && this.props.history.location.state.source === ServiceNowDataSource.WATCHING) {
      dataSource = ServiceNowDataSource.WATCHING;
    }
    else if (this.props.history.location.state && this.props.history.location.state.source === ServiceNowDataSource.MYREQUESTS) {
      dataSource = ServiceNowDataSource.MYREQUESTS;
    } else {
      dataSource = ServiceNowDataSource.UNKNOWN;
    }

    const result = await SupportService.getTicketById(this.props.match.params.id, dataSource);
    const breadcrumbParts = [... this.state.breadcrumbParts];
    let newTicketDetailsState = this.state.ticketDetails;

    if (result.ok) {
      newTicketDetailsState = result.data.results[0];
      dataSource = newTicketDetailsState.serviceNowDataSource;

      if (newTicketDetailsState.project) {
        const pNumber = newTicketDetailsState.project
          .substring(newTicketDetailsState.project.indexOf(" -"))
          .replace("-", "")
          .trim();

        const filter = [{
          logic: "or",
          filters: [{ field: "DisplayName", operator: "contains", value: pNumber }
          ] as Array<FilterDescriptor>
        }] as Array<CompositeFilterDescriptor>;

        const projectDetailsResult = await ResourceService.getResourcesUser({
          permissionsDelimited: "SubmitTechnicalTicket,SubmitWorkRequest-TrackMedia,SubmitWorkRequest-SecureFileUpload,SubmitWorkRequest-ProcessData,SubmitWorkRequest-AnalyzeOrReview,SubmitWorkRequest-ExportData,SubmitWorkRequest-ProjectClosure",
          filters: filter,
          skip: 0,
          take: 100,
          typeCode: "ediscoveryproject",
          showGroupsWithDupePermissions: true,
          flattenResults: true
        });

        if (projectDetailsResult.ok) {
          const projectId =
            projectDetailsResult.data.results.length > 0 ? projectDetailsResult.data.results[0].objectId : 0;

          newTicketDetailsState = { ...newTicketDetailsState, projectId: projectId };
        }
      }

      breadcrumbParts[1].element = <span className="request-breadcrumb-status">{newTicketDetailsState.status}</span>
    } else {
      newTicketDetailsState.status = "Support ticket doesn't exist or you do not have access.";
    }

    this.setState({ isLoading: false, hasError: !result.ok, ticketDetails: newTicketDetailsState, breadcrumbParts, watchListDataSource: dataSource === ServiceNowDataSource.WATCHING });
  }

  handleCloseClick() {
    const path = "/support/servicerequestlist";
    const url = this.props.history.location.state ? {
      pathname: path, state: { source: this.props.history.location.state.source, dataState: this.props.history.location.state.dataState, selectedStatus: this.props.history.location.state.selectedStatus }
    } : { pathname: path }

    this.props.history.push(url);
  }

  render() {

    return (
      <>
        <div className="div-header">
          <div className="tech-header">Technical Support Ticket</div>
          <div className="tech-subHeader">Track and add notes to the ticket.</div>
        </div>
        <Paper>
          <div className="request-details-wrapper">
            <div className="details-header-container">
              <BreadCrumb path={this.state.breadcrumbParts} />
              <span className="k-icon k-i-x k-icon-32" onClick={this.handleCloseClick.bind(this)}></span>
            </div>
            
            {!this.state.hasError && !this.state.isLoading && (<div className="request-details-container">
              <div>
                <Details ticketDetails={this.state.ticketDetails}
                  watchListDataSource={this.state.watchListDataSource}
                  disableAddFollowers={this.state.ticketDetails.status.toLowerCase() === "complete"}                />
                <Attachments
                  incidentId={this.state.ticketDetails.incidentId}
                  watchListDataSource={this.state.watchListDataSource}
                  addingAttachment={this.state.openAddAttachmentsSlide}
                  permissions={this.props.sessionData.permissions}
                  onAddAttachment={() => this.setState({ openAddAttachmentsSlide: true })}
                />
              </div>
              <div>
                <ActivityLog
                  incidentId={this.state.ticketDetails.incidentId}
                  requestTypeCode={this.state.ticketDetails.supportTicketType}
                  projectId={this.state.ticketDetails.projectId}
                  sysId={this.state.ticketDetails.sysId}
                  disableAdd={this.state.ticketDetails.status.toLowerCase() === "complete"}
                  watchListDataSource={this.state.watchListDataSource}
                />
              </div>
            </div>)}
          </div>
        </Paper>
        <DataLoader
          loading={this.state.isLoading}
          dataState={{}}
          getDataAsync={this.dataFetch.bind(this)}
          dataContainerClassesForScope=".request-details-wrapper"
        />
        <AddAttachmentsSlide ticketDetails={this.state.ticketDetails}
          openSlide={this.state.openAddAttachmentsSlide}
          onClose={() => this.setState({ openAddAttachmentsSlide: false })}
          watchListDataSource={this.state.watchListDataSource} />
      </>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.sessionState }), null)(Request);
