import React, { useEffect, useState } from "react";
import NotificationMessageService from "../../services/NotificationMessageService";
import "@progress/kendo-theme-material/dist/all.css";
import NotificationMessageDialog from "./NotificationMessageDialog";
import { INotificationMessageModel, MessageTypeIdsEnum } from "../../models/MessageModel";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { ISessionManager, SessionManager } from "../../SessionManager";
import * as SessionStore from "../../store/Session";
import { IApplicationState } from "../../store";
import { connect } from "react-redux";
import { IPreference, IUserNotificationPreferences, preferenceTypes } from "../../models/Preferences";
import sessionStorageApi from "../../apis/storage/SessionStorageApi";
import * as NotificationStore from "../../store/NotificationMessages"
import { NotificationPopupButtonLabelEnum } from "../../models/MessageModel";

interface IProps {
  sessionManager: ISessionManager;
}

type Props = IProps &
  SessionStore.ISessionState &
  NotificationStore.INotificationMessageState &
  typeof NotificationStore.actionCreators &
{ children?: React.ReactNode };

const NotificationMessage = (props: Props) => {
  const preferenceType = "UserNotificationPreferences";
  const userNotificationPref = props.sessionData.userPrefs.UserNotificationPreferences as IUserNotificationPreferences;

  const [isLoading, setIsLoading] = useState(true);
  const [initializing, setInitializing] = useState(true);
  const [notifications, setNotifications] = useState(null as INotificationMessageModel[]);
  const [activeNotifications, setActiveNotifications] = useState(null as INotificationMessageModel[]);
  const [dontMeShowAgain, setDontMeShowAgain] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  let UserNotificationDBPref = {
    dontShowMeAgainNotificationIds: (userNotificationPref && userNotificationPref.dontShowMeAgainNotificationIds) ? userNotificationPref.dontShowMeAgainNotificationIds : [],
  } as IUserNotificationPreferences;

  useEffect(() => {
    if (initializing) {
      loadNotifications()
        .catch(console.error);

      setInitializing(false);
    }
  });

  const unique = (values: Array<number>): number[] => {
    return values.filter((item, i, ar) => ar.indexOf(item) === i);
  };

  const savePreference = async (preferenceName: preferenceTypes, preferenceValue: IUserNotificationPreferences) => {
    const sessionManager = new SessionManager();
    const preference: IPreference = { prefName: preferenceName, value: preferenceValue };

    sessionManager.setPreference(preference)
  }

  const loadNotifications = async () => {
    setIsLoading(true);

    const response = await NotificationMessageService.getResouceNotificationMessages();

    if (!response.ok) {
      setShowError(true);
      return;
    }

    const notifications = response.data as INotificationMessageModel[];
    const allNotifications = includeIssueResolvedNotification(notifications)

    const activeNotifications = getActiveNotifications(notifications)
    const unReadNotifyCount = notifications.filter(x => (UserNotificationDBPref.dontShowMeAgainNotificationIds.indexOf(x.notificationId) === -1)).length;

    if (activeNotifications.length == 0 && notifications.length > 0) {
      props.setNotificationData(allNotifications, unReadNotifyCount);
    }

    setShowPopup(activeNotifications.length > 0);
    setActiveNotifications(activeNotifications);
    setNotifications(allNotifications);

    setInitializing(false);
    setIsLoading(false);
  }

  const handleCloseDialogEvent = async (event: React.MouseEvent<HTMLElement>) => {
    const currentDontShowMeAgainIds = notifications.map(n => n.notificationId);
    const unReadNotifyCount = notifications.filter(x => (UserNotificationDBPref.dontShowMeAgainNotificationIds.indexOf(x.notificationId) === -1)).length;

    if (dontMeShowAgain) {
      const notifyIds = currentDontShowMeAgainIds.concat(UserNotificationDBPref.dontShowMeAgainNotificationIds);
      savePreference(preferenceType, { dontShowMeAgainNotificationIds: unique(notifyIds) });
    }
    else if (!props.sessionData.impersonateMode) {
      sessionStorageApi.setClosedNotifications(unique(currentDontShowMeAgainIds));
    }

    props.setNotificationData(notifications, unReadNotifyCount, event.currentTarget.textContent === NotificationPopupButtonLabelEnum.ViewDetails);
    setShowPopup(false);
  }

  const dontShowMeAgainChangeEvent = async (event: CheckboxChangeEvent) => {
    setDontMeShowAgain(event.value);
  }

  const getActiveNotifications = (notifications: Array<INotificationMessageModel>) => {
    let activeNotifications = notifications.filter(x => x.messageTypeId === MessageTypeIdsEnum.UPCOMING_MAINTENANCE);
    const closedNotificationsNotifications = sessionStorageApi.getClosedNotifications();

    if (activeNotifications && activeNotifications.length > 0) {
      if ((UserNotificationDBPref && UserNotificationDBPref.dontShowMeAgainNotificationIds.length > 0 || (!props.sessionData.impersonateMode && closedNotificationsNotifications.length > 0))) {
        activeNotifications = activeNotifications.filter(x => UserNotificationDBPref.dontShowMeAgainNotificationIds.indexOf(x.notificationId) === -1 && closedNotificationsNotifications.indexOf(x.notificationId) === -1)
      }
    }
    return activeNotifications;
  }

  const includeIssueResolvedNotification = (notifications: INotificationMessageModel[]) => {
    let appendUrgentNotifications = [] as INotificationMessageModel[];
    notifications.map((item: INotificationMessageModel) => {
      if (item.issueResolved && item.issueResolvedTitle && item.issueResolvedMessage && item.messageTypeId === MessageTypeIdsEnum.URGENT_MAINTENANCE) {
        const resolvedNotifications = {
          title: item.issueResolvedTitle,
          lastmodifiedDate: item.lastmodifiedDate,
          createdDate: item.createdDate,
          message: item.issueResolvedMessage,
          messageTypeId: item.messageTypeId,
          postedResolvedMessage: true
        } as INotificationMessageModel;
        appendUrgentNotifications.push(resolvedNotifications);
      }
      else {
        appendUrgentNotifications.push(item);
      }
    })

    return appendUrgentNotifications;
  }

  const content = (
    <div className="notification-main-content">
      {
        (activeNotifications && activeNotifications.length > 0 && !showError && (
          <div>
            <NotificationMessageDialog
              diagCloseEvent={handleCloseDialogEvent}
              notifications={activeNotifications}
              closeButtonLabelText={activeNotifications.length === 1 ? NotificationPopupButtonLabelEnum.Close : NotificationPopupButtonLabelEnum.ViewDetails}
              visible={showPopup}
              isLoading={isLoading}
              dontShowMeAgainChangeEvent={dontShowMeAgainChangeEvent}
              impersonateMode={props.sessionData.impersonateMode}
            />
          </div>
        ))
      }
    </div >
  )

  return (
    <>
      {content}
    </>
  )
};

export default connect((state: IApplicationState) => ({ ...state.sessionState, ...state.notificationMessageState }), {
  ...SessionStore.actionCreators,
  ...NotificationStore.actionCreators
})(NotificationMessage);