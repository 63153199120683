import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import {
  IFieldValueLookup,
  IWizardSection,
  IWizardRequest,
  IWizardSummary,
  IWizardWarningMessage,
  WizardFieldTypes,
  IWizardDropDownListItem
} from "../../../../models/Wizard";
import SupportRequestPopup from "../../../servicerequest/pulse/SupportRequestPopup";
import AdminService from "../../../../services/AdminService";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { IGridParams } from "../../../common/grid/AdvancedGrid";
import JobsService, { IGetJobStatusResponseData } from "../../../../services/JobService";
import NotificationService from "../../../../services/NotificationService";
import { ServiceBase } from "../../../../services/ServiceBase";
import "./ClientSetupWizard.scss";
import { JobMessagingConfig } from "../../../../JobManager";
import ResourceService from "../../../../services/ResourceService";
import { JobTypeEnum } from "../../../../models/Enums";

enum JobStates {
  ImportReadyForValidation = 1,
  ImportValidating = 2,
  ImportValidatedError = 3,
  ImportValidatedSuccess = 4,
  ImportReadyForProcessing = 5,
  ImportProcessing = 6,
  ImportProcessedError = 7,
  ImportProcessedSuccess = 8,
  ImportProcessedPartialSuccess = 9,
  ImportCancelRequested = 10,
  ImportCancelling = 11,
  ImportCancelled = 12
}

interface IProps {
  isWizardOpen: boolean;
  setIsWizardOpen: (isWizardOpen: boolean) => void;
}

interface IValidationResults extends IGetJobStatusResponseData {
  data: string;
}

interface IClientValidationResult {
  jobId: string;
  clientName: string;
  mainCSContact: string;
  clientRole: string;
  sapSoldToClientName: string;
  clientProjectSelectionCriteria: string;
  contractClientSelectionCriteria: string;
  error: string;
  groupValidationResults: IGroupValidationResults[];
  projectCode?: string;
}

interface IGroupValidationResults {
  name: string;
  type: string;
  id: number;
  status: UserGroupStatus;
}

enum UserGroupStatus {
  Available,
  Deleted,
  New
}

const jobType = JobTypeEnum.ClientSetUp;

type Props = IProps;

const clientSetupSteps = [
  {
    fields: [
      {
        type: "radiogroup",
        name: "Client Setup Type",
        label: "Select how to set up the client.",
        fieldId: "1",
        active: true,
        radioButtonOptions: [
          {
            title: "Enable feature for one project.",
            id: "1",
          },
          {
            title: "Set up features for all existing projects.",
            id: "2",
          },
          {
            title: "Configure Relativity workspace (for DRS or TAR).",
            id: "3",
          }
        ]
      },
      {
        type: "input",
        name: "Client Name",
        fieldId: "1.1",
        label: "Client Name",
        active: false,
        requiredWhenActive: true,
        resetForField: "1",
        min: 3,
        max: 233,
        labelAdditionalInfo: "the Client Name to be used in Epiq Access",
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
            { fieldId: "1", compare: "eq", value: "3" },
          ]
        },
      },
      {
        type: "input",
        name: " Main CS Contact",
        fieldId: "1.2",
        label: " Main CS Contact",
        active: false,
        requiredWhenActive: true,
        resetForField: "1",
        max: 238,
        labelAdditionalInfo: "who will approve setup and ongoing client administration",
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
            { fieldId: "1", compare: "eq", value: "3" },
          ]
        },
      },
      {
        type: "singleselect",
        fieldId: "1.3",
        name: "Client Role",
        label: "Client Role",
        active: false,
        requiredWhenActive: true,
        resetForField: "1",
        labelAdditionalInfo: "see training document for details on role/permissions",
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
            { fieldId: "1", compare: "eq", value: "3" },
          ]
        },
        GetData: AdminService.getRoleList,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return { text: item.roleName, id: item.id };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return {
            take: 100,
            skip: 0,
            sort: [{ field: "roleName", dir: "asc" }],
            filters: [
              {
                logic: "or",
                filters: [
                  { field: "roleName", operator: "contains", value: searchText == null ? "" : searchText }
                ] as Array<FilterDescriptor>
              },
              {
                logic: "and",
                filters: [
                  { field: "roleName", operator: "contains", value: "client" }
                ] as Array<FilterDescriptor>
              },
              {
                logic: "and",
                filters: [
                  { field: "roleName", operator: "notstartswith", value: "Admin Context:" }
                ] as Array<FilterDescriptor>
              },
              {
                logic: "and",
                filters: [
                  { field: "roleName", operator: "notstartswith", value: "Context:" }
                ] as Array<FilterDescriptor>
              },
              {
                logic: "and",
                filters: [
                  { field: "epiqOnly", operator: "eq", value: "false" }
                ] as Array<FilterDescriptor>
              }
            ] as CompositeFilterDescriptor[]
          } as IGridParams;
        }
      },
      {
        type: "displayOnlyField",
        name: "report_type",
        fieldId: "1.4.a",
        label: "Spend History Report Type",
        active: false,
        resetForField: "1",
        hideFieldIdLabel:true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
          ]
        },
      },
      {
        type: "checkbox",
        name: "Include Spend History Report Setup?",
        label: "Include Spend History Report Setup?",
        fieldId: "1.4",
        active: false,
        hideLabel: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
          ]
        },
      },
      {
        type: "radiogroup",
        name: "Spend History Report Type",
        fieldId: "1.4.3",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
            { fieldId: "1.4", compare: "eq", value: true },
          ]
        },
        hideLabel: true,
        hideFieldIdLabel: true,
        hideFieldInSummary: true,
        radioButtonOptions: [
          {
            title: "Spend History By SAP Sold To Client",
            id: "3",
          },
          {
            title: "Spend History By Client Name (Rollup)",
            id: "1",
          },
          {
            title: "Spend History By Contracts",
            id: "2",
          }
        ]
      },
      {
        type: "multiselect",
        fieldId: "1.4.1",
        name: "SAP Sold To Client Name",
        label: "SAP Sold To Client Name",
        filterable: true,
        active: false,
        requiredWhenActive: true,
        resetForField: "1",
        labelAdditionalInfo: "determines Spend History contracts to include",
        hideFieldIdLabel: true,
        GetData: AdminService.getClients,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
            { fieldId: "1.4.3", compare: "eq", value: "1" },
            { fieldId: "1.4", compare: "eq", value: true }
          ]
        },
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return { title: item.clientName, id: item.id };
          });
        },
        GetDataParams: (fieldValues: IFieldValueLookup, searchText?: string) => {
          return {
            take: 100,
            skip: 0,
            sort: [{ field: "clientName", dir: "asc" }],
            filters: [
              {
                logic: "and",
                filters: [
                  { field: "clientName", operator: "contains", value: searchText == null ? "" : searchText }
                ] as Array<FilterDescriptor>
              }
            ] as CompositeFilterDescriptor[]
          } as IGridParams;
        }
      },
      {
        type: "multiselect",
        fieldId: "1.4.2",
        name: "Contract Sold To Client Name",
        label: "Contract Sold To Client Name",
        filterable: true,
        active: false,
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        resetForField: "1",
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
            { fieldId: "1.4.3", compare: "eq", value: "2" },
            { fieldId: "1.4", compare: "eq", value: true }
          ]
        },
        GetData: AdminService.getContractClients,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return { title: `${item.clientName} (${item.clientNumber.toLocaleString()}) (${item.contractsCount.toLocaleString()})`, id: `${ item.clientName } (${ item.clientNumber.toLocaleString() })(${ item.contractsCount.toLocaleString() })` };
          });
        },
        GetDataParams: (fieldValues: IFieldValueLookup, searchText?: string) => {
          return {
            take: 100,
            skip: 0,
            sort: [{ field: "clientName", dir: "asc" }],
            filters: [
              {
                logic: "or",
                filters: [
                  { field: "clientName", operator: "contains", value: searchText == null ? "" : searchText },
                  { field: "clientNumber", operator: "contains", value: searchText == null ? "" : searchText }
                ] as Array<FilterDescriptor>
              }
            ] as CompositeFilterDescriptor[]
          } as IGridParams;
        }
      },
      {
        type: "multiselect",
        fieldId: "1.4.4",
        name: "SAP Sold To Client",
        label: "SAP Sold To Client",
        filterable: true,
        active: false,
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        resetForField: "1",
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
            { fieldId: "1.4.3", compare: "eq", value: "3" },
            { fieldId: "1.4", compare: "eq", value: true }
          ]
        },
        GetData: ResourceService.getResourcesDetails,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return { title: item.displayName, id: item.fields && item.fields.ObjectId };
          });
        },
        GetDataParams: (fieldValues: IFieldValueLookup, searchText?: string) => {
          return {
            take: 100,
            skip: 0,
            typecode: "sapclient",
            orderBy: "DisplayName",
            filters: [
              {
                logic: "or",
                filters: [
                  { field: "DisplayName", operator: "contains", value: searchText == null ? "" : searchText },
                ] as Array<FilterDescriptor>
              }
            ] as CompositeFilterDescriptor[]
          } as IGridParams;
        }
      },
      {
        type: "checkbox",
        name: "Include Pulse Projects?",
        label: "Include Pulse Projects?",
        fieldId: "1.5",
        active: false,
        hideFieldIdLabel: true,
        resetForField: "1",
        hideLabel: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
          ]
        },
      },
      {
        type: "multiselect",
        fieldId: "1.5.1",
        name: "Pulse Project Sold To Client Name",
        label: "Pulse Project Sold To Client Name",
        filterable: true,
        labelAdditionalInfo: "determines Open Projects to include",
        active: false,
        requiredWhenActive: true,
        resetForField: "1",
        hideFieldIdLabel: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
            { fieldId: "1.5", compare: "eq", value: true }
          ]
        },
        GetData: AdminService.getProjectClients,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return { title: `${item.clientName} (${item.projectAssociatedCount.toLocaleString()})`, id: item.clientName };
          });
        },
        GetDataParams: (fieldValues: IFieldValueLookup, searchText?: string) => {
          return {
            take: 100,
            skip: 0,
            sort: [{ field: "clientName", dir: "asc" }],
            filters: [
              {
                logic: "or",
                filters: [
                  { field: "clientName", operator: "contains", value: searchText == null ? "" : searchText }
                ] as Array<FilterDescriptor>
              }
            ] as CompositeFilterDescriptor[]
          } as IGridParams;
        }
      },
      {
        type: "input",
        name: "Company Name",
        fieldId: "1.6",
        label: "Enter the name to use for the Client in Epiq Access.",
        active: false,
        requiredWhenActive: true,
        resetForField: "1",
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "1" },
          ]
        },
      },
      {
        type: "input",
        name: "Primary CS Contact",
        fieldId: "1.7",
        label: "Type the primary CS contact name for approvals and client administration.",
        active: false,
        requiredWhenActive: true,
        resetForField: "1, 1.6",
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "1" },
            { fieldId: "1.6", compare: "neq", value: null },
            { fieldId: "1.6", compare: "neq", value: "" },
          ]
        },
      },
      {
        type: "singleselect",
        fieldId: "1.8",
        name: "Project",
        label: "Add the feature to which project?",
        active: false,
        requiredWhenActive: true,
        resetForField: "1, 1.6",
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "1" },
            { fieldId: "1.6", compare: "neq", value: null },
            { fieldId: "1.6", compare: "neq", value: "" },
            { fieldId: "1.7", compare: "neq", value: null },
            { fieldId: "1.7", compare: "neq", value: "" },
          ]
        },
        GetData: AdminService.getProjects,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const text = `${item.projectName} (${item["projectNumber"]})`;
            return {
              text,
              id: item.projectId,
              data: {
                lookupValues: {
                  SalesForceId: item["salesForceId"],
                  projectNumber: item["projectNumber"],
                  projectCode: item["esiProjectCode"],
                }
              }
            };
          });
        },
        GetDataParams: (searchText: string, existingFieldVals?: IFieldValueLookup) => {
          const filters = [
            {
              logic: "and",
              filters: [
                { field: "projectStatus", operator: "neq", value: "Closed" },
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          if (searchText) {
            filters.push(
              {
                logic: "or",
                filters: [
                  { field: "projectName", operator: "contains", value: searchText },
                  { field: "projectNumber", operator: "contains", value: searchText }
                ] as Array<FilterDescriptor>
              }
            );
          }

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "",
            showGroupsWithDupePermissions: true,
            flattenResults: false,
            orderBy: "projectName",
            filters: filters
          };
        }
      },
      {
        type: "input",
        name: "LS Project Code",
        fieldId: "1.9",
        label: "Provide the LS Project Code.",
        active: false,
        requiredWhenActive: true,
        resetForField: "1.8",
        labelAdditionalInfo: "This code is used in the user and resource group name.",
        defaultValueFunc: (existingFieldVals?: IFieldValueLookup) => {
          return existingFieldVals["1.8"].lookupValues && existingFieldVals["1.8"].lookupValues["projectCode"] ? existingFieldVals["1.8"].lookupValues["projectCode"] : "";
        },
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "1" },
            { fieldId: "1.8", compare: "neq", value: null },
            { fieldId: "1.8", compare: "neq", value: "" },
          ]
        },
      },
      {
        type: "displayOnlyField",
        name: "DRS or TAR Report",
        fieldId: "",
        label: "DRS or TAR Report",
        active: false,
        resetForField: "1",
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "3" },
          ]
        },
      },
      {
        type: "checkbox",
        name: "Relativity Workspace Reports (for DRS or TAR)",
        label: "Configure Relativity Workspace Reports (for DRS or TAR)",
        fieldId: "1.10",
        hideFieldIdLabel: true,
        active: false,
        hideLabel: true,
        value: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "3" },
          ]
        },
      },
      {
        type: "multiselect",
        fieldId: "1.11",
        name: "Relativity Workspace Artifact ID.",
        label: "Enter the Relativity Workspace Artifact ID.",
        filterable: true,
        active: false,
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        resetForField: "1",
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "3" },
          ]
        },
        GetData: ResourceService.getResourcesDetails,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return { title: `${item.displayName} (${item.fields.CaseArtifactId.toString()})`, id: item.fields && item.fields.ResourceId };
          });
        },
        GetDataParams: (fieldValues: IFieldValueLookup, searchText?: string) => {
          return {
            take: 100,
            skip: 0,
            sort: [{ field: "description", dir: "asc" }],
            typecode: "relativityworkspace",
            filters: [
              {
                logic: "or",
                filters: [
                  { field: "CaseArtifactId", operator: "contains", value: searchText == null ? "" : searchText },
                  { field: "DisplayName", operator: "contains", value: searchText == null ? "" : searchText },
                ] as Array<FilterDescriptor>
              }
            ] as CompositeFilterDescriptor[]
          } as IGridParams;
        }
      },
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "2" },
            { fieldId: "1.1", compare: "neq", value: null },
            { fieldId: "1.1", compare: "neq", value: "" },
            { fieldId: "1.2", compare: "neq", value: null },
            { fieldId: "1.2", compare: "neq", value: "" },
            { fieldId: "1.3", compare: "neq", value: null },
            { fieldId: "1.3", compare: "neq", value: "" }
          ]
        },
        {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "1" },
            { fieldId: "1.6", compare: "neq", value: null },
            { fieldId: "1.6", compare: "neq", value: "" },
            { fieldId: "1.7", compare: "neq", value: null },
            { fieldId: "1.7", compare: "neq", value: "" },
            { fieldId: "1.8", compare: "neq", value: null },
            { fieldId: "1.8", compare: "neq", value: "" },
            { fieldId: "1.9", compare: "neq", value: null },
            { fieldId: "1.9", compare: "neq", value: "" },
          ]
        },
        {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "3" },
            { fieldId: "1.1", compare: "neq", value: null },
            { fieldId: "1.1", compare: "neq", value: "" },
            { fieldId: "1.2", compare: "neq", value: null },
            { fieldId: "1.2", compare: "neq", value: "" },
          ]
        },
      ]
    },
    header: "Client Setup Wizard",
    subHeader: "WELCOME TO CLIENT SETUP",
    summaryTitle: "DETAILS",
    description:
      "Use this wizard to set up a client by providing the information needed to create default User Groups, Permissions, and Resources. Choose to set up a project with new features or set up multiple projects."
  },
  {
    fields: [
      {
        type: "multiselect",
        fieldId: "3",
        name: "Users",
        label: "Select the users.",
        filterable: true,
        active: false,
        renderType: "users",
        requiredWhenActive: true,
        hideFieldIdLabel: true,
        hasCustomItemRender: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1", compare: "eq", value: "1" },
          ]
        },
        GetData: AdminService.getUserList,
        ValuesConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return `${item.firstName} ${item.lastName} - ${item.username}`;
          });
        },
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const name = `${item.firstName},${item.lastName}`;
            return {
              title: name,
              id: `${item.firstName} ${item.lastName} - ${item.username}`,
              userId: item.id,
              fullName: name,
              userName: item.username,
              lastLoginDate: item.lastLoginDate
            };
          });
        },
        GetDataParams: (fieldValues: IFieldValueLookup, searchText?: string) => {
          return {
            take: 100,
            skip: 0,
            sort: [{ field: "firstName", dir: "asc" }],
            filters: [
              {
                logic: "or",
                filters: [
                  { field: "firstName", operator: "contains", value: searchText == null ? "" : searchText },
                  { field: "lastName", operator: "contains", value: searchText == null ? "" : searchText },
                  { field: "username", operator: "contains", value: searchText == null ? "" : searchText }
                ] as Array<FilterDescriptor>
              }
            ] as CompositeFilterDescriptor[]
          } as IGridParams;
        }
      },
    ],
    nextCondition: { fieldId: "3", compare: "neq", value: null },
    dependency: { fieldId: "1", compare: "eq", value: "1" },
    header: "Client Setup Wizard",
    subHeader: "SELECT USERS",
    summaryTitle: "USER INFORMATION",
    description:
      "Supply the users who need permission to the new features. To search for a user, start typing the first or last name and continue adding letters to narrow the search until you can select the name.",
  },
  {
    fields: [
      {
        type: "radiogroup",
        name: "Add Feature",
        label: "Select the feature to grant to the client.",
        fieldId: "4.1",
        active: true,
        radioButtonOptions: [
          {
            title: "Easy Upload Work Request",
            id: "1",
          },
          {
            title: "Project Closure Work Request",
            id: "2",
          },
          {
            title: "Other",
            id: "3",
          }
        ]
      },
      {
        type: "singleselect",
        fieldId: "4.2",
        name: "Client Role",
        label: "Select the appropriate client role.",
        active: false,
        requiredWhenActive: true,
        resetForField: "4.1",
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "4.1", compare: "neq", value: null },
            { fieldId: "4.1", compare: "neq", value: "" },
          ]
        },
        GetData: AdminService.getRoleList,
        preSelectTextFunc: (fieldValues: IFieldValueLookup) => {
          return fieldValues["4.1"] && fieldValues["4.1"].value ? (fieldValues["4.1"].value == "1" ? "Client Submit Wrk Req - Easy Upload" : (fieldValues["4.1"].value == "2" ? "Client Submit Wrk Req - Proj Close & Data Delete" : "")) : "";
        },
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return { text: item.roleName, id: item.id };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return {
            take: 100,
            skip: 0,
            sort: [{ field: "roleName", dir: "asc" }],
            filters: [
              {
                logic: "or",
                filters: [
                  { field: "roleName", operator: "contains", value: searchText == null ? "" : searchText }
                ] as Array<FilterDescriptor>
              },
              {
                logic: "and",
                filters: [
                  { field: "roleName", operator: "contains", value: "client" }
                ] as Array<FilterDescriptor>
              },
              {
                logic: "and",
                filters: [
                  { field: "roleName", operator: "notstartswith", value: "Admin Context:" }
                ] as Array<FilterDescriptor>
              },
              {
                logic: "and",
                filters: [
                  { field: "roleName", operator: "notstartswith", value: "Context:" }
                ] as Array<FilterDescriptor>
              },
              {
                logic: "and",
                filters: [
                  { field: "epiqOnly", operator: "eq", value: "false" }
                ] as Array<FilterDescriptor>
              }
            ] as CompositeFilterDescriptor[]
          } as IGridParams;
        }
      }
    ],
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "4.1", compare: "neq", value: null },
        { fieldId: "4.2", compare: "neq", value: null },
        { fieldId: "4.2", compare: "neq", value: "" },
      ]
    },
    dependency: { fieldId: "1", compare: "eq", value: "1" },
    header: "Client Setup Wizard",
    subheader: "SPECIFY THE FEATURE AND ROLE",
    summaryTitle: "FEATURE AND ROLE",
    description:
      "Select the feature and role to add to the client so the appropriate groups can be assigned.",
  },
  {
    fields: [
      {
        type: "hiddenlookup",
        fieldId: "2.1",
        label: "Default Reports",
        name: "Default Reports",
        active: false,
        hideFieldInSummary: true,
        lookupCompletion: (dataResult: any, fieldValues: IFieldValueLookup) => {
          if (!dataResult || dataResult.count === 0) {
            return { success: false };
          }
          return { success: true, data: dataResult.data };
        },
        GetData: ResourceService.getResourceGroupResources,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return { field: item.name, id: item.id };
          });
        },
        GetDataParams: () => {
          const filters = [
            {
              logic: "or",
              filters: [
                { field: "resourceTypeNavigation.displayName", operator: "eq", value: "Report" }
              ] as Array<FilterDescriptor>
            },
            {
              logic: "or",
              filters: [
                { field: "isDefault", operator: "eq", value: "true" },
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          return {
            skip: 0,
            take: 500,
            filters: filters,
            sort: [{ field: "name", dir: "asc" }],
            excludeResourceGroupResources: true
          };
        }
      },
      {
        type: "radiogroup",
        name: "Enable Reports",
        label: "Do you want to grant reports for the client?",
        fieldId: "2.2.2",
        active: true,
        radioButtonOptions: [
          {
            title: "Yes",
            id: "1",
          },
          {
            title: "No",
            id: "2",
            selected: true
          }
        ]
      },
      {
        type: "listbox",
        name: "Reports Available",
        fieldId: "2.2.1",
        label: " ",
        active: false,
        GetData: ResourceService.getResourceGroupResources,
        dependency: { fieldId: "2.2.2", compare: "eq", value: "1" },
        WizardType: "ClientSetup",
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            return { field: item.name, id: item.id };
          });
        },
        GetDataParams: () => {
          const filters = [
            {
              logic: "or",
              filters: [
                { field: "resourceTypeNavigation.displayName", operator: "eq", value: "Report" }
              ] as Array<FilterDescriptor>
            },
            {
              logic: "or",
              filters: [
                { field: "epiqOnly", operator: "eq", value: "false" },
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          return {
            skip: 0,
            take: 500,
            filters: filters,
            sort: [{ field: "name", dir: "asc" }],
            excludeResourceGroupResources: true
          };
        }
      }
    ],
    nextCondition:
    {
      logic: "or",
      subConditions: [
        { fieldId: "2.2.2", compare: "neq", value: null }
      ]
    },
    header: "Client Setup Wizard",
    subHeader: "INCLUDE REPORTS",
    summaryTitle: "REPORTS",
    description:
      "Enable reports for the client as needed. When enabled, the reports listed in Selected Reports become available to the users you just selected. Add or remove reports from Selected Reports to tailor the list. Consider the level of visibility the reports provide before granting access.",
  },
] as Array<IWizardSection>;

export const createClientSetupRequest = function (valueDictionary: IFieldValueLookup) {
  const request = {} as IWizardRequest;

  const getFieldValue = function (id: string) {
    return valueDictionary[id] ? valueDictionary[id].value : null;
  };

  const getFieldText = function (id: string) {
    return valueDictionary[id] ? valueDictionary[id].text : null;
  };

  const isSingleProjectFeature = getFieldValue("1") === "1";

  const soldToClientNames = getFieldValue("1.4.1") && (getFieldValue("1.4.1") as Array<string>).join(';');
  const soldToClients = getFieldValue("1.4.4") && (getFieldValue("1.4.4") as Array<string>).join(';');
  const relWorkspaceIds = getFieldValue("1.11") && (getFieldValue("1.11") as Array<string>).join(';');
  const contracts = getFieldText("1.4.2");
  const contractClientNames = contracts && contracts.split(';');
  //extract the value enclosed in the brackets from contractClientNames

  const contractClientNumbersArray = () => {
    if (contractClientNames) {
      const numbers = contractClientNames.map((client) => {
        return client.match(/\(([^)]+)\)/)[1];
      });

      return numbers.filter((item, index) => numbers.indexOf(item) === index);
    }

    return;
  };

  const contractClients = contractClientNumbersArray() && (contractClientNumbersArray() as Array<string>).join(';');

  const clients = getFieldValue("1.5.1");
  const projecClients = clients && (clients as Array<string>).join(';');

  const defaultRes = getFieldValue("2.1");
  const defaultResources = defaultRes && (defaultRes as Array<string>).join(';');

  const addOrRemove = getFieldValue("2.2.2") === "1";
  const resources = getFieldValue("2.2.1");
  const selectedResources = resources && (resources as Array<string>).join(';');

  const users = valueDictionary[3] && valueDictionary[3].lookupValues ? valueDictionary[3].lookupValues["userIds"] : [];
  const strUserIds = users && (users as Array<string>).join(';');

  request["ClientName"] = !isSingleProjectFeature ? getFieldValue("1.1") : getFieldValue("1.6");
  request["MainCSContact"] = !isSingleProjectFeature ? getFieldValue("1.2") : getFieldValue("1.7");
  request["ClientRole"] = !isSingleProjectFeature ? getFieldValue("1.3") : getFieldValue("4.2");
  request["SAPSoldToClientNameSelectionCriteria"] = getFieldValue("1.4.3") == 1 ? soldToClientNames : "";
  request["ContractClientSelectionCriteria"] = getFieldValue("1.4.3") == 2 ? contractClients : "";
  request["ClientProjectSelectionCriteria"] = projecClients;
  request["resourceIds"] = addOrRemove ? selectedResources : defaultResources;
  request["userIds"] = strUserIds;
  request["featureToGrant"] = getFieldValue("1") == 1 ? (getFieldValue("4.1") == "1" ? "easyupload" : "projectclosure") : "";
  request["projectId"] = getFieldValue("1.8");
  request["projectCode"] = getFieldValue("1.9") ? getFieldValue("1.9") : (valueDictionary["1.8"] && valueDictionary["1.8"].lookupValues && valueDictionary["1.8"].lookupValues["projectCode"] ? valueDictionary["1.8"].lookupValues["projectCode"].toString() : "");
  request["SAPSoldToClientSelectionCriteria"] = getFieldValue("1.4.3") == 3 ? soldToClients : "";
  request["RelativityWorkspaceSelectionCriteria"] = relWorkspaceIds;



  if (valueDictionary["2.2.1"] && valueDictionary["2.2.1"].text === null) {
    valueDictionary["2.2.1"].text = "No report(s) selected.";
  }

  return request;
};

const warningMessage: IWizardWarningMessage = {
  title: "Cancel the client setup request?",
  description:
    "Closing the Client Setup Wizard removes any selections you made. Do you want to cancel this client setup request?"
};

export const jobMessagingConfig: JobMessagingConfig = {
  genericErrorMessage: "Failed to submit client setup request"
};

const ClientSetupWizard = (props: Props) => {
  const [error, setError] = useState<string>("");
  const [warning, setWarning] = useState<string>("");
  const [isValidationInProgress, setValidationProgressSate] = useState(false);
  const [clientValidationResult, setclientValidationResult] = useState<IClientValidationResult>(null);
  const [selecetedReports, setReports] = useState<WizardFieldTypes>(null);
  const [isGroupDeleted, setGroupDeleted] = useState(false);

  const onNextButtonClick = async function (page: number, request: IWizardRequest, jobId: string) {
    if (page === 4) {
      setValidationProgressSate(true);
      setError("");
      setWarning("");

      const result = await JobsService.submitJob({
        jobType: jobType,
        jobId: jobId,
        requestSubmitData: {
          fields: { ...request }
        },
        readyForValidation: true
      });

      if (result.ok) {
        await fetchJobStatus(jobId);
      } else {
        NotificationService.showErrorToast("Failed to submit client setup request for validation.");
      }
    }
  };

  const onFieldDataChange = function (valDictionary: IFieldValueLookup, pageNum: number, fieldId?: string) {

    if (valDictionary["2.2.2"] && valDictionary["2.2.2"].value === "Yes") {
      const fieldText = (valDictionary["2.2.1"] && valDictionary["2.2.1"].text) ? valDictionary["2.2.1"].text.split(';') : null;
      setReports(fieldText);
    }
    else if (valDictionary["2.2.2"] && valDictionary["2.2.2"].value === "No") {
      const fieldText = (valDictionary["2.1"] && valDictionary["2.1"].text) ? valDictionary["2.1"].text.split(';') : null;
      setReports(fieldText);
    }
  }


  const fetchJobStatus = async (jobId: string) => {
    if (jobId) {
      const response = await JobsService.getJobStatus<IValidationResults>(jobId);

      if (response.ok) {
        const clientValidationResult = JSON.parse(response.data.data) as IClientValidationResult;

        switch (response.data.statusId) {
          case JobStates.ImportReadyForValidation:
          case JobStates.ImportValidating:
          case JobStates.ImportReadyForProcessing:
          case JobStates.ImportProcessing:
            await ServiceBase.setTimeoutPromise(1000);
            await fetchJobStatus(jobId);
            break;
          case JobStates.ImportValidatedSuccess:

            const deletedGroupNames = displayGroups(clientValidationResult);
            setclientValidationResult(clientValidationResult);

            if (clientValidationResult && clientValidationResult.groupValidationResults) {
              const isGroupDeleted = clientValidationResult.groupValidationResults.some(ug => ug.status === UserGroupStatus.Deleted);
              setGroupDeleted(isGroupDeleted);

              if (isGroupDeleted) {
                const message = `${deletedGroupNames} was previously deleted. Click on submit for reactivate deleted group(s).`;
                NotificationService.showWarningToast(message);
                setWarning(message);
              }
              else {
                const isResourcesAvailable = clientValidationResult.groupValidationResults.some(ug => ug.status === UserGroupStatus.Available);
                const projectCode = clientValidationResult.projectCode ? " - " + clientValidationResult.projectCode : '';

                if (isResourcesAvailable) {
                  const message = `Group already exists for Client ${clientValidationResult.clientName}${projectCode}`;
                  setError(message);
                  NotificationService.showErrorToast(message);
                }
              }

            }

            setValidationProgressSate(false);
            break;
          case JobStates.ImportValidatedError:
            setValidationProgressSate(false);

            if (clientValidationResult.error) {
              setError(clientValidationResult.error);
              NotificationService.showErrorToast(clientValidationResult.error);
            }

            break;
          case JobStates.ImportProcessedSuccess:
            setValidationProgressSate(false);
            NotificationService.showSuccessToast("Client setup request submitted successfully.", true);
            break;
          case JobStates.ImportProcessedError:
            setValidationProgressSate(false);

            if (clientValidationResult.error) {
              setError(clientValidationResult.error);
              NotificationService.showErrorToast(clientValidationResult.error, true);
            }

            break;
        }
      }
    }
  };

  const onSubmit = async (jobId: string) => {
    setValidationProgressSate(true);
    setError("");
    setWarning("");

    if (!isGroupDeleted)
      NotificationService.showInfoToast('Client Setup process has been initiated. We will notify you once complete.', true);

    await fetchJobStatus(jobId);
  }

  const resultSetInfo = () => {
    let type = "";
    return (
      clientValidationResult.groupValidationResults &&
      clientValidationResult.groupValidationResults.map(item => {
        const displayGroupHeader = type !== item.type;
        type = item.type;

        return (
          <div className="inner-section" key={`footer-summary-parent${item.name}`}>
            {displayGroupHeader && (
              <div className="field-name" key={`footer-summary-child1${item.name}`}>
                {item.type === "usergroup" ? "User Groups" : "Resource Group"}
              </div>
            )}
            <div className="footer-summary-field-value" key={`footer-summary-child2${item.name}`}>
              {item.name}
            </div>
          </div>
        );
      })
    );
  };


  const getFooterSummary = () => {
    return isValidationInProgress || !clientValidationResult ? (
      <>
        <div className="footer-summary-title">RESULTS</div>
        <span className="pending"> Validation is in progress, please wait for a few seconds ...</span>

      </>
    ) : (
      <>
        <div className="footer-summary-title">RESULTS</div>
        <div className="footer-summary-description">
          This action sets up the following groups for {clientValidationResult.clientName}:
        </div>
        {resultSetInfo()}
        {error && (
          <div className="error-info">
            <span>
              <ErrorIcon />
            </span>{" "}
            <span className="error-msg">{error}</span>
          </div>
        )}
        {warning && (
          <div className="warning-info">
            <span>
              <WarningIcon />
            </span>{" "}
            <span className="warning-msg">{warning}</span>
          </div>
        )}
      </>
    );
  };

  const wizardSummary: IWizardSummary = {
    title: "CLIENT SETUP SUMMARY",
    description:
      "Carefully review the resulting groups and permissions for the new client. To make changes, click the Edit pencil. When you are satisfied with this summary, click Submit.",
    footerSummary: (values: IFieldValueLookup) => { return getFooterSummary() }
  };

  const displayGroups = (clientValidationResult: IClientValidationResult) => {
    if (clientValidationResult && clientValidationResult.groupValidationResults) {
      const deletedUserGroups = clientValidationResult.groupValidationResults.filter(x => x.status === UserGroupStatus.Deleted && x.type === "usergroup").map((v: IGroupValidationResults) => v.name).join(', ');
      const deletedResourceGroup = clientValidationResult.groupValidationResults.find(x => x.status === UserGroupStatus.Deleted && x.type === "resourcegroup");

      let displayMessage = "";
      if (deletedUserGroups) {
        displayMessage = `User group(s): ${deletedUserGroups}`;
      }
      if (deletedResourceGroup) {
        const resourceGroupMessage = `Resource group: ${deletedResourceGroup && deletedResourceGroup.name}`;
        displayMessage = displayMessage ? `${displayMessage} and ${resourceGroupMessage}` : resourceGroupMessage;
      }

      return displayMessage;
    }
  }

  const displayGroupTypes = (clientValidationResult: IClientValidationResult) => {
    if (clientValidationResult && clientValidationResult.groupValidationResults) {
      const isDeletedUserGroups = clientValidationResult.groupValidationResults.some(x => x.status === UserGroupStatus.Deleted && x.type === "usergroup");
      const isDeletedResourceGroup = clientValidationResult.groupValidationResults.some(x => x.status === UserGroupStatus.Deleted && x.type === "resourcegroup");

      let displayMessage = "";
      if (isDeletedUserGroups) {
        displayMessage = `User group(s)`;
      }
      if (isDeletedResourceGroup) {
        const resourceGroupMessage = `Resource group`;
        displayMessage = displayMessage ? `${displayMessage} and Resource group` : "Resource group";
      }

      return displayMessage;
    }
  }

  const confirmReactivateSubmitMessage: IWizardWarningMessage = {
    title: `Reactivate ${displayGroupTypes(clientValidationResult)}`,
    description: `${displayGroups(clientValidationResult)} was previously deleted. Do you want to reactivate this deleted groups?`
  };

  return (
    <Paper>
      <SupportRequestPopup
        open={props.isWizardOpen}
        onClose={() => { props.setIsWizardOpen(false); }}
        jobTypeNumber={jobType}
        createRequestObject={createClientSetupRequest}
        wizardSections={clientSetupSteps}
        wizardSummary={wizardSummary}
        warningMessage={warningMessage}
        onNextButtonClickWNewJob={onNextButtonClick}
        disableSubmitButton={isValidationInProgress || error.length > 0}
        onFieldDataChange={onFieldDataChange}
        disableBackButton={isValidationInProgress}
        jobMessageConfig={jobMessagingConfig}
        onSubmitClick={onSubmit}
        createRequestEachPage={true}
        confirmReactivateMessage={isGroupDeleted ? confirmReactivateSubmitMessage : null}
      />
    </Paper>
  );
};

export default ClientSetupWizard;
