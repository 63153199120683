import React, { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {
  IWizardSection,
  IFieldValueLookup,
  IWizardFieldChangeEvent,
  IWizardFieldStatusUpdateEvent,
  IWizardSummary,
  IWizardField,
  IFieldManagerFunctions
} from "../../../models/Wizard";
import { componentFromType } from "./WizardElements";
import { cloneDeep } from "lodash";
import "./WizardFieldManager.scss";
import WizardStateManager, { IActiveErrorMessages } from "../utilities/WizardStateManager";
import WizardHelper from "../utilities/WizardHelper";
import WizardSummary from "./WizardSummary";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import ReactMarkdown from 'react-markdown';

interface IProps {
  match?: any;
  history?: any;
  existingData?: WizardStateManager;
  wizardSections: Array<IWizardSection>;
  wizardSummary: IWizardSummary;
  onWrapperDataChange?: (valDictionary: IFieldValueLookup, nextBlocked: boolean) => void;
  maxWidth?: number;
  jobId?: string;
  jobTypeNumber: number;
  debugMode?: boolean;
  seedKey: string;
  onFieldDataChange?: (lookup: IFieldValueLookup, pageNum: number, fieldId?: string) => void;
  updateCounter?: number;
  managerFunctionsOnLoad: (funcManager: IFieldManagerFunctions) => void;
  permissions: Set<string>;
}

interface IFieldStatus {
  isBusy?: boolean;
  errorMessage?: string;
  fieldId: string;
}

interface IFieldStatusMessages {
  [fieldId: string]: IFieldStatus;
}

type Props = IProps;

const WizardFieldManager = (props: Props) => {

  const getLabel = WizardHelper.getLabel;

  const [fieldState] = useState(props.existingData || new WizardStateManager({}));
  const [fieldUpdates, setFieldUpdates] = useState(0);
  const [fieldValueUpdates, setFieldValueUpdates] = useState({} as IFieldValueLookup);
  const [resetDependencies, setResetDependencies] = useState((props.existingData && props.existingData.resetDepSet) ? props.existingData.resetDepSet : {} as Record<string, Set<string>>);
  const [fieldBlockingStatuses, setFieldBlockingStatuses] = useState({} as IFieldStatusMessages);
  const handleSubmit = (dataItem: any) => alert(JSON.stringify(dataItem, null, 2));

  useEffect(() => {
    // do once
    updateWrapperInfo(fieldState.values);
    props.managerFunctionsOnLoad({ setPage, clearAllFieldBlockStatuses });
  }, []);

  useEffect(() => {
    updateWrapperInfo(fieldState.values);
  }, [props.updateCounter, fieldUpdates, props.jobId]);

  useEffect(
    () => {
      if (!fieldValueUpdates) return;

      updateFields(fieldValueUpdates);
      setFieldValueUpdates(null);
    },
    [fieldValueUpdates]
  );

  const updateFields = function (fieldValueUpdates: IFieldValueLookup) {
    fieldState.updateFields(fieldValueUpdates);
    setFieldUpdates((f) => f + 1);
  }

  const setFields = function (fieldValueUpdates: IFieldValueLookup) {
    fieldState.values = fieldValueUpdates;
    setFieldUpdates((f) => f + 1);
  }

  const reValidateRelatedErrors = function (conditionFieldId: string, fieldVals: IFieldValueLookup) {
    // go through existing fields and see if the target field on their condition gets changed
    // We aren't enabling errors, just removing, consider enabling errors on related fields. Showing them happens on the field change only

    props.wizardSections[fieldState.page].fields.forEach(relatedField => {
      if (relatedField.fieldId === conditionFieldId) {
        return; // Don't need to revalidate self as a condition related to self's value
      }

      const fieldError = fieldState.validationMsgs[relatedField.fieldId]
        ? fieldState.validationMsgs[relatedField.fieldId][conditionFieldId]
        : null;
      if (fieldError) {
        // TODO I don't like that this method is both a read and a write
        fieldState.dependencyStatus(
          relatedField.validation,
          fieldVals,
          relatedField.fieldId,
          fieldVals[relatedField.fieldId].value
        );
      }
    });
  };

  const getWithActiveStatusUpdates = function (valDictionary: IFieldValueLookup, pageNumber: number) {
    if (pageNumber > props.wizardSections.length - 1) {
      return valDictionary;
    }

    return fieldState.getWithActiveStatusUpdates(props.wizardSections[pageNumber].fields, valDictionary);
  };

  const fieldStatusUpdate = (fieldEvent: IWizardFieldStatusUpdateEvent) => {

    setFieldBlockingStatuses((prevStatuses) => {
      if (fieldEvent.isBusy || fieldEvent.error) {
        prevStatuses[fieldEvent.fieldId] = {
          errorMessage: fieldEvent.error,
          isBusy: fieldEvent.isBusy,
          fieldId: fieldEvent.fieldId
        };
      } else {
        delete prevStatuses[fieldEvent.fieldId];
      }

      return prevStatuses;
    });
    setFieldUpdates((f) => f + 1); // fieldBlockingStatuses is not rendering properly for validation error messages, so here I am triggering fieldUpdate usestate to render the elemments to display error messages
  };

  const fieldOnChange = async function (field: IWizardFieldChangeEvent) {
    // TODO textarea and Input don't need to call all this for every character typed. Maybe should only call this sequence whenever characters change from 1 to 0 or 0 to 1

    const newFieldVals = cloneDeep(fieldState.values);

    newFieldVals[field.fieldId] = {
      value: field.value,
      active: true,
      isDirty: true,
      text: field.text,
      lookupValues: field.lookupValues
    };

    console.log("field onChange recieved:", field, newFieldVals);

    const isValid = !field.validation
      ? true
      : fieldState.dependencyStatus(field.validation, newFieldVals, field.fieldId, field.value);

    let updatedFeldValsWDeps = resetAnyDependencies(field, newFieldVals);

    if (isValid) {
      Object.keys(fieldState.validationMsgs).length > 0 && reValidateRelatedErrors(field.fieldId, updatedFeldValsWDeps);
      updatedFeldValsWDeps = getWithActiveStatusUpdates(updatedFeldValsWDeps, fieldState.page);
    }
    // In some of the scenarios like when changed  hiddenlookup field status(active field) from true to false(due to dropdown values changes)
    // errormessage in fieldBlockingStatuses not clearing.So for that purpose implemented this method
    Object.keys(fieldBlockingStatuses).length > 0 && resetAnyFieldBlockStatuses(updatedFeldValsWDeps);

    setFields(updatedFeldValsWDeps);

    if (props.onFieldDataChange) {
      props.onFieldDataChange(updatedFeldValsWDeps, fieldState.page, field.fieldId);
    }

    if (field.postChange) {
      var updates = await field.postChange(newFieldVals[field.fieldId], newFieldVals);
      setFieldValueUpdates({ [field.fieldId]: { ...updates } });
    }

  };

  const clearAllFieldBlockStatuses = function () {
    setFieldBlockingStatuses({});
    setFieldUpdates((f) => f + 1);
  }

  const resetAnyFieldBlockStatuses = function (fieldValues: IFieldValueLookup) {

    setFieldBlockingStatuses((prevStatuses) => {

      Object.keys(prevStatuses).map(fieldId => {

        const fieldItem = props.wizardSections[fieldState.page].fields.find(x => x.fieldId === fieldId);
        if (fieldItem != null) {
          if (!WizardHelper.isFieldActive(fieldItem, fieldValues)) {
            delete prevStatuses[fieldId]
          }
        }
      });

      return prevStatuses;
    });
    setFieldUpdates((f) => f + 1);
  }

  const resetAnyDependencies = function (field: IWizardField, fieldValues: IFieldValueLookup) {
    // Add 'self' as a dependency
    if (field.resetForField) {
      setResetDependencies((newDeps) => {
        const spiltResetFieldIds = field.resetForField.split(',');
        spiltResetFieldIds.forEach((resetFieldId: string, i: number) => {
          let deps = resetDependencies[resetFieldId];
          if (!deps) {
            deps = new Set();
          }
          deps.add(field.fieldId);
          newDeps[resetFieldId] = deps
        })
        return newDeps;
      });
    }

    const valMsgs = fieldState.validationMsgs;
    // If someone else depends on 'self' clear them
    const dependencyOnMe = resetDependencies[field.fieldId];
    if (dependencyOnMe) {
      dependencyOnMe.forEach(fieldId => {
        delete fieldValues[fieldId];
        delete valMsgs[fieldId];
      });
      fieldState.resetValidationFields(valMsgs);
    }

    return fieldValues;
  };

  const updateWrapperInfo = function (valDictionary: IFieldValueLookup) {

    if (!props.onWrapperDataChange) return;

    props.onWrapperDataChange(valDictionary, Object.keys(fieldBlockingStatuses).length > 0);

    if (props.onFieldDataChange) {
      props.onFieldDataChange(fieldState.values, fieldState.page);
    }

  };

  const setPage = function (pageNumber: number, newFieldVals: IFieldValueLookup) {

    if (pageNumber == null) return;

    newFieldVals = getWithActiveStatusUpdates(newFieldVals, pageNumber);

    fieldState.page = pageNumber;
    setFields(newFieldVals);
  };

  console.log("current field values", fieldState.values);

  if (fieldState.page >= props.wizardSections.length) {
    return <WizardSummary
      fieldValues={fieldState.values}
      wizardSummary={props.wizardSummary} wizardSections={props.wizardSections}
      dependencySatisfied={(dep, fv) => fieldState.dependencyStatus(dep, fv)}
      setPage={(page, fv) => setPage(page, fv)}
      permissions = {props.permissions}
    />
  }

  return (
    <div className="dynamic-wizard-fields">
      {!props.onWrapperDataChange && <h2>{props.wizardSections[fieldState.page].header}</h2>}
      <h3>{getLabel(props.wizardSections[fieldState.page].subHeader, fieldState.values)}</h3>
      <Form
        onSubmit={handleSubmit}
        key={"form" + props.seedKey}
        render={formRenderProps => (
          <FormElement style={{ maxWidth: props.maxWidth || 650 }}>
            <fieldset>
              <div>{getLabel(props.wizardSections[fieldState.page].description, fieldState.values)}</div>
              <div>{getLabel(props.wizardSections[fieldState.page].staticText, fieldState.values)}</div>
              {props.wizardSections[fieldState.page].fields.map(item => {
                const labelClsName = (item.type !== "hiddenlookup" && item.type !== "hiddenField") ? "label-wrap" : "";
                if (!WizardHelper.isFieldActive(item, fieldState.values)) return <></>;
                return <>
                  {item.type !== "hiddenField" && <div className={`${labelClsName} ${item.cssName ? item.cssName(fieldState.values) : ""}`}>
                    {(item.type !== "hiddenlookup" && item.hideFieldIdLabel !== true) && props.debugMode && item.fieldId && <em>({item.fieldId}) </em>}
                    {(item.type !== "hiddenlookup" && item.hideLabel !== true) &&
                      <Tooltip
                        title={item.tooltipTitle || ""}
                        placement="top-start"
                        arrow
                      ><b> {getLabel(item.label, fieldState.values, "(EMPTY)")}</b>
                      </Tooltip>
                    }

                    {item.labelAdditionalInfo ? <i> - {item.labelAdditionalInfo} </i> : ""}
                    {item.labelCaption ? <><br /><ReactMarkdown className="dynamic-wizard-label-caption" children={item.labelCaption} /></> : ""}
                    {item.type !== "checkbox" && <div />}
                    <Field
                      component={componentFromType(item.type)}
                      onChange={fieldOnChange}
                      fieldStatusUpdate={fieldStatusUpdate}
                      name={item.fieldId}
                      fieldData={{ ...item }}
                      fieldValues={fieldState.values}
                      valInfo={fieldState.values[item.fieldId]}
                      validation={item.validation}
                      jobId={props.jobId}
                      key={item.fieldId + props.seedKey}
                      seedKey={item.fieldId + "-" + props.seedKey}
                      jobType={props.jobTypeNumber}
                      watchField={item.watchField}
                    />
                    {item.fieldAdditionalNotes && (<><span className="field-additional-notes-label">{getLabel(item.fieldAdditionalNotes, fieldState.values)}</span></>)}
                    {(fieldState.validationMsgs[item.fieldId] || fieldBlockingStatuses[item.fieldId]) && (
                      <div className="validation-messages">
                        {fieldState.validationMsgs[item.fieldId] &&
                          Object.keys(fieldState.validationMsgs[item.fieldId]).map(conditionFieldId => {
                            return Object.keys(fieldState.validationMsgs[item.fieldId][conditionFieldId]).map(
                              conditionKey => {
                                return (
                                  <p className="field-state-error-msg"><ErrorIcon className="field-block-error-icon" /> {fieldState.validationMsgs[item.fieldId][conditionFieldId][conditionKey]}</p>
                                );
                              }
                            );
                          })}
                        {fieldBlockingStatuses[item.fieldId] &&
                          fieldBlockingStatuses[item.fieldId].errorMessage && (
                            <p className="field-block-error-msg"><InfoIcon className="field-block-error-icon" /> {fieldBlockingStatuses[item.fieldId].errorMessage}</p>
                          )}
                      </div>
                    )}
                  </div>}
                </>
              })}
            </fieldset>
            {!props.onWrapperDataChange && (
              <div className="k-form-buttons">
                <button
                  type={"button"}
                  className="k-button"
                  onClick={null /*unused for now, but put this back in for a nav/button manager => goToNextPage()*/}
                  disabled={
                    Object.keys(fieldBlockingStatuses).length > 0 ||
                    fieldState.hasInValidFields(props.wizardSections[fieldState.page], fieldState.validationMsgs) ||
                    !fieldState.dependencyStatus(props.wizardSections[fieldState.page].nextCondition) ||
                    fieldState.hasRequiredFields(props.wizardSections[fieldState.page], fieldState.values)
                  }
                >
                  NEXT
                </button>
                <button type={"button"} className="k-button" onClick={null /*unused for now, but put this back in for a nav/button manager => t => goPrevPage()*/} disabled={fieldState.page === 0}>
                  BACK
                </button>
                <button
                  type={"submit"}
                  className="k-button"
                  disabled={
                    Object.keys(fieldState.validationMsgs).length > 0 || fieldState.page < props.wizardSections.length - 1
                  }
                >
                  Submit
                </button>
              </div>
            )}
          </FormElement>
        )}
      />
    </div>
  );
};

export default WizardFieldManager;
