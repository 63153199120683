import React, { useEffect, useState } from "react";
import { Dialog } from '@progress/kendo-react-dialogs';
import CloseIcon from "@material-ui/icons/Close";
import WizardFieldController from "../../common/wizard/WizardFieldManager";
import NotificationService from "../../../services/NotificationService";
import JobManager, { JobMessagingConfig } from "../../../JobManager";
import { IJobState } from "../../../store/Jobs";
import { Prompt } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "../common/SupportRequestPopup.scss";
import { IFieldControlButtons, IWizardSection, IWizardRequest, IFieldValueLookup, IWizardSummary, IWizardWarningMessage, IWizardDataInfo, IFieldDataDropdown, FormDataUpdateReason, IApplicationDatabaseFields, ISavableWizard, IFieldManagerFunctions, IWizardTextConfig } from "../../../models/Wizard";
import JobsService from "../../../services/JobService";
import { IApplicationState } from "../../../store";
import { connect } from "react-redux";
import { ISessionState } from "../../../store/Session";
import { DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import { StandardWizard } from "../../common/wizard/StandardWizard";
import DialogBox from "../../common/DialogBox";
import { v4 as uuid } from "uuid";
import WizardStateManager from "../../common/utilities/WizardStateManager";
import JobResultDownloading from "../../job/JobResultDownloading";
import { cloneDeep } from "lodash";
import WizardHelper from "../../common/utilities/WizardHelper";
import { Checkbox } from "@progress/kendo-react-inputs";
import ReactMarkdown from 'react-markdown';
import {  SubTicketTypes } from "../../../models/ServicenowSupportRequestSettings";
import { JobTypeEnum } from "../../../models/Enums";

type Props = IProps & IJobState & ISessionState;

interface IProps {
  match?: any;
  history?: any;
  open: boolean;
  onClose: (isCancel?: boolean) => void;
  jobTypeNumber: number;
  jobStatusInfo?: Record<string, number>;
  wizardSections: Array<IWizardSection>;
  createRequestObject: (lookup: IFieldValueLookup) => IWizardRequest;
  onNextButtonClickWNewJob?: (page: number, request?: IWizardRequest, jobId?: string) => void;
  warningMessage: IWizardWarningMessage;
  wizardSummary: IWizardSummary;
  disableSubmitButton?: boolean;
  disableBackButton?: boolean;
  onSubmitClick?: (jobId?: string) => void;
  jobMessageConfig: JobMessagingConfig;
  onFieldDataChange?: (lookup: IFieldValueLookup, page: number, fieldId?: string) => void;
  createRequestEachPage?: boolean;
  saveForLater?: ISavableWizard;
  confirmSubmitMessage?: IWizardWarningMessage;
  confirmReactivateMessage?: IWizardWarningMessage;
  externallyManagedFields?: IFieldValueLookup;
  wizardTextConfig?: IWizardTextConfig;
}

const SupportRequestPopup = (props: Props) => {
  const [title, setTitle] = useState('');
  const [buttons, setButtons] = useState([] as Array<IFieldControlButtons>);
  const [summaryFields, setSummaryFields] = useState<IWizardRequest | null>(null);
  const [canDownload, setCanDownload] = useState(false);
  const [downloadGenerateCount, setDownloadGenerateCount] = useState(0);
  const [footer, setFooter] = useState(null as JSX.Element);
  const [jobId, setJobId] = useState(null as string);
  const [submittingJob, setSubmittingJob] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [key, setKey] = useState(uuid());
  const [jobManager] = useState(new JobManager());
  const [showPauseMsg, setShowPauseMsg] = useState(false);
  const [jobResponseData, setJobResponseData] = useState(null as IWizardDataInfo);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showReactivateConfirm, setShowReactivateConfirm] = useState(false);
  const [wizardStateManager, setWizardStateManager] = useState(new WizardStateManager({}));
  const [setPage, setFunc_SetPage] = useState<(pageNumber: number, newFieldVals: IFieldValueLookup) => void>(null);
  const [clearAllFieldBlockStatuses, setFunc_clearAllFieldBlockStatuses] = useState<() => void>(null);
  const [checkExistingData, setCheckExistingData] = useState<boolean>(false);
  const [checkRequiredMessage, setCheckRequiredMessage] = useState<boolean>(false);
  const [downloadButtonLabel, setDownloadButtonLabel] = useState(null as string);

  const width = 750;
  const height = 750;

  const getLabel = WizardHelper.getLabel;

  useEffect(() => {
    onFieldManagerWrapperChange(wizardStateManager.values, false);
  }, [checkRequiredMessage, props.disableSubmitButton, props.disableBackButton]);

  useEffect(() => {
    async function getJob() {
      if (props.externallyManagedFields) {
        setCheckExistingData(true);
        wizardStateManager.updateFields(props.externallyManagedFields);
        wizardStateManager.updateResetDepSetAndValidations(props.wizardSections);
      }

      const jobInfo = await JobsService.initializeJob({ jobType: props.jobTypeNumber });

      if (jobInfo.ok) {
        if (jobInfo.data.existingData) {
          setCheckExistingData(true);
          wizardStateManager.updateFields((jobInfo.data.existingData.submitData.valuesAudit as any) as IFieldValueLookup);
          wizardStateManager.updateResetDepSetAndValidations(props.wizardSections);
        }
        setJobId(jobInfo.data.jobId);
      } else {
        // TODO should we just keep on trying to get the job?
        NotificationService.showErrorToast("Failed to create job, we won't be able to submit a request. Try closing this and reopening this popup.");
      }
    }
    if (!jobId && props.open) {
      // freshly opened, so get a job
      getJob();
    } else if (jobId && !props.open) {
      clearFormData();
      setCheckExistingData(false);
    }
  }, [props.open, showWarning]);

  const goToNextPage = function () {
    const newFieldVals = cloneDeep(wizardStateManager.values);

    let pg = wizardStateManager.page + 1;

    for (let i = pg; i < props.wizardSections.length; i++) {
      if (wizardStateManager.dependencyStatus(props.wizardSections[i].dependency, newFieldVals)) {
        pg = i;
        break;
      }

      if (pg <= i && i == props.wizardSections.length - 1) {
        pg = props.wizardSections.length;
      }
    }

    setPage(pg, newFieldVals);

    if (props.onNextButtonClickWNewJob && props.createRequestEachPage) {
      const request = props.createRequestObject(wizardStateManager.values);
      createNewJob();
      props.onNextButtonClickWNewJob(pg, request, jobId);
    }
    else if (props.onNextButtonClickWNewJob) {
      props.onNextButtonClickWNewJob(pg, null, jobId);
    }
  };

  const goPrevPage = function () {
    const newFieldVals = cloneDeep(wizardStateManager.values);

    let pg = wizardStateManager.page - 1;

    for (let i = pg; i >= 0; i--) {
      if (wizardStateManager.dependencyStatus(props.wizardSections[i].dependency, newFieldVals)) {
        pg = i;
        break;
      }
    }

    if (pg < 0) {
      console.error("first page shouldn't have conditions. Taking us to first page");
      pg = 0;
    }

    clearAllFieldBlockStatuses();
    setPage(pg, newFieldVals);
  };

  const pauseWizardJob = function () {
    const { wrequest, auditDataInfo } = wizardStateManager.extractFieldData(props.wizardSections, props.createRequestObject);
    onFormDataUpdate(wrequest, auditDataInfo, wizardStateManager.values, "pause");
  }

  const submitFields = function () {
    const { wrequest, auditDataInfo } = wizardStateManager.extractFieldData(props.wizardSections, props.createRequestObject);

    if (props.confirmSubmitMessage) {
      onConfirmSubmit(wrequest, auditDataInfo, wizardStateManager.values);
    }
    else if (props.confirmReactivateMessage) {
      onConfirmReactivationSubmit(wrequest, auditDataInfo, wizardStateManager.values);
    }
    else {
      onSubmit(wrequest, auditDataInfo, wizardStateManager.values);
    }
  };

  const onFieldManagerWrapperChange = function (valDictionary: IFieldValueLookup, nextBlocked: boolean) {

    const page = wizardStateManager.page;
    const buttons = [];
    const hasSaveForLaterFeature = props.saveForLater && props.saveForLater.enabled(valDictionary, page) || false;

    if (page > 0) {

      if (hasSaveForLaterFeature && page > 0) {
        buttons.push({
          text: "SAVE FOR LATER",
          onClick: pauseWizardJob,
          isAlignLeft: true
        });
      }

      buttons.push({
        text: "BACK",
        disabled: page === 0 || (props.disableBackButton && props.disableBackButton === true),
        onClick: goPrevPage
      });
    }

    if (hasSaveForLaterFeature && checkExistingData && page === 0) {
      buttons.push({
        text: "CLEAR ALL",
        onClick: clearPauseWizardJob,
        isAlignLeft: true
      });
    }

    if (page < props.wizardSections.length) {
      buttons.push({
        text: props.wizardTextConfig && props.wizardTextConfig.initialNextText ? props.wizardTextConfig.initialNextText : page === 0 && props.wizardSections.length > 1 ? "GET STARTED" : "NEXT",
        disabled:
          nextBlocked ||
          wizardStateManager.hasInValidFields(props.wizardSections[page], wizardStateManager.validationMsgs) ||
          !wizardStateManager.dependencyStatus(props.wizardSections[page].nextCondition, valDictionary) ||
          wizardStateManager.hasRequiredFields(props.wizardSections[page], wizardStateManager.values),
        onClick: goToNextPage,
        isPrimary: true
      });
    }

    let footer;

    const isOnSummary = page > props.wizardSections.length - 1;
    let summaryFields = null as IWizardRequest;

    if (isOnSummary) {
      summaryFields = (wizardStateManager.extractFieldData(props.wizardSections, props.createRequestObject)).wrequest;

      let requiredMessage = props.wizardSummary.getConfirmRequiredFields && props.wizardSummary.getConfirmRequiredFields(summaryFields);


      buttons.push({
        text: "SUBMIT",
        title: (props.disableSubmitButton && props.disableSubmitButton === true)
          ? "You cannot submit when using View As to impersonate a user."
          : "",
        disabled:
          Object.keys(wizardStateManager.validationMsgs).length > 0 ||
          (props.disableSubmitButton && props.disableSubmitButton === true) || (requiredMessage ? !checkRequiredMessage : false),
        onClick: submitFields,
        isPrimary: true
      });

      if (requiredMessage) {
        const customMessage = getLabel(requiredMessage, wizardStateManager.values);
        footer = (<div className="authorize-checkbox">
          <Checkbox
            id={"checkReq"}
            defaultChecked={checkRequiredMessage}
            onChange={(e) => {
              setCheckRequiredMessage(e.value);
            }}>
            <label htmlFor={"checkReq"} className={"k-checkbox-label"}>
              <ReactMarkdown
                components={{ p: React.Fragment }}
                children={customMessage} />
            </label>
          </Checkbox>

        </div>);
      }

    }

    let header;

    if (props.wizardSections.length > page) {
      header =
        page > props.wizardSections.length - 1
          ? getLabel(props.wizardSections[1].header, valDictionary)
          : getLabel(props.wizardSections[page].header, valDictionary);
    } else {
      header = getLabel(props.wizardSections[page - 1].header, valDictionary);
    }

    setTitle(header);
    setButtons(buttons);
    setFooter(footer);

    if (summaryFields != null) {
      setDownloadGenerateCount(downloadGenerateCount + 1);
    }

    setCanDownload(isOnSummary && props.wizardSummary.canDownload ? props.wizardSummary.canDownload(summaryFields) : false);
    setDownloadButtonLabel(isOnSummary && props.wizardSummary.downloadButtonLabel ? props.wizardSummary.downloadButtonLabel(summaryFields) : "Download");
    setSummaryFields(summaryFields);
  }

  const onWarnClose = function () {
    setShowWarning(true);
  }

  const clearFormData = function (clearJob: boolean = true) {
    setKey(uuid());

    if (clearJob) {
      setWizardStateManager(new WizardStateManager({}));
      setCheckRequiredMessage(false);
      setJobId(null);
    }
  }

  const managerFunctionsOnLoad = function (funcManager: IFieldManagerFunctions) {
    setFunc_SetPage(() => funcManager.setPage);
    setFunc_clearAllFieldBlockStatuses(() => funcManager.clearAllFieldBlockStatuses);
  }

  const createNewJob = async function () {
    if (!jobId) {
      NotificationService.showErrorToast("Error cannot submit because a job couldn't be created.... trying to create a job");
      const jobInfo = await JobsService.initializeJob({ jobType: props.jobTypeNumber });
      if (jobInfo.ok) {
        setJobId(jobInfo.data.jobId);
      } else {
        NotificationService.showErrorToast("Error cannot submit job");
        return;
      }
    }
  }

  const onFormDataUpdate = function (request: IWizardRequest, auditData: Array<IWizardSection>, vals: IFieldValueLookup, reason: FormDataUpdateReason) {

    if (reason == "previewPdf") {
      // unused for now
    }
    else if (reason == "pause") {
      setJobResponseData({ request: request, auditData: auditData, vals: vals });
      setShowPauseMsg(true);
    }
  }

  const confirmPauseWizardJob = async function () {
    createNewJob();
    const result = await JobsService.updateJobInfo({ jobType: props.jobTypeNumber, jobId: jobId, requestSubmitData: { Fields: jobResponseData.request, FormQuestions: jobResponseData.auditData, ValuesAudit: jobResponseData.vals }, jobStatus: props.jobStatusInfo["pause"] });

    if (result.ok) {
      setShowPauseMsg(false);
      props.onClose(true);
    }

  }

  const onConfirmSubmit = function (request: IWizardRequest, auditData: Array<IWizardSection>, vals: IFieldValueLookup) {
    setJobResponseData({ request: request, auditData: auditData, vals: vals });
    setShowConfirm(true);
  }

  const confirmSubmit = async function () {
    await onSubmit(jobResponseData.request, jobResponseData.auditData, jobResponseData.vals);
    setShowConfirm(false);
  }

  const clearPauseWizardJob = async function (isClose?: boolean) {
    setKey(uuid());
    wizardStateManager.values = {};
    if (isClose) {
      props.onClose(true);
    }
    await JobsService.updateJobInfo({ jobType: props.jobTypeNumber, jobId: jobId, jobStatus: props.jobStatusInfo["clearPause"] });

  }

  const onConfirmReactivationSubmit = function (request: IWizardRequest, auditData: Array<IWizardSection>, vals: IFieldValueLookup) {
    setJobResponseData({ request: request, auditData: auditData, vals: vals });
    setShowReactivateConfirm(true);
  }

  const confirmReactivationSubmit = async function () {
    setShowReactivateConfirm(false);
    props.onClose();
    NotificationService.showInfoToast('Client Setup process has been initiated. We will notify you once complete.', true);
    await onSubmit(jobResponseData.request, jobResponseData.auditData, jobResponseData.vals);
  }

  const onSubmit = async function (request: IWizardRequest, auditData: Array<IWizardSection>, vals: IFieldValueLookup) {
    createNewJob();
    let id = jobId;

    setSubmittingJob(true);

    const data = { Fields: request, FormQuestions: auditData, ValuesAudit: vals };

    const result = await JobsService.submitJob({ jobType: props.jobTypeNumber, jobId: id, requestSubmitData: data, SubmitData: request });

    if (!result.ok) {
      NotificationService.showErrorToast(props.jobMessageConfig.genericErrorMessage);
    } else {
      if (props.jobTypeNumber != JobTypeEnum.CreateWorkRequest && props.jobTypeNumber != JobTypeEnum.CreateTicket && props.onSubmitClick) {
        props.onSubmitClick(id);
      } else {
        if (props.onSubmitClick) {
          props.onSubmitClick();
        }
        jobManager.addJobId(id, props.jobMessageConfig);
      }

      props.onClose();
      clearFormData(false);
    }
    setSubmittingJob(false);
  }

  const getSections = function (secions: Array<IWizardSection>) {
    return secions.forEach(s => {
      s.fields.forEach(f => {
        if (f.type == "dropdown") {
          const ddField = f as IFieldDataDropdown;
          ddField.DropDownOptions = ddField.DropDownOptions && ddField.DropDownOptions.filter(dd => {
            if (dd.permission && !props.sessionData.permissions.has(dd.permission)) {
              return false;
            } else {
              return true;
            }
          });
        }
      })
    })
  }

  const getFileNameSuffix = () => {

    if (summaryFields) {
      const projectName = summaryFields["projectName"] ? summaryFields["projectName"] : "";
      if (summaryFields["hasILMProjectClosure"]) {
        return `Close Project Request - ${projectName}`;
      }
      else if (summaryFields["hasILMPartialProjectDeletion"]) {
        const dbFieldsArray = summaryFields["applicationDatabaseAssignment"] ? summaryFields["applicationDatabaseAssignment"] as Array<IApplicationDatabaseFields> : [];
        const appDb = dbFieldsArray && dbFieldsArray.length > 0 ? dbFieldsArray[0].databaseName : "";
        return `Delete Database - ${projectName} - ${appDb}`;
      }
      else if (summaryFields["hasILMMediaDeletion"]) {
        return `Delete Media - ${projectName}`;
      }
      else if (summaryFields["RequestTypeCode"] === SubTicketTypes.AddUserRelativityAccess || summaryFields["RequestTypeCode"] === SubTicketTypes.RemoveUserRelativityAccess || summaryFields["RequestTypeCode"] === SubTicketTypes.AddUserEpiqAccess) {
        return `${summaryFields["RequestTypeName"]} - ${(summaryFields["ProjectName"]).toString().replace(/[^a-zA-Z0-9_-]+/g, " ")} ${summaryFields["ProjectNumber"] ? "(" + summaryFields["ProjectNumber"] + ")" : ""}`;
      }
      else if (summaryFields["hasEasyUpload"]) {
        return `Use Easy Upload - ${projectName}`
      }
      else
        return "";
    }
    return "";
  }

  return (
    <>
      <Prompt when={!submittingJob && props.open} message={(nextLoc) => {
        if (!submittingJob && props.open) {
          setShowWarning(true);
          return false;
        }
        return true;
      }} />
      {<div className="warning"><DialogBox
        title={props.warningMessage.title}
        content={props.warningMessage.description}
        visible={showWarning}
        diagCloseEvent={() => setShowWarning(false)}
        toggleDialogEvent={event => {
          if (event.currentTarget.textContent === "YES") {

            if (props.jobStatusInfo && wizardStateManager.values != null && Object.keys(wizardStateManager.values).length > 0) {
              clearPauseWizardJob(true);
            }
            else {
              props.onClose(true);
              clearFormData();
            }
          }
          setShowWarning(false);
        }}
        okButtonLabelText={"YES"}
        cancelButtonLabelText={"NO"}
      /></div>}
      {props.saveForLater && props.saveForLater.enabled && <div className="warning"><DialogBox
        title={props.saveForLater.message.title}
        content={props.saveForLater.message.description}
        visible={showPauseMsg}
        diagCloseEvent={() => setShowPauseMsg(false)}
        toggleDialogEvent={event => {

          if (event.currentTarget.textContent === "SAVE") {
            confirmPauseWizardJob();
          }

          if (event.currentTarget.textContent === "CANCEL") {
            setShowPauseMsg(false);
          }

        }}
        okButtonLabelText={"SAVE"}
        cancelButtonLabelText={"CANCEL"}
      /></div>}
      {props.confirmReactivateMessage && jobResponseData && <div className="warning"><DialogBox
        title={props.confirmReactivateMessage.title}
        content={props.confirmReactivateMessage.description}
        visible={showReactivateConfirm}
        diagCloseEvent={() => setShowPauseMsg(false)}
        toggleDialogEvent={event => {

          if (event.currentTarget.textContent === "REACTIVATE") {
            confirmReactivationSubmit();
          }

          if (event.currentTarget.textContent === "CANCEL") {
            setShowReactivateConfirm(false);
          }

        }}
        okButtonLabelText={"CANCEL"}
        cancelButtonLabelText={"REACTIVATE"}
      /></div>}
      {props.confirmSubmitMessage && jobResponseData && <div className="warning"><DialogBox
        title={props.confirmSubmitMessage.title || props.confirmSubmitMessage.getTitle(jobResponseData.vals)}
        content={props.confirmSubmitMessage.getDescription(jobResponseData.vals)}
        visible={showConfirm}
        dailogInfo={"confirm-dailog"}
        diagCloseEvent={() => setShowConfirm(false)}
        toggleDialogEvent={event => {

          if (event.currentTarget.textContent === "CONFIRM") {
            confirmSubmit();
          }

          if (event.currentTarget.textContent === "BACK") {
            setShowConfirm(false);
          }

        }}
        okButtonLabelText={"BACK"}
        cancelButtonLabelText={"CONFIRM"}
      /></div>}
      {props.open && <div className="support-popup">
        <Dialog aria-labelledby="customized-dialog-title" onClose={onWarnClose} width={width} height={height} closeIcon={true}>
          <DialogTitle className="modal-title">{title}
            {canDownload &&
              <JobResultDownloading
                jobId={jobId}
                jobType={props.jobTypeNumber}
                payload={{ fields: summaryFields, jobId }}
                generateCount={downloadGenerateCount}
                fileNamePrefix={""}
                fileNameSuffix={getFileNameSuffix()}
                approvalChecked={checkRequiredMessage}
                fileExtension={props.jobTypeNumber == JobTypeEnum.CreateTicket ? ".xls" : ".pdf"}
                downloadButtonLabel={downloadButtonLabel}
                toolTipTitle={props.jobTypeNumber == JobTypeEnum.CreateTicket ? "Review bulk import spreadsheet" : ""}
              />
            }
            <Tooltip
              title={props.jobMessageConfig ? props.jobMessageConfig.cancelMessage : null}
              placement="top-start"
              arrow
              disableFocusListener={!props.jobMessageConfig || !props.jobMessageConfig.cancelMessage}
              disableHoverListener={!props.jobMessageConfig || !props.jobMessageConfig.cancelMessage}
              disableTouchListener={!props.jobMessageConfig || !props.jobMessageConfig.cancelMessage}
            >
              <IconButton
                className="modal-close"
                aria-label="close"
                disabled={false}
                onClick={onWarnClose}
              ><CloseIcon /></IconButton>
            </Tooltip>
          </DialogTitle>
          <DialogContent>
            <StandardWizard
              buttonDefinitions={buttons}
              hideStepIndicator={true}
              footerContent={footer}>
              <WizardFieldController wizardSections={getSections(props.wizardSections)}
                existingData={wizardStateManager}
                onWrapperDataChange={onFieldManagerWrapperChange}
                maxWidth={width}
                jobTypeNumber={props.jobTypeNumber}
                key={key}
                seedKey={key}
                jobId={jobId}
                debugMode={props.sessionData.permissions.has("EpiqOnlyDebugFeatures")}
                disableSubmitButton={props.disableSubmitButton}
                disableBackButton={props.disableBackButton}
                onFieldDataChange={props.onFieldDataChange ? props.onFieldDataChange : null}
                createRequestEachPage={props.createRequestEachPage}
                managerFunctionsOnLoad={managerFunctionsOnLoad}
                permissions={props.sessionData.permissions}
                {...props}
              />
            </StandardWizard>
          </DialogContent>
        </Dialog>
      </div>}
    </>
  );
}

export default connect((state: IApplicationState, ownProps: IProps) => { return { ...ownProps, ...state.jobState, ...state.sessionState } }, null)(withRouter(SupportRequestPopup as any));
