import * as React from "react";
import JobList, { IAdminJobQueueOptions } from "../../common/Job/JobList";

interface IJobQueueProps {

}

type Props = IJobQueueProps;

const JobQueue = (props: Props) => {
  const adminJobQueueOptions: IAdminJobQueueOptions = { includeSystemGeneratedJobs: false };

  return (
    <>
      <JobList adminJobQueueOptions={adminJobQueueOptions}></JobList>
    </>
  );
};

export default JobQueue;
