import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";

export default class FilterHelper {
    static getModifiedCorrectDateFilter = (
        gridFilters: Array<CompositeFilterDescriptor>,
        dateColumnName: string = ""
    ) => {
        // This function is used for date column filter in all advance grid pages.
        let isDateFilterExists = false;
        let isDateFilterCleared = false;
        let dateColumnValue: Date;
        const filters = gridFilters
        ? gridFilters.map(compositeFilter => {
            const dateColumnFilter: FilterDescriptor = compositeFilter.filters.find(
            filter => (filter as FilterDescriptor).field === dateColumnName
            ) as FilterDescriptor;

            if (dateColumnFilter) {
            const dateFilterValue = dateColumnFilter.value;
            
            isDateFilterCleared = typeof dateFilterValue === "string" && (dateFilterValue as String).length === 0;

            if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
                dateColumnFilter.operator = 'eq';
            }

            dateColumnValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((dateColumnFilter.operator === 'isnull' || dateColumnFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

            if (dateColumnFilter.operator === 'isnull' || dateColumnFilter.operator === 'isnotnull') {
                return {
                ...compositeFilter,
                filters: [
                    {
                    ...dateColumnFilter,
                    field: dateColumnName,
                    operator: typeof dateFilterValue === "string" && dateColumnFilter.operator === 'isnotnull' ? "eq" : dateColumnFilter.operator,
                    value: dateColumnValue
                    }
                ]
                };
            }

            isDateFilterExists = true;

            return {
                ...compositeFilter,
                filters: [
                {
                    ...dateColumnFilter,
                    field: dateColumnName,
                    operator: "gte",
                    value: new Date(dateColumnValue.toUTCString())
                }
                ]
            };
            }
            return compositeFilter;
        })
        : [];

        if (!isDateFilterCleared && isDateFilterExists) {
            filters.push({
                logic: "and",
                filters: [
                {
                    field: dateColumnName,
                    operator: "lt",
                    value: new Date(new Date(dateColumnValue.setUTCDate(dateColumnValue.getUTCDate() + 1)).toUTCString())
                }
                ]
            });
        }

        return filters;
    };
}