import React, { useState, useRef } from "react";
import SupportService, { ISupportTicket } from "../../../services/SupportService";
import { Button } from "@progress/kendo-react-buttons";
import { GridCellProps, GridColumnProps, GridColumnResizeEvent } from "@progress/kendo-react-grid";
import { IGridParams, AdvancedGrid } from "../../common/grid/AdvancedGrid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { FilterDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { NavLink } from "react-router-dom";
import GridRefreshButton from "../../common/GridRefreshButton";
import "./RequestGrid.scss";
import SupportRequestPopup from "../pulse/SupportRequestPopup";
import RequestDetails from "./RequestDetails";
import { TrackCustomEvent } from '../../common/wizard/WizardEventTracker';
import { IFieldValueLookup, ISavableWizard, IWizardRequest, IWizardSection, IWizardSummary, IWizardTracerSettings, IWizardWarningMessage } from "../../../models/Wizard";
import { eventNames, createTrackRequest } from "../../../models/SupportRequestTrackerSettings";
import { JobMessagingConfig } from "../../../JobManager";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import RequestStatuses from "./RequestStatuses";
import { FilterDropdownData, GridPreferences, GridUserPreferences } from "../../../models/Preferences";
import { ColumnFromField, setSavedColumnsToGrid, setDefaultColumnsToGrid, getFields, saveColumnWidthPreferenceInDB } from "../../common/grid/columns/ColumnHelper";
import { IGridPreference } from "../../../models/Preferences";
import * as SessionStore from "../../../store/Session";
import { ColumnFieldListBox } from "../../common/grid/columns/ColumnFieldListBox";
import SettingsIcon from "@material-ui/icons/Settings";

export enum RequestType {
  Pulse,
  ServiceNow,
}

export enum GridUserPrefColumns {
  Pulse = 0,
  ServiceNow = 1,
  Both = 2,
  EpiqOnly = 3
}

export enum ServiceNowDataSource {
  MYREQUESTS = "MYREQUESTS",
  WATCHING = "WATCHING",
  PROJECTREQS = "PROJECTREQS",
  UNKNOWN = "UNKNOWN"
}

interface IDataSource {
  value: string;
  key: string;
  statuses: Array<IStatus>;
  className: string;
}

interface IRefreshGridParams extends IGridParams {
  refreshToggle: boolean;
}

interface ICreateRequestButtonConfig {
  label: string;
  route: string;
}

interface IDataSourcesSelector {
  values: Record<string, IDataSource>;
}

interface IRequestTypeConfig {
  totalRecordsLabel: string | IDataSourcesSelector;
  createButtonConfig?: ICreateRequestButtonConfig;
  noRecordsMessage: string;
  header?: string;
  description?: string;
}

interface IRequestType {
  config: IRequestTypeConfig;
}

interface IWizardPops {
  workRequestSteps: Array<IWizardSection>,
  createWorkRequest(valueDictionary: IFieldValueLookup): IWizardRequest,
  wizardSummary: IWizardSummary,
  wizardWarningMessage: IWizardWarningMessage,
  jobMessagingConfig: JobMessagingConfig,
  wizardSaveForLatter?: ISavableWizard,
  wizardConfirmSubmitMessage?: IWizardWarningMessage,
  jobTypeNumber: number
}

interface IProps extends IWizardPops {
  history: any;
  showCreateRequestButton?: boolean;
  showRequestList?: boolean;
  requestType: RequestType;
  disableSubmitButton?: boolean;
}

export interface IStatus {
  status: string;
  filter: FilterDescriptor;
  title: string;
  count: number;
}

type Props = IProps & SessionStore.ISessionState;

const statusFilter: FilterDescriptor = { field: "status", operator: "eq" };

const statuses = new Array<IStatus>(
  {
    title: "Open",
    status: "Open",
    filter: {
      ...statusFilter,
      operator: "neq",
      value: "Complete"
    },
    count: 0
  },
  {
    title: "Awaiting Input",
    status: "Awaiting Input",
    filter: {
      ...statusFilter,
      value: "Awaiting Input"
    },
    count: 0
  },
  {
    title: "New",
    status: "New",
    filter: {
      ...statusFilter,
      value: "New"
    },
    count: 0
  },
  {
    title: "In Progress",
    status: "Work in Progress",
    filter: {
      ...statusFilter,
      value: "Work in Progress"
    },
    count: 0
  },
  {
    title: "Complete",
    status: "Complete",
    filter: {
      ...statusFilter,
      value: "Complete"
    },
    count: 0
  }
);

const requestTypes: Record<string, IRequestType> = {
  [RequestType.Pulse]: {
    config: {
      totalRecordsLabel: {
        values: {
          [ServiceNowDataSource.PROJECTREQS]: { value: "Team Requests", key: ServiceNowDataSource.PROJECTREQS, statuses: statuses, className: "pulse-team-requests-grid" },
          [ServiceNowDataSource.MYREQUESTS]: { value: "My Requests", key: ServiceNowDataSource.MYREQUESTS, statuses: statuses, className: "pulse-my-requests-grid" }
        }
      },
      createButtonConfig: {
        label: "REQUEST",
        route: "/support/createworkrequest"
      },
      noRecordsMessage: "No work requests to display.",
      header: "Work Requests",
      description: "View work requests you or your team submitted."
    }
  },
  [RequestType.ServiceNow]: {
    config: {
      totalRecordsLabel: {
        values: {
          [ServiceNowDataSource.WATCHING]: { value: "Followed Tickets", key: ServiceNowDataSource.WATCHING, statuses: statuses, className: "servicenow-followed-tickets-grid" },
          [ServiceNowDataSource.MYREQUESTS]: { value: "My Tickets", key: ServiceNowDataSource.MYREQUESTS, statuses: statuses, className: "servicenow-my-tickets-grid" }
        }

      },
      createButtonConfig: {
        label: "TICKET",
        route: "/support/createRequest"
      },
      noRecordsMessage: "No technical support tickets to display.",
      header: "Technical Support Tickets",
      description: "View technical support tickets you submitted or follow."
    }
  }
};

const initialState: IRefreshGridParams = {
  skip: 0,
  take: 100,
  sort: [{ field: "status", dir: "asc" }, { field: "submitted", dir: "desc" }],
  filter: { logic: "and", filters: [{ field: "status", operator: "eq", value: statuses[0] }] },
  refreshToggle: false
};

const JobStatusInfo: Record<string, number> = {
  "pause": 95,
  "pdfpreview": 104,
  "clearPause": 800
}

const preferenceType = "SupportTicketListGridPreferences";
const tableKey = "SupportTicketList";

const RequestGrid = (props: Props) => {
  const workRequestRestrictions = props.requestType === RequestType.Pulse;
  const defaultColWidth = !workRequestRestrictions ? 200 : null

  const SupportTicketMasterFields = {
    [tableKey]: [{ field: "incidentId", colWidth: defaultColWidth, requestType: GridUserPrefColumns.Both },
    { field: "project", colWidth: defaultColWidth, requestType: GridUserPrefColumns.Both },
    { field: "supportTicketType", colWidth: defaultColWidth, requestType: GridUserPrefColumns.Both },
    { field: "shortDescription", colWidth: defaultColWidth, requestType: GridUserPrefColumns.Both },
    { field: "createdBy", colWidth: defaultColWidth, requestType: ServiceNowDataSource.PROJECTREQS },
    { field: "submitted", colWidth: defaultColWidth, requestType: GridUserPrefColumns.Both },
    { field: "status", colWidth: defaultColWidth, requestType: GridUserPrefColumns.Both },
    { field: "externalWatchListAsArray", colWidth: defaultColWidth, requestType: GridUserPrefColumns.EpiqOnly },
    { field: "sapBillToClient", colWidth: defaultColWidth, requestType: GridUserPrefColumns.EpiqOnly }]

  } as Record<string, Array<GridPreferences>>;

  const gridUserPreferences = {
    ["incidentId"]: { field: "incidentId", type: "text", displayName: workRequestRestrictions ? "Request #" : "Ticket #", isDefault: true, isMandatory: true },
    ["project"]: { field: "project", type: "text", displayName: "Project #", isDefault: true },
    ["supportTicketType"]: { field: "supportTicketType", type: "DropDown", displayName: "Type", filterDropdownData: !workRequestRestrictions ? [{ name: "Account Administration", id: "Account Admin", operator: "eq" }, { name: "Technical Support", id: "Application,Corp It", operator: "overridein" }] : [], isDefault: true },
    ["shortDescription"]: { field: "shortDescription", type: "text", displayName: "Description", isDefault: true },
    ["submitted"]: { field: "submitted", type: "DateTime", displayName: "Submitted", isDefault: true },
    ["createdBy"]: { field: "createdBy", type: "text", displayName: "Created By", isDefault: true },
    ["status"]: { field: "status", type: "text", displayName: "Status", isDefault: true, isMandatory: true },
    ["externalWatchListAsArray"]: { field: "externalWatchListAsArray", type: "text", displayName: "External Watchlist" },
    ["sapBillToClient"]: { field: "sapBillToClient", type: "text", displayName: "Client Name" },
  } as Record<string, GridUserPreferences>

  const source = props.history.location.state && props.history.location.state.source ? props.history.location.state.source : null;
  const prevGridState: IRefreshGridParams = props.history.location.state && props.history.location.state.dataState ? props.history.location.state.dataState : null;
  const prevTabStatus: IStatus = props.history.location.state && props.history.location.state.selectedStatus ? props.history.location.state.selectedStatus : null;

  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = React.useState(0);
  const [currentFieldId, setCurrentFieldId] = React.useState(null as string);
  const [fieldValues, setFieldValues] = React.useState(null as IFieldValueLookup);
  const [supportWizardOpen, setSupportWizardOpen] = useState(false);
  const [supportDetails, setSupportDetails] = useState(null as ISupportTicket);
  const [serviceRequestData, setServiceRequestData] = useState<Array<ISupportTicket>>(new Array<ISupportTicket>());
  const [dataState, setDataState] = useState(prevGridState && prevGridState.filters ? prevGridState : initialState);
  const [serviceNowDataSource, setServiceNowDataSource] = useState(source ? source : (props.requestType === RequestType.Pulse ? ServiceNowDataSource.PROJECTREQS : ServiceNowDataSource.WATCHING));
  const [selectedStatus, setSelectedStatus] = useState<IStatus>(prevTabStatus && prevTabStatus.filter ? prevTabStatus : statuses[0]);
  const [selectedTab, setSelectedTab] = useState(source && source == ServiceNowDataSource.MYREQUESTS ? 1 : 0);
  const [refresh, setRefresh] = useState(false);
  const [prevDataState, setPrevDataState] = useState(prevGridState && prevGridState.filters ? prevGridState : null);
  const [prevSelectedTabStatus, setPrevSelectedTabStatus] = useState(prevTabStatus && prevTabStatus.filter ? prevTabStatus : null);
  const [availableFields, setAvailableFields] = useState(new Array<GridUserPreferences>());
  const [selectedFields, setSelectedFields] = useState(new Array<GridUserPreferences>());
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const gridRef = useRef<AdvancedGrid<ISupportTicket, IRefreshGridParams>>(null);

  const getDynamicColumnsToGrid = (): Array<GridColumnProps> => {
    const savedGridColumns = props.sessionData.userPrefs.SupportTicketListGridPreferences as IGridPreference;
    if (props.requestType === RequestType.ServiceNow && savedGridColumns && savedGridColumns[tableKey]) {
      return setSavedColumnsToGrid(savedGridColumns[tableKey], gridUserPreferences, setColumnProperties, defaultColWidth);

    }
    else {
      const fields = SupportTicketMasterFields[tableKey].filter(x => (x.requestType === GridUserPrefColumns.Both || x.requestType === props.requestType || x.requestType === serviceNowDataSource || (props.sessionData.employerId === 1 ? x.requestType === GridUserPrefColumns.EpiqOnly : null)));
      return setDefaultColumnsToGrid(fields, gridUserPreferences, setColumnProperties);
    }
  }

  const setColumnProperties = (field: GridUserPreferences) => {
    const col = ColumnFromField(field);

    if (col.field.toLowerCase() === "incidentid")
      col.cell = workRequestRestrictions ? pulseWRNumberCell : requestNumberCell;
    else if (col.field.toLowerCase() === "project")
      col.cell = projectNumberCell;
    else if (col.field.toLowerCase() === "supporttickettype")
      col.cell = requestTypeCell;
    else if (col.field.toLowerCase() === "shortdescription") {
      col.cell = descriptionCell;
      col.filterable = props.requestType === RequestType.ServiceNow;
      col.sortable = false;
      col.headerClassName = "no-sort";
    }
    else if (col.field.toLowerCase() === "submitted") {
      col.cell = submittedCell;
      col.filterable = props.requestType === RequestType.ServiceNow;
    }
    else if (col.field.toLowerCase() === "createdby") {
      col.cell = createdByCell;
      col.sortable = false;
      col.headerClassName = "no-sort";
    }
    else if (col.field.toLowerCase() === "status") {
      col.cell = statusCell;
      col.filterable = false;
    }
    else if (col.field.toLowerCase() === "externalwatchlistasarray") {
      col.filterable = false;
      col.cell = externalWatchListCell;
    }
    else if (col.field.toLowerCase() === "sapbilltoclient") {
      col.filterable = true;
    }

    return col;
  }

  const handleShowSettingDialog = () => {
    const savedGridColumns = props.sessionData.userPrefs.SupportTicketListGridPreferences as IGridPreference;
    const masterFields = SupportTicketMasterFields[tableKey] as Array<GridPreferences>;
    const fields = getFields(savedGridColumns ? savedGridColumns[tableKey] : null, masterFields.filter(x => (x.requestType === GridUserPrefColumns.Both || x.requestType === props.requestType || x.requestType === serviceNowDataSource || (props.sessionData.employerId === 1 ? x.requestType === GridUserPrefColumns.EpiqOnly : null))), gridUserPreferences);

    setShowSettingsDialog(true);
    setAvailableFields(fields.availableFields);
    setSelectedFields(fields.selectedFields);
  }

  const handleCloseSettingDialog = (reload: boolean) => {
    setShowSettingsDialog(false);
    setLoading(reload);
    if (reload) {
      setTimeout(() => {
        const filter = { logic: "and", filters: [{ field: "status", operator: "eq", value: statuses[0] }] } as CompositeFilterDescriptor;
        const newState: IRefreshGridParams = {
          skip: 0,
          take: 500,
          sort: [{ field: "status", dir: "asc" }, { field: "submitted", dir: "desc" }],
          filter: filter,
          filters: [filter] as CompositeFilterDescriptor[],
          refreshToggle: false
        };  //reset the grid data

        gridRef.current.resetGridState(newState);
        setDataState(newState);
        setPrevDataState(null);
        setPrevSelectedTabStatus(null);
        setRefresh(!refresh);
        setLoading(false);
      }, 200);
    }
  }

  const handleColumnResize = (event: GridColumnResizeEvent) => {
    if (props.requestType === RequestType.ServiceNow) {
      if (event.end) {//Indicates that resizing is complete and the user has dropped the resize handler.
        const field = event.columns[event.index].field;
        const newWidth = event.columns[event.index].width;
        const savedGridColumns = props.sessionData.userPrefs.SupportTicketListGridPreferences as IGridPreference;
        const masterFields = SupportTicketMasterFields[tableKey].filter(x => (x.requestType === GridUserPrefColumns.Both || x.requestType === props.requestType || (props.sessionData.employerId === 1 ? x.requestType === GridUserPrefColumns.EpiqOnly : null))) as Array<GridPreferences>;
        const gridPref = ((savedGridColumns && savedGridColumns[tableKey]) ? savedGridColumns[tableKey] : masterFields.filter(x => (gridUserPreferences[x.field] && gridUserPreferences[x.field].isDefault === true)) as Array<GridPreferences>);
        saveColumnWidthPreferenceInDB(tableKey, preferenceType, gridPref, field, newWidth);
      }
    }
  }

  const fetchServiceRequestGridData = async (dataState: IRefreshGridParams) => {
    let gridDataState = prevDataState ? prevDataState : dataState;
    setLoading(true);
    let filters = gridDataState.filters;

    if (gridDataState.filters) {
      let submittedByFilter = false;
      let submittedDateValue: Date;

      filters = gridDataState.filters.map(compositeFilter => {
        const statusesFilter: CompositeFilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "status"
        ) as CompositeFilterDescriptor;
        const submittedFilter: CompositeFilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "submitted"
        ) as CompositeFilterDescriptor;
        const createdByFilter: CompositeFilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "createdBy"
        ) as CompositeFilterDescriptor;
        const ticketTypeFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "supportTicketType"
        ) as FilterDescriptor;
        const externalWatchFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === "externalWatchListAsArray"
        ) as FilterDescriptor;

        if (statusesFilter) {
          const selectedFilter = prevSelectedTabStatus && prevSelectedTabStatus.filter ? prevSelectedTabStatus : selectedStatus
          return {
            ...compositeFilter,
            filters: [{ ...statusesFilter, ...selectedFilter.filter }]
          };
        }

        if (submittedFilter) {
          const submittedFilterValue = (compositeFilter.filters[0] as FilterDescriptor).value;
          let submittedFilterOperator = (compositeFilter.filters[0] as FilterDescriptor).operator;

          if (!isNaN(new Date(submittedFilterValue).getFullYear()) && new Date(submittedFilterValue).getFullYear() > 1970) {
            submittedFilterOperator = 'eq';
          }

          submittedDateValue =
            typeof submittedFilterValue === "string" ? new Date("01/01/1901") : (submittedFilterOperator === "isnull" || submittedFilterOperator === "isnotnull" ? null : new Date(submittedFilterValue));

          if (submittedFilterOperator === "isnull" || submittedFilterOperator === "isnotnull") {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...submittedFilter,
                  field: "submitted",
                  operator: typeof submittedFilterValue === "string" && submittedFilterOperator === 'isnotnull' ? "eq" : submittedFilterOperator,
                  value: submittedDateValue
                }
              ]
            };
          }

          submittedByFilter = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...submittedFilter,
                field: "submitted",
                operator: "gte",
                value: new Date(submittedDateValue.toUTCString())
              }
            ]
          };
        }

        if (createdByFilter) {
          return {
            ...compositeFilter,
            logic: "or",
            filters: [
              {
                field: "createdByExternalEmail",
                operator: 'contains',
                value: (compositeFilter.filters[0] as FilterDescriptor).value
              },
              {
                field: "createdByInternalEmail",
                operator: 'contains',
                value: (compositeFilter.filters[0] as FilterDescriptor).value
              }
            ]
          }
        }

        if (ticketTypeFilter && props.requestType === RequestType.ServiceNow) {
          const filterSelectedValue = ticketTypeFilter.value && ticketTypeFilter.value.id;

          if (filterSelectedValue) {
            return {
              ...compositeFilter,
              filters: [
                {
                  field: "supportTicketType",
                  operator: ticketTypeFilter.value.operator,
                  value: filterSelectedValue
                }
              ]
            }

          } else {
            let index = compositeFilter.filters.findIndex((p) => p === ticketTypeFilter);
            if (index >= 0) {
              compositeFilter.filters.splice(index, 1);
              return { ...compositeFilter };
            }
          }
        }

        if (externalWatchFilter) {
          return {
            ...compositeFilter,
            filters: [
              {
                field: "externalWatchList",
                operator: "contains",
                value: (compositeFilter.filters[0] as FilterDescriptor).value
              }
            ]
          }
        }

        return compositeFilter;
      });

      if (submittedByFilter) {
        filters.push({
          logic: "and",
          filters: [
            {
              field: "submitted",
              operator: "lt",
              value: new Date(
                new Date(submittedDateValue.setUTCDate(submittedDateValue.getUTCDate() + 1)).toUTCString()
              )
            }
          ]
        });
      }
    }

    const result = await SupportService.getUserTickets(
      { ...dataState, filters: filters },
      props.requestType === RequestType.Pulse,
      props.requestType === RequestType.ServiceNow && serviceNowDataSource === ServiceNowDataSource.WATCHING,
      props.requestType === RequestType.Pulse && serviceNowDataSource === ServiceNowDataSource.PROJECTREQS
    );

    if (result.ok) {
      setServiceRequestData(result.data.results);
    } else {
      setServiceRequestData(null);
      setHasError(true);
    }

    setDataState(gridDataState);
    setPrevDataState(null);
    setPrevSelectedTabStatus(null);
    setRefresh(!refresh);
    setLoading(false);
  };

  const requestNumberCell = (props: GridCellProps) => {
    const requestNumber = props.dataItem.incidentId;

    return (
      <td>
        <div className="request-number">
          {" "}
          <NavLink to={{ pathname: `/support/servicerequest/${requestNumber}`, state: { source: serviceNowDataSource, dataState: dataState, selectedStatus: selectedStatus } }}>
            <em title={requestNumber}>{requestNumber}</em>
          </NavLink>
        </div>
      </td>
    );
  };

  const pulseWRNumberCell = (props: GridCellProps) => {
    const requestNumber = props.dataItem.incidentId;
    const noSummary = !props.dataItem.createdByExternalEmail;

    if (noSummary) {
      return <td>{requestNumber}</td>
    }

    return (
      <td>
        <div className="request-number">
          {" "}
          <span className="popup-detail-link" onClick={() => setSupportDetails(props.dataItem)} >{requestNumber}</span>
        </div>
      </td>
    );
  };

  const projectNumberCell = (props: GridCellProps) => {
    const projectNumber = props.dataItem.project;

    return <td>{projectNumber}</td>;
  };

  const requestTypeCell = (props: GridCellProps) => {
    const requestType = props.dataItem.supportTicketType;

    return <td>{requestType}</td>;
  };

  const descriptionCell = (props: GridCellProps) => {
    const shortDescription = props.dataItem.shortDescription;

    return (
      <td className="description">
        <div className="description-wrapper">
          <em title={shortDescription}>{shortDescription}</em>
        </div>
      </td>
    );
  };

  const submittedCell = (props: GridCellProps) => {
    const dataItem: ISupportTicket = props.dataItem;
    return (
      <td>
        <div className="submitted-date">
          <div>{dataItem.submitted.localDateString}</div>
          <div className="submitted-time">{dataItem.submitted ? dataItem.submitted.localTimeString : ""}</div>
        </div>
      </td>
    );
  };

  const statusCell = (props: GridCellProps) => {
    const status = props.dataItem.status as string;
    const statusClass = `status-circle ${status
      .toLowerCase()
      .trim()
      .replace(/\s+|[,\/]/g, "-")}`;

    return (
      <td>
        <div className="status">
          <div className={statusClass} />
          <div className="status-label">{status}</div>
        </div>
      </td>
    );
  };

  const createdByCell = (props: GridCellProps) => {
    const createdBy = props.dataItem.createdByExternalEmail ? props.dataItem.createdByExternalEmail : props.dataItem.createdByInternalEmail;

    return (
      <td>{createdBy}</td>
    )
  }

  const externalWatchListCell = (props: GridCellProps) => {
    const externalWatchListAsArray = props.dataItem.externalWatchListAsArray;

    return (
      <td className="description">
        <div className="description-wrapper">
          <em title={externalWatchListAsArray}>{externalWatchListAsArray}</em>
        </div>
      </td>
    );
  };

  const filterElements = (element: any) => {
    if (element.tagName === "EM") {
      return true;
    }

    return false;
  };

  const reloadData = (refreshStatuses: boolean) => {
    const newState: IRefreshGridParams = { ...dataState, refreshToggle: !dataState.refreshToggle };

    gridRef.current.resetGridState(newState);
    setDataState(newState);

    if (refreshStatuses) {
      setRefresh(!refresh);
    }
  };
  const onFieldDataChange = function (valDictionary: IFieldValueLookup, pageNum: number, fieldId?: string) {
    setFieldValues(valDictionary);
    setPageNum(pageNum);

    if (fieldId) {
      setCurrentFieldId(fieldId);
    }

  }

  const trackCustomEvent = (key: string) => {
    const wizardTrackerSetting = {} as IWizardTracerSettings<IFieldValueLookup>;
    wizardTrackerSetting.eventNames = eventNames;
    wizardTrackerSetting.createTrackRequest = createTrackRequest;

    if (fieldValues != null) {
      wizardTrackerSetting.valDictionary = fieldValues;
    }

    if (key == "Cancel") {
      wizardTrackerSetting.stepField = `${pageNum + (currentFieldId ? ' - ' + currentFieldId : '')}`;
    }
    TrackCustomEvent(key, wizardTrackerSetting);
  }

  const createNewRequestButtonRender = () => {
    return (
      <>
        <GridRefreshButton onRefreshGridClick={() => reloadData(true)} />
        {props.showCreateRequestButton && (
          <Button
            className="add-request-btn"
            icon={"plus"}
            primary={true}
            onClick={() => {
              if (props.requestType === RequestType.Pulse || props.requestType === RequestType.ServiceNow) {
                setSupportWizardOpen(true);
                trackCustomEvent("New");
                return;
              }
              props.history.push(requestTypes[props.requestType].config.createButtonConfig.route);
            }}
          >
            {requestTypes[props.requestType].config.createButtonConfig.label}
          </Button>
        )}
        {props.requestType === RequestType.ServiceNow && (
          <Button className="service-col-setting" onClick={handleShowSettingDialog}>
            <SettingsIcon />
          </Button>
        )}
      </>
    );
  };

  const columns = () => {
    const gridCols = getDynamicColumnsToGrid();
    return gridCols;
  }

  const ticketsGrid = (record: IDataSource) => {
    return (
      <Tooltip openDelay={2} position="right" filter={filterElements}>
        <AdvancedGrid
          ref={(standardGridInstance: AdvancedGrid<ISupportTicket, IRefreshGridParams>) => {
            gridRef.current = standardGridInstance;
          }}
          showErrorState={hasError}
          className={`support-request-grid ${record.className}`}
          showLoadingIndicator={loading}
          sortable={{ mode: "multiple" }}
          data={props.showRequestList && serviceRequestData}
          dataFetch={fetchServiceRequestGridData}
          dataState={dataState}
          columns={columns()}
          paging={false}
          noRecordsRender={<p>{requestTypes[props.requestType].config.noRecordsMessage}</p>}
          noLoadOnMount={false}
          filteredDataOnly={<p>No requests that match filters.</p>}
          filterOperators={{
            text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
            date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
          }}
          onColumnResize={handleColumnResize}
          columnVirtualization={false}
          moreRecordsAvailableMessage={"Too many requests to display. Adjust filters to refine results"}
        />
      </Tooltip>
    );
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    const selectedTabIndex = e.selected;
    setSelectedTab(selectedTabIndex);
    setServiceNowDataSource(selectedTabIndex == 0 ? (props.requestType === RequestType.Pulse ? ServiceNowDataSource.PROJECTREQS : ServiceNowDataSource.WATCHING) : ServiceNowDataSource.MYREQUESTS);
    setSelectedStatus(statuses[0]);
    setServiceRequestData(null);
    setDataState(initialState);
  };

  const changeRequestStatus = (status: IStatus) => {
    setSelectedStatus(status);
    reloadData(false);
  }

  return (
    <div className="service-grid">
      {supportDetails && supportDetails.incidentId &&
        <RequestDetails
          onClose={() => {
            setSupportDetails(null)
          }}
          request={supportDetails ? supportDetails : null}
          open={!!supportDetails}
          {...props}
        />
      }
      <SupportRequestPopup
        open={supportWizardOpen}
        onClose={(isCancel?: boolean) => {

          if (isCancel) {
            trackCustomEvent("Cancel");
          }

          setSupportWizardOpen(false);
          setFieldValues(null);
          setCurrentFieldId(null);
        }}
        jobTypeNumber={props.jobTypeNumber}
        jobStatusInfo={JobStatusInfo}
        disableSubmitButton={props.disableSubmitButton}
        createRequestObject={props.createWorkRequest}
        wizardSections={props.workRequestSteps}
        wizardSummary={props.wizardSummary}
        warningMessage={props.wizardWarningMessage}
        saveForLater={props.wizardSaveForLatter}
        confirmSubmitMessage={props.wizardConfirmSubmitMessage}
        jobMessageConfig={props.jobMessagingConfig}

        onNextButtonClickWNewJob={() => {

          if (pageNum == 0) {
            trackCustomEvent("Initiate")
          }

        }}
        onSubmitClick={() => { trackCustomEvent("Submit") }}
        onFieldDataChange={onFieldDataChange}
        history={props.history}
      />

      <ColumnFieldListBox
        showSettingFieldsDialog={showSettingsDialog}
        availableFields={availableFields}
        selectedFields={selectedFields}
        onSettingsDialogClose={handleCloseSettingDialog}
        tableKey={tableKey}
        preferenceName={preferenceType}
      />

      <div className="request-grid-list">
        <h3>{requestTypes[props.requestType].config.header}</h3>
        <p>{requestTypes[props.requestType].config.description}</p>
        <div className="request-tabs">
          <div className="request-tab-add-button">
            {createNewRequestButtonRender()}
          </div>
          <TabStrip selected={selectedTab} onSelect={handleSelect} className="angle-tabs" keepTabsMounted={true}>
            {
              Object.keys((requestTypes[props.requestType].config.totalRecordsLabel as IDataSourcesSelector).values)
                .map((key, index) => {
                  const sourceRecord = (requestTypes[props.requestType].config.totalRecordsLabel as IDataSourcesSelector).values[key];
                  return <TabStripTab title={sourceRecord.value} disabled={loading && selectedTab !== index}>
                    <RequestStatuses
                      byProject={props.requestType === RequestType.Pulse && serviceNowDataSource === ServiceNowDataSource.PROJECTREQS}
                      isWatchlist={props.requestType === RequestType.ServiceNow && serviceNowDataSource === ServiceNowDataSource.WATCHING}
                      requestType={props.requestType}
                      statuses={statuses}
                      key={sourceRecord.key}
                      selectedStatus={selectedStatus}
                      changeStatus={changeRequestStatus}
                      refresh={refresh}
                    />
                    {selectedTab == index && ticketsGrid(sourceRecord)}
                  </TabStripTab>
                })
            }
          </TabStrip>
        </div>
      </div>


    </div>
  );
};

export default RequestGrid;
