import React, { useState, useEffect, useRef, ReactElement } from "react";
import "./RelWorkspaces.scss";
import { AdvancedGrid, IGridParams } from "../common/grid/AdvancedGrid";
import { GridCellProps, GridRowProps } from "@progress/kendo-react-grid";
import { IRelWorkspace } from "../../models/Relativity";
import RelativityService from "../../services/RelativityService";
import { Button } from "@progress/kendo-react-buttons";
import ApplicationNav from "../common/utilities/ApplicationNav";
import { ApplicationFeature } from "../../models/ApplicationModels";
import { IGridColumnProps } from "../common/grid/BaseGrid";
import ConsoleService from "../../services/ConsoleService";
import { INotificationMessageModel, MessageTypeIdsEnum } from "../../models/MessageModel";
import Tooltip from "@material-ui/core/Tooltip";
import ReactMarkdown from 'react-markdown';

interface IRefreshGridParams extends IGridParams {
  refreshToggle: boolean;
}

interface IProps {
  onHelpClicked: (app: ApplicationFeature, workspaceName?: string) => void;
  appNotifications: INotificationMessageModel[];
}

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "workspaceName",
  Ascending: "asc",
  Descending: "desc"
};

const startingGridParams: IRefreshGridParams = {
  skip: 0,
  take: GridConfigurations.PageSize,
  sort: [{ field: GridConfigurations.OrderBy, dir: "asc" }],
  refreshToggle: false
};

type Props = IProps;

export default (props: Props) => {

  const gridRef = useRef<AdvancedGrid<IRelWorkspace, IRefreshGridParams>>(null);
  const [hasError, setHasError] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [dataState, setDataState] = useState(startingGridParams);
  const [workspaces, setWorkspaces] = useState<Array<IRelWorkspace>>(null);
  const filterTimeOut = useRef(null);

  useEffect(() => { }, []);

  const openSupport = (workspace: IRelWorkspace) => {
    props.onHelpClicked(workspace.app, workspace.workspaceName);
  }

  const openLogin = async (props: IRelWorkspace) => {
    const result = await ConsoleService.preNavigate(props.app.id, null, props.workspaceId);
    if (result.ok && result.data && result.data.redirectUri) {
      window.open(result.data.redirectUri, '_blank', 'noopener,noreferrer');
    }

    await ApplicationNav.waitLinkReady(props.app);
  }

  const columns = new Array<IGridColumnProps>(
    { field: "workspaceName", title: "Relativity Workspace Name", filterable: true, preCalcPercentageWidth: 25, sortable: true },
    { field: "app.url", title: "Relativity Website URL", filterable: true, preCalcPercentageWidth: 25, sortable: true },
    { field: "relativityStatusName", title: "Status", filterable: true, sortable: true },
    { title: "", sortable: false, headerClassName: "no-sort user-workspace-action-cell", preCalcFixedWidth: 400 }
  );

  const fetchDataAsync = async (gridParams: IRefreshGridParams, caller?: string) => {

    setIsGridLoading(true);
    setDataState(gridParams);

    var results = await RelativityService.getMyWorkspaces(gridParams);

    if (results.ok) {
      setIsGridLoading(false);

      setWorkspaces(results.data.results);
      setHasError(false);
    } else {
      console.log("Could not load grid.");
      setIsGridLoading(false);
      setHasError(true);
    }
  }

  const rowRender = (row: ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    let propOverrides = {};
    const appId = props.dataItem.app && props.dataItem.app.id;
    const isUrgentMaintenanceExists = maintenanceInfo(appId, MessageTypeIdsEnum.URGENT_MAINTENANCE);

    if (isUrgentMaintenanceExists) {
      propOverrides = { ...propOverrides, class: "disable-row" };
    }

    return React.cloneElement(row, { ...propOverrides }, row.props.children);
  };

  const renderCell = (td: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps) => {
    const { field, dataItem } = cellProps;
    const value = dataItem && dataItem[field] ? dataItem[field] : "";
    const urgentMaintenance = maintenanceInfo(dataItem.app.id, MessageTypeIdsEnum.URGENT_MAINTENANCE);
    let upcomingMaintenance = null;

    if (!urgentMaintenance) {
      upcomingMaintenance = maintenanceInfo(dataItem.app.id, MessageTypeIdsEnum.UPCOMING_MAINTENANCE);
    }

    if (field === "workspaceName") {
      return (
        <td className="user-workspace-action-cell">
          {urgentMaintenance && Object.keys(urgentMaintenance).length > 0 ?
            <Tooltip classes={{ tooltip: "mui-tooltip-maxwidth-400", }} enterDelay={2} title={<span style={{ fontSize: "14px" }} > <div className="message-title">{urgentMaintenance.title}</div>
              <div className="message-content">
                <ReactMarkdown children={urgentMaintenance.message} />
              </div>
            </span>} placement="top" arrow>
              <span className="rel-workspace-urgent-maintenance"><i className="fa-sharp fa-solid fa-megaphone"></i></span>
            </Tooltip>
            : (upcomingMaintenance && Object.keys(upcomingMaintenance).length > 0 ?
            <Tooltip classes={{ tooltip: "mui-tooltip-maxwidth-400", }} enterDelay={2} title={<span style={{ fontSize: "14px" }} > <div className="message-title">{upcomingMaintenance.title}</div>
              <div className="message-content">
                <ReactMarkdown children={upcomingMaintenance.message} />
              </div>
            </span>} placement="top" arrow>
              <span className="rel-workspace-upcoming-maintenance"><i className="fa-sharp fa-solid fa-timer"></i></span>
            </Tooltip> : <></>)}
          <span>{value}</span>
        </td>
      );
    }
    else if (field === "app.url" || field === "relativityStatusName") {
      return td;
    }
    else {
      return (<td className="user-workspace-action-cell">
        {!urgentMaintenance && (
          <div className="btn-container">
            <Button className="btn btn-secondary support-btn" onClick={() => openSupport(dataItem)}><i className="fas fa-tools"></i> Relativity Support</Button>
            <Button className="btn btn-primary" primary={true} onClick={() => openLogin(dataItem)}><i className="fas fa-right-to-bracket"></i>Login</Button>
          </div>)
        }
      </td>)
    }
  };

  const maintenanceInfo = (appId: number, messageType: number) => {
    const appNotification = props.appNotifications && props.appNotifications.find((n: INotificationMessageModel) => { return n.notificationResourcesDetails && n.notificationResourcesDetails.find(nr => nr.objectId === appId) && n.messageTypeId === messageType && n.active === true });
    return appNotification && { ...appNotification, message: appNotification.message.length > 100 ? appNotification.message.substring(0, 100) + "... Refer to Alerts" : appNotification.message };
  }

  return <div className="user-workspace-grid">
    <AdvancedGrid
      ref={(standardGridInstance: AdvancedGrid<IRelWorkspace, IRefreshGridParams>) => {
        gridRef.current = standardGridInstance;
      }}
      showErrorState={hasError}
      showLoadingIndicator={isGridLoading}
      data={workspaces}
      dataFetch={fetchDataAsync}
      dataState={dataState}
      columns={columns}
      rowRender={rowRender}
      cellRender={renderCell}
      paging={false}
      noRecordsRender={<p>No Workspaces Found.</p>}
      noLoadOnMount={false}
      filteredDataOnly={false}
      hidePaper={false}
      filterOperators={{
        text: [
          { text: 'grid.filterContainsOperator', operator: 'contains' }
        ]
      }}
    />
  </div>
}