import * as React from "react";
import "./Message.scss";
import { IApplicationState } from "../../../store";
import { connect } from "react-redux";
import * as HeaderStore from "../../../store/Header";
import * as WarningMessageStore from "../../../store/WarningMessage";
import { MessageModel, IAgreementAcceptanceModel, MessageTypeIdsEnum, TriggerDisplayMessageEnum } from "../../../models/MessageModel";
import CommonHelper from "../../common/utilities/CommonHelper";
import NotificationService from "../../../services/NotificationService";
import MessageService from "../../../services/MessageService";
import { CircularProgress, Paper, Grid } from "@material-ui/core";
import Edit from "./details/Edit";
import AgreementDialog from "../../basic/AgreementDialog";
import * as SessionStore from "../../../store/Session";
import { Employer } from "../../../models/EmployerModel";
import DialogBox from "../../common/DialogBox";
import { IResource } from "../../../models/ResourceModels";
import { DateTime } from "luxon";

enum Mode {
  Edit,
  Create,
  View
}

interface IProps {
  setHeaderButtons: any;
  match?: any;
  history: any;
}

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  SessionStore.ISessionState;

type State = {
  mode: Mode;
  initializing: boolean;
  isMessageAlreadyExist: boolean;
  messageDetails: MessageModel;
  isLoading: boolean;
  hasError: boolean;
  actionItemAnchorEl: HTMLElement;
  showDeleteConfirmPopup: boolean;
  showPreview: boolean;
  initialMessageDetails: MessageModel;
  isIssueResolvedMessage: boolean;
};

class MessageDetails extends React.Component<Props, State> {
  [x: string]: any;
  constructor(props: Props) {
    super(props);

    const mode = Number(this.props.match.params.messageId) || Number(this.props.match.params.notificationId) > 0 ? Mode.View : Mode.Create;

    this.state = {
      mode,
      initializing: true,
      isMessageAlreadyExist: false,
      messageDetails: new MessageModel(),
      initialMessageDetails: new MessageModel(),
      isLoading: false,
      hasError: false,
      actionItemAnchorEl: null,
      showDeleteConfirmPopup: false,
      showPreview: false,
      isIssueResolvedMessage: false
    };
  }

  async componentDidMount() {
    await this.ensureDataFetched();
    await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/messages", "Back to message list");
  }

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevState !== this.state) {
      const isDirtyChangesExist = this.messageDetailsIsDirty();
      this.props.updateUnsavedChanges(isDirtyChangesExist);
    }
  }

  private async ensureDataFetched() {
    if (this.state.mode === Mode.Edit || this.state.mode === Mode.View) {
      const messageId: number = Number(this.props.match.params.messageId);
      const notificationId: number = Number(this.props.match.params.notificationId);

      const messageDetailsResult = messageId > 0 ? await MessageService.getMessageInfo(messageId) : notificationId > 0 ? await MessageService.getNotificationInfo(notificationId) : null;

      this.setState({ ...this.state, isLoading: true });
      if (!messageDetailsResult.ok) {
        await this.props.setHeaderButtons(null);
        await this.props.saveUnsavedChanges(null, false);
        this.setState({ ...this.state, hasError: true });
        this.props.history.push("/administration/messages");
      } else {
        let messageData = messageDetailsResult.data ? messageDetailsResult.data : new MessageModel();

        const startDateTime = messageData.startDate ? CommonHelper.convertUTCDateTimeToGivenTimezone(new Date(messageData.startDate), messageData.timezone) : null;
        const endDateTime = messageData.endDate ? CommonHelper.convertUTCDateTimeToGivenTimezone(new Date(messageData.endDate), messageData.timezone) : null;
        messageData.notificationId = notificationId;
        messageData.startDate = startDateTime;
        messageData.startTime = startDateTime;
        messageData.endDate = endDateTime;
        messageData.endTime = endDateTime;
        messageData.noEndDate = endDateTime === null;
        messageData.messageTypeId = messageId ? 1 : messageData.messageTypeId;//--- default values
        messageData.triggerType = messageId ? TriggerDisplayMessageEnum.EMPLOYER : TriggerDisplayMessageEnum.APP_INSTANCE; //--- default value
        messageData.timezone = messageData.timezone ? messageData.timezone : "";
        messageData.notificationResourcesDetails = messageData.notificationResourcesDetails ? messageData.notificationResourcesDetails : [];
        messageData.addResources = messageData.notificationResourcesDetails ? messageData.notificationResourcesDetails.map((r: IResource) => r.resourceId) : [];

        const employerIds = messageData.employerList ? messageData.employerList.map((x: Employer) => { return x.id }) : [];
        messageData.employerIds = employerIds;

        this.setState({
          messageDetails: messageData,
          initialMessageDetails: messageData,
          initializing: false,
          isLoading: false,
          hasError: false,
          isIssueResolvedMessage: messageData.reEnableAppTile
        });
      }
    }
    else {
      this.setState({ initializing: false });
    }

    this.props.saveUnsavedChanges(JSON.stringify(this.state.messageDetails), false);
  }

  getBtnsList: any = (isSaveDisabled: boolean, isSaving: boolean) => {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick
      },
      {
        buttonLabel: "Preview",
        type: "button",
        handleClick: this.handlePreviewClick,
        className: "msg-preview-btn",
        disabled: !(this.state.messageDetails.title && this.state.messageDetails.message && this.state.messageDetails.message !== '<p></p>')
      },
      {
        buttonLabel: "Save Message",
        type: "button",
        disabled: isSaveDisabled || (this.state.mode === Mode.Edit || this.state.mode === Mode.View ? !this.props.sessionData.permissions.has("EpiqAdminUpdateAgreement") : false),
        handleClick: this.onAddBtnClick,
        color: "primary",
        isSaving: isSaving
      }
    ];
    return buttons;
  };

  handleCancelClick = () => {
    this.props.history.push({
      pathname: "/administration/messages", state: { messageType: this.props.history.location.state && this.props.history.location.state.messageType }
    });
  };

  handlePreviewClick = () => {
    this.setState({ showPreview: true });
  };

  saveAgreementDetails = async (message: MessageModel) => {
    if (this.state.messageDetails.agreementId == 0) {
      const result = await MessageService.createMessage(message);

      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage) {
          if (errorMessage.toLocaleLowerCase() === "agreement name already exists.") {
            this.setState({ isMessageAlreadyExist: true });
            NotificationService.showErrorToast("Message name already exists.");
          } else {
            NotificationService.showErrorToast("Something went wrong. Agreement was not created.");
          }

          await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/messages", "Back to message list");
        }
        else {
          const msgObj = CommonHelper.cloneObject(this.state.messageDetails) as MessageModel;
          msgObj.name = message.name;

          this.setState({ messageDetails: msgObj });
          NotificationService.showSuccessToast(`${this.state.messageDetails.name} Created.`);
          this.props.saveUnsavedChanges(null, false);
          this.props.history.push({ pathname: "/administration/messages", state: { messageType: MessageTypeIdsEnum.EULA_AGREEMENT } });
        }
      }
      else {
        NotificationService.showErrorToast("Something went wrong. Agreement was not created.");
      }
    } else {
      const result = await MessageService.updateMessage(message);
      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage && errorMessage.toLocaleLowerCase() === "agreement name already exists.") {
          this.setState({ isMessageAlreadyExist: true });
          NotificationService.showErrorToast("Message name already exists.");
        }
        else {
          this.setState({ mode: Mode.View });
          await this.props.saveUnsavedChanges(JSON.stringify(this.state.messageDetails), false);
          NotificationService.showSuccessToast("Update saved.");
          this.ensureDataFetched();
        }

        await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/messages", "Back to agreement list");
      }
      else {
        NotificationService.showErrorToast("Something went wrong.Update not saved.");
        await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/messages", "Back to agreement list");
      }
    }
  }

  saveNotificationDetails = async (message: MessageModel) => {
    if (this.state.messageDetails.notificationId == 0) {
      const result = await MessageService.createNotification(message);

      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage) {
          if (errorMessage.toLocaleLowerCase() === "notification message with the same name already exists") {
            this.setState({ isMessageAlreadyExist: true });
            NotificationService.showErrorToast("Message name already exists.");
          } else {
            NotificationService.showErrorToast(errorMessage);
          }

          await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/messages", "Back to message list");
        }
        else {
          const msgObj = CommonHelper.cloneObject(this.state.messageDetails) as MessageModel;
          msgObj.name = message.name;

          this.setState({ messageDetails: msgObj });
          NotificationService.showSuccessToast(`${this.state.messageDetails.name} Created.`);
          this.props.saveUnsavedChanges(null, false);
          this.props.history.push({ pathname: "/administration/messages", state: { messageType: MessageTypeIdsEnum.DEFAULT } });
        }
      }
      else {
        NotificationService.showErrorToast("Something went wrong. Notification message was not created.");
      }
    } else {
      const result = await MessageService.updateNotification(message);
      if (result.ok) {
        const errorMessage = result.data && result.data.inValid === true && result.data.message ? result.data.message : "";

        if (errorMessage) {
          if (errorMessage.toLocaleLowerCase() === "notification message with the same name already exists") {
            this.setState({ isMessageAlreadyExist: true });
            NotificationService.showErrorToast("Message name already exists.");
          } else {
            NotificationService.showErrorToast(errorMessage);
          }
        }
        else {
          this.setState({ mode: Mode.View });
          await this.props.saveUnsavedChanges(JSON.stringify(this.state.messageDetails), false);
          NotificationService.showSuccessToast("Update saved.");
          this.ensureDataFetched();
        }

        await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/messages", "Back to agreement list");
      }
      else {
        NotificationService.showErrorToast("Something went wrong.Update not saved.");
        await this.props.setHeaderButtons(this.getBtnsList(true, false), "", "/administration/messages", "Back to agreement list");
      }
    }
  }

  onAddBtnClick = async () => {
    this.props.setHeaderButtons(this.getBtnsList(true, true), "", "/administration/messageDetails/0", "Back to create message");

    const message = { ...this.state.messageDetails };
    const startDate = message.startDate ? CommonHelper.combineDateWithTimeAndTimezone(message.startDate, message.startTime, message.timezone) : null;
    const endDate = message.endDate ? CommonHelper.combineDateWithTimeAndTimezone(message.endDate, message.endTime, message.timezone) : null;
    message.startDate = startDate;

    if (!message.noEndDate && message.endDate != null && message.endTime != null) {
      message.endDate = endDate;
    }

    if ((message.messageTypeId === MessageTypeIdsEnum.UPCOMING_MAINTENANCE || message.messageTypeId == MessageTypeIdsEnum.URGENT_MAINTENANCE) && message.triggerType == TriggerDisplayMessageEnum.APP_INSTANCE) {
      this.saveNotificationDetails(message);
    }
    else {
      this.saveAgreementDetails(message);
    }
  }

  handleMessageEdited(message: MessageModel, isValid: boolean) {
    const editedMessageDetails: MessageModel = {
      ...this.state.messageDetails,
      name: message.name,
      active: message.active,
      comment: message.comment,
      startDate: message.startDate,
      endDate: message.endDate,
      startTime: message.startTime,
      endTime: message.endTime,
      employerIds: message.employerIds,
      employerList: message.employerList,
      forceLogoutOnDisagree: message.forceLogoutOnDisagree,
      title: message.title,
      message: message.message,
      messageTypeId: message.messageTypeId,
      triggerType: message.triggerType,
      noEndDate: message.noEndDate,
      addResources: message.addResources,
      timezone: message.timezone,
      timezoneOffset: message.timezoneOffset,
      repeat: message.repeat,
      reEnableAppTile: message.reEnableAppTile,
      issueResolved: message.issueResolved,
      issueResolvedTitle: message.issueResolvedTitle,
      issueResolvedMessage: message.issueResolvedMessage
    };

    const isMessageAlreadyExist = this.state.messageDetails.name.toLowerCase() === message.name.toLowerCase() && this.state.isMessageAlreadyExist ? this.state.isMessageAlreadyExist : false;

    this.setState({
      ...this.state,
      messageDetails: editedMessageDetails,
      isMessageAlreadyExist
    }, this.toggleSave);
  }

  handleEditCancelled = () => {
    if (this.messageDetailsIsDirty()) {
      this.ensureDataFetched(); //Need to refetch the data to get the original data, unable to handle without refresh due to some state issues
    }
    this.setState({ messageDetails: this.state.initialMessageDetails, mode: Mode.View, isMessageAlreadyExist: false }, this.toggleSave);
  }

  messageDetailsIsDirty() {
    return this.props.initialState !== JSON.stringify(this.state.messageDetails as MessageModel);
  }

  isFormValidationsPassed = () => {
    const {
      messageDetails,
      isMessageAlreadyExist
    } = this.state;
    const isMsgNameValidationPassed = messageDetails.name &&
      isMessageAlreadyExist === false
      ? true
      : false;

    const hasEmpOrResources = messageDetails.messageTypeId === 1 ? messageDetails.employerIds && messageDetails.employerIds.length > 0 : messageDetails.addResources && messageDetails.addResources.length > 0;

    const hasValidTimezone = messageDetails.messageTypeId != MessageTypeIdsEnum.EULA_AGREEMENT ? messageDetails.timezone : true

    const hasValidFields = messageDetails.startDate && messageDetails.startTime
      && messageDetails.triggerType > 0 && messageDetails.messageTypeId > 0
      && hasEmpOrResources && messageDetails.title && messageDetails.message;

    let hasValidEndDate = false;
    if (hasValidFields && hasValidTimezone) {
      if (messageDetails.noEndDate) {
        hasValidEndDate = true;
      }
      else if (messageDetails.endDate != null && messageDetails.endTime != null) {
        const startDateTime = CommonHelper.combineDateWithTimeAndTimezone(messageDetails.startDate, messageDetails.startTime, messageDetails.timezone);
        const endDateTime = CommonHelper.combineDateWithTimeAndTimezone(messageDetails.endDate, messageDetails.endTime, messageDetails.timezone);
        hasValidEndDate = endDateTime > startDateTime;
      }
    }

    let hasIssueResolvedValid = messageDetails.issueResolved ? messageDetails.issueResolvedTitle && messageDetails.issueResolvedMessage : true;

    return (
      (isMsgNameValidationPassed && hasValidFields && hasValidEndDate && hasIssueResolvedValid && hasValidTimezone)
    );
  };

  private toggleSave() {
    const canSave =
      (this.messageDetailsIsDirty() && this.isFormValidationsPassed());

    this.props.setHeaderButtons(this.getBtnsList(!canSave), "", "/administration/messages", "Back to messages list");
  }

  diagCloseEvent = async (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ showPreview: false });
  }

  handleDeleteConfirm = () => {
    this.setState({ showDeleteConfirmPopup: true, actionItemAnchorEl: null });
  }

  toggleDialogEvent = async (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.textContent.toLocaleLowerCase() === "confirm") {
      this.setState({ showDeleteConfirmPopup: false });
      var result = this.state.messageDetails.agreementId ? await MessageService.DeleteMessage(this.state.messageDetails.agreementId) : await MessageService.DeleteNotification(this.state.messageDetails.notificationId);
      if (result && result.ok) {
        NotificationService.showSuccessToast(`Deleted message: ${this.state.messageDetails.name}.`);
        this.props.history.push({ pathname: "/administration/messages", state: { messageType: this.state.messageDetails.agreementId ? MessageTypeIdsEnum.EULA_AGREEMENT : MessageTypeIdsEnum.DEFAULT } });
      }
      else {
        NotificationService.showErrorToast(`${this.state.messageDetails.name} message deletion failed`);
      }
    }
    else if (event.currentTarget.textContent.toLocaleLowerCase() === "cancel") {
      this.setState({ showDeleteConfirmPopup: false });
    }
  }

  resetMode = (mode: Mode) => {
    if (this.messageDetailsIsDirty()) {
      this.ensureDataFetched();
    }
    this.setState({ messageDetails: this.state.initialMessageDetails, mode: mode, isMessageAlreadyExist: false }, this.toggleSave);
  }

  render() {
    const { mode, initializing, messageDetails, isMessageAlreadyExist, isLoading, hasError } = this.state;
    let messageInfo;

    if ((mode !== Mode.Create && isLoading) || initializing) {
      messageInfo = (
        <Paper className="message-grid-wrapper">
          <div className="message-loader">
            <CircularProgress />
          </div>
        </Paper>
      );
    }
    else if (hasError) {
      messageInfo = (
        <Paper className="emp-details-wrapper">
          <div className="no-emp">
            <p> Oops! Something went wrong. </p>
            <p>Please check your network connection and refresh the page.</p>
          </div>
        </Paper>
      );
    }
    else {
      messageInfo = (
        <>
          {this.state.showDeleteConfirmPopup && <div className="warning">
            <DialogBox
              title={"Delete Message"}
              content={"Confirm that you want to delete this message?"}
              okButtonLabelText={"Cancel"}
              cancelButtonLabelText={"Confirm"}
              visible={this.state.showDeleteConfirmPopup}
              toggleDialogEvent={this.toggleDialogEvent.bind(this)}>
            </DialogBox>
          </div>}
          <Edit
            initialMessageDetails={this.state.initialMessageDetails}
            messageDetails={this.state.initialMessageDetails}
            isMessageNameExist={isMessageAlreadyExist}
            createMessage={mode === Mode.Create}
            onMessageEdited={this.handleMessageEdited.bind(this)}
            mode={mode}
            handleDeleteConfirm={this.handleDeleteConfirm.bind(this)}
            resetMode={this.resetMode.bind(this)}
            handleEditCancelled={this.handleEditCancelled.bind(this)}
            isIssueResolvedMessage={this.state.isIssueResolvedMessage}
          />

          {messageDetails && messageDetails.message && this.state.showPreview && (
            <AgreementDialog
              agreement={{ title: this.state.messageDetails.title, message: messageDetails.message } as IAgreementAcceptanceModel}
              closeButtonLabelText={"Close"}
              diagCloseEvent={this.diagCloseEvent.bind(this)}
              modalWidth={Number(this.props.match.params.messageId) > 0 ? "1200px" : "600px"}
            />
          )}

        </>)
    }

    return (
      <div className="message-info-wrapper" >
        {messageInfo}
      </div>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.headerState, ...state.warningMessageState, ...state.sessionState }), {
  ...HeaderStore.actionCreators,
  ...WarningMessageStore.actionCreators
})(MessageDetails as any);